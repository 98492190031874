import { Vue, Component, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import lodash from 'lodash';

// Components
import QrOrderLine from '@/views/qrCodes/components/qrOrderLine.vue';
import QrOrderLineLoader from '@/views/qrCodes/components/qrOrderLineLoader.vue';

// Store
import store from '@/store';
import { mapState } from 'vuex';
import { ActionTypes } from "@/store/actions/actions";

// Services
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";

// Models
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ProductGroup } from "@/models/productGroup";
import { DropdownModel } from "@/models/dropdownModel";
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { OrderLineStepStatus } from "@/models/orderLineStepStatus";
import { PublishUnpublishModel, PublishUnpublishReqModel } from "@/models/publishUnpublishOrderModel";
import { NotificationHelper } from "@/helpers/notificationHelper";

@Component({
  components: { QrOrderLine, QrOrderLineLoader },
  computed: mapState(['orderLoading', 'orders', 'suppliers', 'totalQrCodeLive']),
})
export default class QrCodes extends Vue {
  private orderService: OrderService;
  private supplierService: SupplierService;

  private orderLoading!: boolean;

  private orders!: OrderLineDisplayModel[];

  private filteredOrders: OrderLineDisplayModel[] = [];

  private orderNumberFilter: string = '';

  private selectedBrand: string = '';

  private selectedProductGroup: ProductGroup = ProductGroup.None;

  private selectedUsername: string = '';

  private selectedSeason: string = '';

  private scrollPosition: number = 0;

  private tabIndex: number = 0;

  private isOpen: boolean = false;

  private selectedSortOption: string = '';

  private isSortAtoZ: boolean = false;

  private isSortZtoA: boolean = false;

  private sortType: string = 'asc';

  private latestOrders: OrderLineDisplayModel[] = [];

  private isAllOrdersSelected: boolean = false;

  // TTD-3244
  private suppliers!: OrderCompanyModel[];
  private selectedPartner: string = '';
  private toPublishOrders: OrderLineDisplayModel[] = [];
  private publishedOrders: OrderLineDisplayModel[] = [];
  private selectedOrders: OrderLineDisplayModel[] = [];
  private isSaving: boolean = false;
  private publishUnpublishData: PublishUnpublishReqModel[] = [];
  private totalQrCodeLive!: number;
  private showConfirmPopup: boolean = false;
  private updatePhaseStatusType: string = '';
  private individualOrderSelected: PublishUnpublishReqModel[] = [];

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (this.orderLoading === false && this.orders.length === 0) {
      await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
    } else {
      this.filterOrders();
    }
  }

  private scrollEv(event: any): void {
    this.scrollPosition = event.currentTarget.scrollTop;
  }

  private get showNoFilterResults(): boolean {
    return this.orderNumberFilter !== '' || this.selectedBrand !== '' || this.selectedProductGroup !== ProductGroup.None || this.selectedUsername !== '' || this.selectedSeason !== '' || this.selectedPartner !== '';
  }

  private get locale(): string {
    if (i18n.availableLocales.some((l) => l === i18n.locale)) {
      return i18n.locale;
    }
    return i18n.fallbackLocale as string;
  }

  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [
      { value: '', text: this.$t('pages.order_lines.all_brands').toString() },
    ];
    this.orders.forEach((line) => {
      if (
        options.findIndex((option) => option.value === line.brandName) === -1
      ) {
        options.push(new DropdownModel(line.brandName, line.brandName));
      }
    });

    return options;
  }

  private get productGroups(): DropdownModel[] {
    const options: DropdownModel[] = [
      {
        value: 0,
        text: this.$t('pages.order_lines.all_productgroups').toString(),
      },
    ];
    this.orders.forEach((line) => {
      if (
        options.findIndex((option) => option.value === line.productGroup) === -1
      ) {
        const productGroupName = this.$t(
          `enums.product_group.${line.productGroup}`
        )
          .toString()
          .split(/(?=[A-Z])/)
          .join(' ');
        options.push(
          new DropdownModel(
            line.productGroup,
            productGroupName.charAt(0).toUpperCase() +
              productGroupName.slice(1).toLowerCase()
          )
        );
      }
    });
    return options;
  }

  private get username(): DropdownModel[] {
    const options: DropdownModel[] = [
      {
        value: '',
        text: this.$t('pages.order_lines.all_usernames').toString(),
      },
    ];
    const distinctUsers: any[] = [];
    this.orders.filter((item) => {
      const i = distinctUsers.findIndex(
        (x) => x.fromCompanyUser.userId == item.fromCompanyUser.userId
      );
      if (i <= -1) {
        distinctUsers.push(item);
      }
      return null;
    });
    distinctUsers.forEach((line) => {
      let fullname =
        line.fromCompanyUser.firstName + ' ' + line.fromCompanyUser.lastName;
      if (fullname.length > 20) {
        fullname = fullname.slice(0, 14).concat('...');
      }
      options.push(new DropdownModel(line.fromCompanyUser.userId, fullname));
    });
    return options;
  }

  private get seasons(): DropdownModel[] {
    const options: DropdownModel[] = [
      { value: '', text: this.$t('pages.order_lines.all_seasons').toString() },
    ];
    const distinctSeasons: any[] = [];
    this.orders.filter((item) => {
      const i = distinctSeasons.findIndex(
        (x) => x.orderLines[0].season == item.orderLines[0].season
      );
      if (
        i <= -1 &&
        item.orderLines[0].season !== null &&
        item.orderLines[0].season !== ''
      ) {
        distinctSeasons.push(item);
      }
      return null;
    });
    distinctSeasons.forEach((line) => {
      if (line.orderLines[0].season !== null) {
        options.push(
          new DropdownModel(
            line.orderLines[0].season,
            line.orderLines[0].season
          )
        );
      }
    });

    return options;
  }

  // TTD-3244
  private get partners(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: '', text: 'All partners' }];
    options.push(
      ...this.suppliers.map((s) => new DropdownModel(s.id, s.companyName))
    );
    return options;
  }

  private get orderLines(): OrderLineDisplayModel[] {
    // this.filteredOrders.forEach((o) => (o.show = true));
    let orders = lodash.cloneDeep(this.filteredOrders);
    if (this.tabIndex === 0) {
      orders = orders.filter(
        (o) =>
          (o.orderLinePhase === 1 &&
            (o.orderLineStatus === 0 ||
              o.orderLineStatus === 1 ||
              o.orderLineStatus === 2 ||
              o.orderLineStatus === 3 ||
              o.orderLineStatus === 4)) ||
          (o.orderLinePhase === 2 && o.orderLineStatus === 4)
      );
    } else {
      orders = orders.filter(
        (o) =>
          o.orderLinePhase === 2 &&
          (o.orderLineStatus === 5 || o.orderLineStatus === 6)
      );
    }
    if (this.orderNumberFilter !== '') {
      orders = orders.filter((o) => {
        return (
          o.orderNumber
            .toLowerCase()
            .includes(this.orderNumberFilter.toLowerCase()) ||
          (o.orderLines[0].season !== null &&
            o.orderLines[0].season
              .toLowerCase()
              .includes(this.orderNumberFilter.toLowerCase())) ||
          o.orderLines[0].colourway
            ?.toLowerCase()
            .includes(this.orderNumberFilter.toLowerCase()) ||
          o.brandName
            .toLowerCase()
            .includes(this.orderNumberFilter.toLowerCase()) ||
          o.styleNumber
            .toLowerCase()
            .includes(this.orderNumberFilter.toLowerCase()) ||
          o.styleName
            .toLowerCase()
            .includes(this.orderNumberFilter.toLowerCase())
        );
      });
    }
    if (this.selectedBrand !== '') {
      orders = orders.filter((o) => o.brandName === this.selectedBrand);
    }
    if (this.selectedUsername !== '') {
      orders = orders.filter(
        (o) => o.fromCompanyUser.userId === this.selectedUsername
      );
    }
    if (this.selectedSeason !== '') {
      orders = orders.filter(
        (o) => o.orderLines[0].season === this.selectedSeason
      );
    }
    if (this.selectedPartner !== '') {
      orders = orders.filter(
        (o) => o.toCompanyId === this.selectedPartner
      );
    }
    if (this.selectedProductGroup !== ProductGroup.None) {
      orders = orders.filter(
        (o) => o.productGroup === this.selectedProductGroup
      );
    }

    const finalOrders: OrderLineDisplayModel[] = orders;

    let latestOrders: OrderLineDisplayModel[] = [];
    latestOrders = finalOrders.filter((o) => o.orderLineStatus !== 7);
    if (latestOrders.length <= 1400) {
      return latestOrders;
    }
    if (this.scrollPosition === 0) {
      latestOrders = finalOrders.filter((o) => o.orderLineStatus !== 7);
      latestOrders = latestOrders.slice(0, 20);
    } else {
      if (
        document.getElementById('scrollableDiv') !== null &&
        document.getElementById('widgetDiv') !== null
      ) {
        const parentDiv = document
          .getElementById('scrollableDiv')!
          .getBoundingClientRect();
        const widgetDiv = document
          .getElementById('widgetDiv')!
          .getBoundingClientRect().height;
        const totalHeight = (widgetDiv + 10) * 2; //here +10 is for margin-bottom to div
        const orderCount =
          (Math.floor(this.scrollPosition / totalHeight) + 1) * 20;
        latestOrders = finalOrders.filter((o) => o.orderLineStatus !== 7);
        latestOrders = latestOrders.slice(0, orderCount);
      } else {
        this.scrollPosition = 0;
      }
    }
    return latestOrders;
  }

  private selectATab(index: number): void {
    this.tabIndex = index;
    this.toggleQrCodes(false);
  }

  private toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  private sortedList(sort: string, val: string) {
    if (val !== '') {
      this.selectedSortOption = val;
      this.sortType = sort;
      if (this.selectedSortOption === 'colourWay') {
        if (sort === 'asc') {
          this.isSortAtoZ = true;
          this.isSortZtoA = false;
          this.filteredOrders = this.filteredOrders
            .slice()
            .sort(this.sortByColour);
        } else {
          this.isSortZtoA = true;
          this.isSortAtoZ = false;
          this.filteredOrders = this.filteredOrders
            .slice()
            .sort((a, b) => this.sortByColour(b, a));
        }
      } else {
        this.filteredOrders = this.filteredOrders.slice().sort((a, b) => {
          if (this.selectedSortOption) {
            if (sort === 'asc') {
              this.isSortAtoZ = true;
              this.isSortZtoA = false;
              if (
                a[this.selectedSortOption] === null ||
                a[this.selectedSortOption].trim() === '' ||
                a[this.selectedSortOption] === undefined
              )
                return -1;
              if (
                b[this.selectedSortOption] === null ||
                b[this.selectedSortOption].trim() === '' ||
                b[this.selectedSortOption] === undefined
              )
                return 1;
              if (
                !isNaN(+a[this.selectedSortOption].trim()) &&
                !isNaN(+b[this.selectedSortOption].trim())
              ) {
                return (
                  +a[this.selectedSortOption].trim() -
                  +b[this.selectedSortOption].trim()
                );
              }
              return a[this.selectedSortOption]
                .trim()
                .localeCompare(b[this.selectedSortOption].trim());
            } else {
              this.isSortZtoA = true;
              this.isSortAtoZ = false;
              if (
                a[this.selectedSortOption] === null ||
                a[this.selectedSortOption].trim() === '' ||
                a[this.selectedSortOption] === undefined
              )
                return 1;
              if (
                b[this.selectedSortOption] === null ||
                b[this.selectedSortOption].trim() === '' ||
                b[this.selectedSortOption] === undefined
              )
                return -1;
              if (
                !isNaN(+a[this.selectedSortOption].trim()) &&
                !isNaN(+b[this.selectedSortOption].trim())
              ) {
                return (
                  +b[this.selectedSortOption].trim() -
                  +a[this.selectedSortOption].trim()
                );
              }
              return b[this.selectedSortOption]
                .trim()
                .localeCompare(a[this.selectedSortOption].trim());
            }
          }
        });
      }
      this.filteredOrders = this.filteredOrders.slice(
        0,
        this.filteredOrders.length
      );
    }
  }

  private sortByColour(a: any, b: any) {
    const colorA = a.orderLines[0].colourway;
    const colorB = b.orderLines[0].colourway;
    if (colorA == null || colorA === '') return -1;
    if (colorB == null || colorB === '') return 1;
    if (colorA === undefined) return -1;
    if (colorB === undefined) return 1;
    if (typeof colorA === 'number' && typeof colorB === 'number') {
      return colorA - colorB;
    }
    return colorA.localeCompare(colorB);
  }

  private toggleQrCodes(value: boolean): void {
    this.isAllOrdersSelected = value;
    if (this.isAllOrdersSelected) {
      this.orderLines.forEach((o) => {
        o.isOrderSeleted = true;
        o.isDisabled = true;
      });
    } else {
      this.orderLines.forEach((o) => {
        o.isOrderSeleted = false;
        o.isDisabled = false;
      });
    }
    this.selectedOrders = this.orderLines.filter((o) => o.isOrderSeleted);
    if(this.selectedOrders.length > 0){
      this.updatePhaseStatusType = 'BULK';
    } else {
      this.updatePhaseStatusType = '';
    }
  }

  private selectQrCode(orderLine: OrderLineDisplayModel, type: string): void {
    this.selectedOrders = [];
    if (
      orderLine.orderId !== Guid.Empty &&
      orderLine.orderId !== null &&
      orderLine.orderId !== undefined
    ) {
      this.orderLines[
        this.orderLines.findIndex((o) => o.orderId === orderLine.orderId)
      ].isOrderSeleted =
        !this.orderLines[
          this.orderLines.findIndex((o) => o.orderId === orderLine.orderId)
        ].isOrderSeleted;
    }
    const selected = this.orderLines.filter((o) => o.isOrderSeleted);
    if(selected.length > 0){
      this.orderLines.forEach(o => o.isDisabled = true);
      this.updatePhaseStatusType = type;
    } else {
      this.orderLines.forEach(o => o.isDisabled = false);
      this.updatePhaseStatusType = '';
    }
    if (
      selected.length === this.orderLines.length
    ) {
      this.isAllOrdersSelected = true;
    } else {
      this.isAllOrdersSelected = false;
    }
    this.selectedOrders = selected;
  }

  private confirmUpdatePhaseStatus(selectedOrders: PublishUnpublishReqModel[], type: string): void {
    this.showConfirmPopup = true;
    this.updatePhaseStatusType = type;
    this.individualOrderSelected = selectedOrders;
  }

  private confirmBulkUpdatePhase(): void {
    this.showConfirmPopup = true;
    this.updatePhaseStatusType = 'BULK';
  }

  private async save(): Promise<void> {
    try {
      this.isSaving = true;
      if(this.updatePhaseStatusType === 'BULK'){
        if(this.tabIndex === 0){
          await this.publish();
        } else {
          await this.unpublish();
        }
      } else {
        await this.savePublishUnpublishOrders(this.individualOrderSelected);
        this.updatePhaseStatusType = '';
      }
    }
    finally {
      this.isSaving = false;
      this.showConfirmPopup = false;
    }
  }

  private cancel(): void {
    this.showConfirmPopup = false;
  }

  private async publish(): Promise<void> {
      this.publishUnpublishData = [];
      const steps = this.$store.getters
        .orderLineSteps as OrderLineStepDisplayModel[];
        this.selectedOrders.forEach(order => {
          const filteredSteps = steps.filter(
            (s) => s.orderId === order.orderId
          );
          let hasStepEvidence: boolean = false;
          if (filteredSteps.length > 0) {
            const index = filteredSteps.findIndex(
              (s) => s.status !== OrderLineStepStatus.Accepted
            );
            if (index === -1) {
              hasStepEvidence = true;
            }
          }
          const publishPhaseStatus: PublishUnpublishReqModel = {
            orderId: order.orderId,
            lineID: order.id,
            orderLinePhase: 2,
            orderLineStatus: hasStepEvidence ? 6 : 5,
          };
          this.publishUnpublishData.push(publishPhaseStatus);
        })
        await this.savePublishUnpublishOrders(this.publishUnpublishData);
  }

  private async unpublish(): Promise<void> {
      this.publishUnpublishData = [];
      const steps = this.$store.getters
        .orderLineSteps as OrderLineStepDisplayModel[];
        this.selectedOrders.forEach(order => {
          const filteredSteps = steps.filter(
            (s) => s.orderId === order.orderId
          );
          let phase = 1;
          let status = 0;
          if (filteredSteps.length > 0) {
            if (
              filteredSteps.findIndex((s) => s.toCompanyId === Guid.Empty) ===
              -1
            ) {
              if (
                filteredSteps.findIndex(
                  (s) => s.status !== OrderLineStepStatus.Accepted
                ) === -1
              ) {
                phase = 2;
                status = 4;
              } else {
                if (
                  filteredSteps.findIndex(
                    (s) => s.status === OrderLineStepStatus.WaitingForApproval
                  ) !== -1
                ) {
                  phase = 1;
                  status = 2;
                } else {
                  phase = 1;
                  status = 1;
                }
              }
            }
          }
          const publishPhaseStatus: PublishUnpublishReqModel = {
            orderId: order.orderId,
            lineID: order.id,
            orderLinePhase: phase,
            orderLineStatus: status,
          };
          this.publishUnpublishData.push(publishPhaseStatus);
        });
        await this.savePublishUnpublishOrders(this.publishUnpublishData);
  }

  private async savePublishUnpublishOrders(selectedOrders: PublishUnpublishReqModel[]): Promise<void>{
    const result = await this.supplierService.updatePublishOrderlinePhaseStatus(selectedOrders);
    if(result.success){
      if(this.tabIndex === 0){
        NotificationHelper.createSucceededNotification(i18n.t('global.notifications.publish_succeeded').toString());
        this.$store.commit("setAllQrCodeCount", this.totalQrCodeLive + selectedOrders.length);
      } else {
        NotificationHelper.createSucceededNotification(i18n.t('global.notifications.unpublish_succeeded').toString());
        this.$store.commit("setAllQrCodeCount", this.totalQrCodeLive - selectedOrders.length);
      }
      this.selectedOrders = [];
    } else {
      if(this.tabIndex === 0){
        NotificationHelper.createErrorNotification(i18n.t('global.notifications.publish_failed').toString());
      } else {
        NotificationHelper.createErrorNotification(i18n.t('global.notifications.unpublish_failed').toString());
      }
    }
  }

  @Watch('orders', { deep: true })
  private filterOrders(): void {
    if (this.orders.length > 0) {
      this.filteredOrders = [];
      this.orders.forEach((o) => {
        if (o.orderLinePhase === 0 && o.orderLineStatus === 0) {
          return;
        } else {
          o.isDisabled = false;
          this.filteredOrders.push(o);
        }
      });
      this.toPublishOrders = this.filteredOrders.filter(
        (o) =>
          (o.orderLinePhase === 1 &&
            (o.orderLineStatus === 0 ||
              o.orderLineStatus === 1 ||
              o.orderLineStatus === 2 ||
              o.orderLineStatus === 3 ||
              o.orderLineStatus === 4)) ||
          (o.orderLinePhase === 2 && o.orderLineStatus === 4)
      );
      this.publishedOrders = this.filteredOrders.filter(
        (o) =>
          o.orderLinePhase === 2 &&
          (o.orderLineStatus === 5 || o.orderLineStatus === 6)
      );
    } else {
      this.filteredOrders = lodash.cloneDeep(this.orders);
      this.toPublishOrders = [];
      this.publishedOrders = [];
    }
  }
}
