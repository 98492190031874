import { Vue, Component, Prop, Emit, Ref } from "vue-property-decorator";
import lodash from "lodash";
import store from "@/store";
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { ActionTypes } from "@/store/actions/actions";

// Services
import { SupplierService } from "@/services/supplierService";

// Components
import SupplyChainPartnerLoader from '@/views/supplyChainPartners/components/supplyChainPartnerLoader.vue';

// Models
import { SupplierModel, ContactUserList } from "@/models/supplierModel";
import { AddressModel } from "@/models/addressModel";
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { ClientPartnerSettingRespModel, ClientPartnerSharingSettingModel, ClientRelationship, SharingSettingModel } from "@/models/sharingSetting";
import { UserPermissionModel } from "@/models/permissionModel";
import { UserModel } from "@/models/userModel";
import { CompanyPhotoModel } from "@/models/companyPhotoModel";
import i18n from "@/i18n";

@Component({
  computed: mapState(['countryList', 'supplierPartnersBrands', 't1AssociatedPartners', 'agentAssociatedPartners', 'facilities', 'userPermissions', 'clientPartnerSharingSettings', 'user']),
  components: {
    SupplyChainPartnerLoader
  }
})
export default class SharingSetting extends Vue {

  @Prop()
  private supplier!: SupplierModel;

  @Prop()
  private selectedPartnerType!: number;

  @Prop()
  private userDetails!: ContactUserList[];

  @Prop()
  private isPartnerSelected!: boolean;

  @Prop()
  private sharePartnersInfo!: SupplierOverviewModel[];

  private supplierService: SupplierService;

  private settingsLoading: boolean = false;

  private settings: SharingSettingModel = new SharingSettingModel();

  private changedCounter: number = 0;
  
  private userPermissions!: UserPermissionModel | null;

  private clientPartnerSharingSettings!: ClientPartnerSharingSettingModel;

  private partnersSharingSettingSaving: boolean = false;

  private showApplySettingsModal: boolean = false;

  private user!: UserModel;

  private applySettingSelected: string = '';

  public constructor() {
    super();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.settingsLoading = true;
      this.showTop();
      // if(this.clientPartnerSharingSettings === null) { commented because it might possible that both client & partner add data same time
        await store.dispatch(ActionTypes.SET_CLIENT_PARTNER_SHARING_SETTINGS);
      // }
      if(this.clientPartnerSharingSettings !== null && this.clientPartnerSharingSettings.sharingSettings.length > 0) {
        const selectedSupp = this.sharePartnersInfo.filter(supp => supp.id === this.supplier.id);
        const set = this.clientPartnerSharingSettings.sharingSettings.filter(partner => (partner.supplierID === this.supplier.id) && (partner.associatedWithClientVia === selectedSupp[0].associatedWithClientVia));
        if(set.length > 0){
          this.settings = lodash.cloneDeep(set[0]);
        } else {
          this.settings = new SharingSettingModel();
        }
      } else {
        this.settings = new SharingSettingModel();
      }
    } finally {
      this.settingsLoading = false;
    }
  }

  private get getEditSettingPermission(): boolean {
    if (this.userPermissions !== null) {
      return this.userPermissions.editSharingProfileSettings === 1 ? true : false;
    }
    return false;
  }

  private get companyPictures(): CompanyPhotoModel[]{
    let pictures: CompanyPhotoModel[] = [];
    if(this.supplier.facilityAddresses && this.supplier.facilityAddresses.length > 0){
      const addID = this.supplier.facilityAddresses[0].id;
      pictures = this.supplier.profilePictures.filter(p => p.addressID === addID);
    }
    return pictures;
  }

  private applySetingModalText(type: string): string {
    let text = '';
    if(type === 'ALL-PARTNER') {
      text = i18n.t('pages.supply_chain_partners.apply_setting_all_partners', [this.supplier.companyName]).toString();
    } else {
      text = i18n.t('pages.supply_chain_partners.apply_setting_selected_partners', [this.supplier.companyName]).toString();
    }
    return text;
  }

  private toggleSetting(key: string, value: string): void {
    switch (key) {
      case "showDetails":
        this.settings.showDetails = value;
        if(this.settings.showDetails === 'DISABLED') {
          this.settings.showSupplierName = 'DISABLED';
          this.settings.showFactoryAddress = 'DO-NOT-SHOW-ADDRESS';
          this.settings.showProfile = 'DISABLED';
          this.settings.showProfilePicture = 'DISABLED';
        } else {
          this.settings = new SharingSettingModel();
        }
        break;
      case "showSupplierName":
        this.settings.showSupplierName = value;
        if(value === 'DISABLED') {
          this.settings.showFactoryAddress = 'CITY_STATE_COUNTRY';
          this.settings.showProfile = 'DISABLED';
          this.settings.showProfilePicture = 'DISABLED';
        } else {
          this.settings.showFactoryAddress = 'FULL-ADDRESS';
          this.settings.showProfile = 'ENABLED';
          this.settings.showProfilePicture = 'ENABLED';
        }
        break;
      case "showFactoryAddress":
        this.settings.showFactoryAddress = value;
        break;
      case "showProfile":
        this.settings.showProfile = value;
        break;
      case "showProfilePicture":
        this.settings.showProfilePicture = value;
        break;
      default:
        break;
    }
    this.changedCounter++;
  }

  private closeApplySettingsModal(): void {
    this.applySettingSelected = '';
    this.showApplySettingsModal = false;
  }

  private showApplySettings(): void {
    const selectedSupp = this.sharePartnersInfo.find(sup => sup.id === this.supplier.id);
    if(selectedSupp && (selectedSupp.isTier1 || selectedSupp.isAgent || selectedSupp.isDelegatePartner)){
      this.showApplySettingsModal = true;
    } else {
      this.showApplySettingsModal = false;
      this.savePartnerSharingSettings();
    }
  }

  private toggleApplySetting(value: string): void {
    this.applySettingSelected = value;
  }

  private async savePartnerSharingSettings(): Promise<void> {
    this.partnersSharingSettingSaving = true;
    try {
      const partnersSetting: ClientPartnerSharingSettingModel = new ClientPartnerSharingSettingModel();
      const sharingSettings: SharingSettingModel[] = [];
      // Apply setting to all associated partners
      if(this.applySettingSelected === 'ALL-PARTNER') {
        this.sharePartnersInfo.forEach((partner: SupplierOverviewModel) => {
          const partnerSetting: SharingSettingModel = new SharingSettingModel();
          if(this.clientPartnerSharingSettings !== null && this.clientPartnerSharingSettings.sharingSettings.length > 0) {
            const part = this.clientPartnerSharingSettings.sharingSettings.filter(partnerSet => (partnerSet.supplierID === partner.id) && (partnerSet.associatedWithClientVia === partner.associatedWithClientVia) && (partnerSet.partnerOf === partner.partnerOf));
            if(part.length > 0) {
              // update existing partner setting data
              part[0].showDetails = this.settings.showDetails;
              part[0].showSupplierName = this.settings.showSupplierName;
              part[0].showFactoryAddress = this.settings.showFactoryAddress;
              part[0].showProfile = this.settings.showProfile;
              part[0].showProfilePicture = this.settings.showProfilePicture;
              part[0].applySettingsTo = (partner.relatioshipWithClient === ClientRelationship.CP) || (partner.relatioshipWithClient === ClientRelationship.DP_OF_CP) || (partner.relatioshipWithClient === ClientRelationship.AGENT) ? this.applySettingSelected : '';
              sharingSettings.push(part[0]);
            } else {
              // add new partner setting in existing client setting data
              partnerSetting.ID = uuidv4();
              partnerSetting.supplierID = partner.id;
              partnerSetting.showDetails = this.settings.showDetails;
              partnerSetting.showSupplierName = this.settings.showSupplierName;
              partnerSetting.showFactoryAddress = this.settings.showFactoryAddress;
              partnerSetting.showProfile = this.settings.showProfile;
              partnerSetting.showProfilePicture = this.settings.showProfilePicture;
              partnerSetting.relatioshipWithClient = partner.relatioshipWithClient;
              partnerSetting.associatedWithClientVia = partner.associatedWithClientVia;
              partnerSetting.partnerOf = partner.partnerOf;
              partnerSetting.applySettingsTo = (partner.relatioshipWithClient === ClientRelationship.CP) || (partner.relatioshipWithClient === ClientRelationship.DP_OF_CP) || (partner.relatioshipWithClient === ClientRelationship.AGENT) ? this.applySettingSelected : '';
              sharingSettings.push(partnerSetting);
            }
          } else {
            // No client partner setting is empty 
            partnerSetting.ID = uuidv4();
            partnerSetting.supplierID = partner.id;
            partnerSetting.showDetails = this.settings.showDetails;
            partnerSetting.showSupplierName = this.settings.showSupplierName;
            partnerSetting.showFactoryAddress = this.settings.showFactoryAddress;
            partnerSetting.showProfile = this.settings.showProfile;
            partnerSetting.showProfilePicture = this.settings.showProfilePicture;
            partnerSetting.relatioshipWithClient = partner.relatioshipWithClient;
            partnerSetting.associatedWithClientVia = partner.associatedWithClientVia;
            partnerSetting.partnerOf = partner.partnerOf;
            partnerSetting.applySettingsTo = (partner.relatioshipWithClient === ClientRelationship.CP) || (partner.relatioshipWithClient === ClientRelationship.DP_OF_CP) || (partner.relatioshipWithClient === ClientRelationship.AGENT) ? this.applySettingSelected : '';
            sharingSettings.push(partnerSetting);
          }
        });
        if(this.clientPartnerSharingSettings !== null){
          let uniqAssociatedWith: string[] = [];
          const partnerType = this.sharePartnersInfo.find(sup => sup.id === this.supplier.id);
          if(partnerType && partnerType.relatioshipWithClient === ClientRelationship.CP) {
            uniqAssociatedWith = lodash.uniqBy(this.sharePartnersInfo, 'associatedWithClientVia').map(s => s.associatedWithClientVia);
            uniqAssociatedWith = uniqAssociatedWith.filter(aw => aw !== '');
            uniqAssociatedWith.forEach(assoc => {
              const existingSuppliers = this.clientPartnerSharingSettings.sharingSettings.filter(cs => cs.associatedWithClientVia === assoc);
              if(existingSuppliers.length > 0) {
                existingSuppliers.forEach(sup => {
                  if(sharingSettings.findIndex(ss => ss.ID === sup.ID) === -1){
                    sup.showDetails = this.settings.showDetails;
                    sup.showSupplierName = this.settings.showSupplierName;
                    sup.showFactoryAddress = this.settings.showFactoryAddress;
                    sup.showProfile = this.settings.showProfile;
                    sup.showProfilePicture = this.settings.showProfilePicture;
                    sharingSettings.push(sup);
                  }
                })
              }
            })
          }
        }
      } else {
        // Apply setting to only selected partner
        const selectedSuppIndex = this.sharePartnersInfo.findIndex(sup => sup.id === this.supplier.id);
        if(selectedSuppIndex !== -1) {
          const partnerSetting: SharingSettingModel = new SharingSettingModel();
          if(this.clientPartnerSharingSettings !== null && this.clientPartnerSharingSettings.sharingSettings.length > 0) {
            const part = this.clientPartnerSharingSettings.sharingSettings.filter(partnerSet => (partnerSet.supplierID === this.sharePartnersInfo[selectedSuppIndex].id) && (partnerSet.associatedWithClientVia === this.sharePartnersInfo[selectedSuppIndex].associatedWithClientVia) && (partnerSet.partnerOf === this.sharePartnersInfo[selectedSuppIndex].partnerOf));
            if(part.length > 0) {
              // update existing partner setting data
              part[0].showDetails = this.settings.showDetails;
              part[0].showSupplierName = this.settings.showSupplierName;
              part[0].showFactoryAddress = this.settings.showFactoryAddress;
              part[0].showProfile = this.settings.showProfile;
              part[0].showProfilePicture = this.settings.showProfilePicture;
              part[0].applySettingsTo = (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.CP) || (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.DP_OF_CP) || (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.AGENT) ? this.applySettingSelected : '';
              sharingSettings.push(part[0]);
            } else {
              // add new partner setting in existing client setting data
              partnerSetting.ID = uuidv4();
              partnerSetting.supplierID = this.sharePartnersInfo[selectedSuppIndex].id;
              partnerSetting.showDetails = this.settings.showDetails;
              partnerSetting.showSupplierName = this.settings.showSupplierName;
              partnerSetting.showFactoryAddress = this.settings.showFactoryAddress;
              partnerSetting.showProfile = this.settings.showProfile;
              partnerSetting.showProfilePicture = this.settings.showProfilePicture;
              partnerSetting.relatioshipWithClient = this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient;
              partnerSetting.partnerOf = this.sharePartnersInfo[selectedSuppIndex].partnerOf;
              partnerSetting.associatedWithClientVia = this.sharePartnersInfo[selectedSuppIndex].associatedWithClientVia;
              partnerSetting.applySettingsTo = (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.CP) || (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.DP_OF_CP) || (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.AGENT) ? this.applySettingSelected : '';
              sharingSettings.push(partnerSetting);
            }
          } else {
            // No client partner setting is empty 
            partnerSetting.ID = uuidv4();
            partnerSetting.supplierID = this.sharePartnersInfo[selectedSuppIndex].id;
            partnerSetting.showDetails = this.settings.showDetails;
            partnerSetting.showSupplierName = this.settings.showSupplierName;
            partnerSetting.showFactoryAddress = this.settings.showFactoryAddress;
            partnerSetting.showProfile = this.settings.showProfile;
            partnerSetting.showProfilePicture = this.settings.showProfilePicture;
            partnerSetting.relatioshipWithClient = this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient;
            partnerSetting.partnerOf = this.sharePartnersInfo[selectedSuppIndex].partnerOf;
            partnerSetting.associatedWithClientVia = this.sharePartnersInfo[selectedSuppIndex].associatedWithClientVia;
            partnerSetting.applySettingsTo = (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.CP) || (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.DP_OF_CP) || (this.sharePartnersInfo[selectedSuppIndex].relatioshipWithClient === ClientRelationship.AGENT) ? this.applySettingSelected : '';
            sharingSettings.push(partnerSetting);
          }
        }
      }
      partnersSetting.clientID = this.user.companyId;
      partnersSetting.clientName = this.user.companyName;
      partnersSetting.sharingSettings = sharingSettings;
      let resp: ClientPartnerSettingRespModel = new ClientPartnerSettingRespModel();
      if(this.clientPartnerSharingSettings === null) {
        resp = await this.supplierService.savePartnerSharingSettingsAsync(partnersSetting);
      } else {
        const newPartnerSettings: SharingSettingModel[] = [];
        const updatePartnerSettings: SharingSettingModel[] = [];
        sharingSettings.forEach((setting: SharingSettingModel) => {
          const part = this.clientPartnerSharingSettings.sharingSettings.filter(partnerSet => partnerSet.ID === setting.ID);
          if(part.length > 0) {
            updatePartnerSettings.push(setting);
          } else {
            newPartnerSettings.push(setting);
          }
        });
        if(newPartnerSettings.length > 0) {
          const clonePartnerSettings = lodash.cloneDeep(partnersSetting);
          clonePartnerSettings.sharingSettings = newPartnerSettings;
          resp = await this.supplierService.addNewPartnerSharingSettingsAsync(clonePartnerSettings);
        }
        if(updatePartnerSettings.length > 0) {
          const clonePartnerSettings = lodash.cloneDeep(partnersSetting);
          clonePartnerSettings.sharingSettings = updatePartnerSettings;
          clonePartnerSettings.createdAt = this.clientPartnerSharingSettings.createdAt;
          clonePartnerSettings.createdBy = this.clientPartnerSharingSettings.createdBy;
          clonePartnerSettings.updatedAt = this.clientPartnerSharingSettings.updatedAt;
          clonePartnerSettings.updatedBy = this.clientPartnerSharingSettings.updatedBy;
          resp = await this.supplierService.updatePartnerSharingSettingsAsync(clonePartnerSettings);
        }
      }
      if(resp.success) {
        store.commit(ActionTypes.SET_CLIENT_PARTNER_SHARING_SETTINGS, resp.sharingSettings);
      }
      this.changedCounter = 0;
      this.showApplySettingsModal = false;
      this.applySettingSelected = '';
    } finally {
      this.partnersSharingSettingSaving = false;
    }
  }

  @Emit()
  private showTop(): void{}

  @Emit()
  private openDetails(supplier: SupplierOverviewModel, selected: boolean): void {
  }

  @Emit()
  private addressess(allAddressess: AddressModel[]): void {}
}
