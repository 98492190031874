import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { OrderLineModel } from "./orderLineModel";
import { ProductGroup } from './productGroup';
import { ClientStepModel } from './clientStepModel';
import { Guid } from "./guid";
import { StepHistory } from './stepHistoryModal';
import { StepState } from './stepState';
import { OrderLineDisplayModel } from './orderLineDisplayModel';

export class OrderLineStepParallelModel {
    public id!: string;
    public orderId!: string;
    public orderLineId!: string;
    public orderNumber!: string;
    public stepId!: number;
    public displayName!: string;
    public productGroup!: ProductGroup;
    public sequence!: number;
    public status!: OrderLineStepStatus;
    public fromCompanyId!: string;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public evidencePhotos: OrderLineStepProof[] = [];
    public validSocialCertificates: number[] = [];
    public validSustainabilityCertificates: number[] = [];
    public showDetailsCount!: number;
    public showNewsLetter!: boolean;
    public showFactoryAddress!: boolean;
    public showDetails!: boolean;
    public showProfile!: boolean;
    public rejectionReason!: string;
    public compliantAtVerificationDate!: boolean;
    public currentlyValidCertificates!: null;
    public stepState!: StepState;
    public stepHistory!: StepHistory[];
    public isLastStep!: string;
    public parentStepId!: string;
    public isSelected?: boolean;
    //TTD-2620, Agent orderlines changes
    public addedByAgent?: boolean;
    public updatedByAgent?: boolean;
    public deletedByAgent?: boolean;
    //TTD-2620, Agent orderlines changes
    // TTD-2721
    public footprintRefNo!: string;
    public footprintRefID!: string;
    public footprintRefNoUpdatedBy!: string;
    // Sprint 20, TTD-3940
    public footprintRefNoPrefix?: string;
    // Sprint 29, TTD-3818
    public clientID!: string;
    public cpSupplierID!: string;
    public agentID?: string;
    public delegatedCompanyID?: string;

    public constructor(orderLine: OrderLineModel | OrderLineDisplayModel, clientStep: ClientStepModel, parentStepId: string) {
        this.id = orderLine.id!;
        this.parentStepId = parentStepId!;
        this.orderId = orderLine.orderId!;
        this.orderNumber = orderLine.orderNumber!;
        this.orderLineId = orderLine.orderLineId!;
        this.stepId = clientStep.stepId;
        this.productGroup = clientStep.productGroup;
        this.displayName = clientStep.displayName;
        this.sequence = clientStep.sequence;
        this.fromCompanyId = Guid.Empty;
        this.toCompanyId = Guid.Empty;
        this.toCompanyName = "";
        this.showDetails = false;
        this.showNewsLetter = false;
        this.showFactoryAddress = false;
        this.showProfile = false;
        this.showDetailsCount = 0;
        this.rejectionReason = "null";
        this.stepState = StepState.NONE;
        this.stepHistory = [];
        this.status = 0;
        this.addedByAgent = false;
        this.updatedByAgent = false;
        this.deletedByAgent = false;
        this.footprintRefNo = "";
        this.footprintRefID = Guid.Empty;
        this.footprintRefNoUpdatedBy = Guid.Empty;
        // Sprint 20, TTD-3940
        this.footprintRefNoPrefix = "";
    }
}