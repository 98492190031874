export class UserPermissionModel {

    public constructor(userPermissions: UserPermissionModel){
        this.assignSCPOrderLineSteps = userPermissions.assignSCPOrderLineSteps !== undefined ? userPermissions.assignSCPOrderLineSteps : 0;
        this.assignSCPOrderLine = userPermissions.assignSCPOrderLine !== undefined ? userPermissions.assignSCPOrderLine : 0;
        this.archieveCompliance = userPermissions.archieveCompliance !== undefined ? userPermissions.archieveCompliance : 0;
        this.blockUser = userPermissions.blockUser !== undefined ? userPermissions.blockUser : 0;
        this.confirmGeolocationCompany = userPermissions.confirmGeolocationCompany !== undefined ? userPermissions.confirmGeolocationCompany : 0;
        this.confirmOrderLine = userPermissions.confirmOrderLine !== undefined ? userPermissions.confirmOrderLine : 0;
        this.confirmCompliance = userPermissions.confirmCompliance !== undefined ? userPermissions.confirmCompliance : 0;
        this.createOrderLine = userPermissions.createOrderLine !== undefined ? userPermissions.createOrderLine : 0;
        this.createOrderLineSteps = userPermissions.createOrderLineSteps !== undefined ? userPermissions.createOrderLineSteps : 0;
        this.createOrderLineStepsTemplate = userPermissions.createOrderLineStepsTemplate !== undefined ? userPermissions.createOrderLineStepsTemplate : 0;
        this.createReports = userPermissions.createReports !== undefined ? userPermissions.createReports : 0;
        this.deleteOrderLine = userPermissions.deleteOrderLine !== undefined ? userPermissions.deleteOrderLine : 0;
        this.deleteOrderLineSteps = userPermissions.deleteOrderLineSteps !== undefined ? userPermissions.deleteOrderLineSteps : 0;
        this.deleteOrderLineStepsTemplate = userPermissions.deleteOrderLineStepsTemplate !== undefined ? userPermissions.deleteOrderLineStepsTemplate : 0;
        this.deleteReports = userPermissions.deleteReports !== undefined ? userPermissions.deleteReports : 0;
        this.deleteBrands = userPermissions.deleteBrands !== undefined ? userPermissions.deleteBrands : 0;
        this.deleteFootprint = userPermissions.deleteFootprint !== undefined ? userPermissions.deleteFootprint : 0;
        this.deleteNextStep = userPermissions.deleteNextStep !== undefined ? userPermissions.deleteNextStep : 0;
        this.deleteFootprintData = userPermissions.deleteFootprintData !== undefined ? userPermissions.deleteFootprintData : 0;
        this.inviteCompany = userPermissions.inviteCompany !== undefined ? userPermissions.inviteCompany : 0;
        this.inviteUser = userPermissions.inviteUser !== undefined ? userPermissions.inviteUser : 0;
        this.readCompany = userPermissions.readCompany !== undefined ? userPermissions.readCompany : 0;
        this.readLogo = userPermissions.readLogo !== undefined ? userPermissions.readLogo : 0;
        this.readOrderLine = userPermissions.readOrderLine !== undefined ? userPermissions.readOrderLine : 0;
        this.readOrderLineSteps = userPermissions.readOrderLineSteps !== undefined ? userPermissions.readOrderLineSteps : 0;
        this.readOrderLineStepsTemplate = userPermissions.readOrderLineStepsTemplate !== undefined ? userPermissions.readOrderLineStepsTemplate : 0;
        this.readProductPicture = userPermissions.readProductPicture !== undefined ? userPermissions.readProductPicture : 0;
        this.readQrCode = userPermissions.readQrCode !== undefined ? userPermissions.readQrCode : 0;
        this.readReports = userPermissions.readReports !== undefined ? userPermissions.readReports : 0;
        this.readUser = userPermissions.readUser !== undefined ? userPermissions.readUser : 0;
        this.readBrands = userPermissions.readBrands !== undefined ? userPermissions.readBrands : 0;
        this.readFootprint = userPermissions.readFootprint !== undefined ? userPermissions.readFootprint : 0;
        this.readNextStep = userPermissions.readNextStep !== undefined ? userPermissions.readNextStep : 0;
        this.readSCPOrderLine = userPermissions.readSCPOrderLine !== undefined ? userPermissions.readSCPOrderLine : 0;
        this.readQualityReport = userPermissions.readQualityReport !== undefined ? userPermissions.readQualityReport : 0;
        this.readFootprintData = userPermissions.readFootprintData !== undefined ? userPermissions.readFootprintData : 0;
        this.readSignedCompliance = userPermissions.readSignedCompliance !== undefined ? userPermissions.readSignedCompliance : 0;
        this.readCertificates = userPermissions.readCertificates !== undefined ? userPermissions.readCertificates : 0;
        this.updateCompany = userPermissions.updateCompany !== undefined ? userPermissions.updateCompany : 0;
        this.updateOrderLine = userPermissions.updateOrderLine !== undefined ? userPermissions.updateOrderLine : 0;
        this.updateOrderLineSteps = userPermissions.updateOrderLineSteps !== undefined ? userPermissions.updateOrderLineSteps : 0;
        this.updateOrderLineStepsTemplate = userPermissions.updateOrderLineStepsTemplate !== undefined ? userPermissions.updateOrderLineStepsTemplate : 0;
        this.updateReports = userPermissions.updateReports !== undefined ? userPermissions.updateReports : 0;
        this.updateUser = userPermissions.updateUser !== undefined ? userPermissions.updateUser : 0;
        this.updateBrands = userPermissions.updateBrands !== undefined ? userPermissions.updateBrands : 0;
        this.updateFootprint = userPermissions.updateFootprint !== undefined ? userPermissions.updateBrands : 0;
        this.updateNextStep = userPermissions.updateNextStep !== undefined ? userPermissions.updateNextStep : 0;
        this.uploadOrderLine = userPermissions.uploadOrderLine !== undefined ? userPermissions.uploadOrderLine : 0;
        this.uploadLogo = userPermissions.uploadLogo !== undefined ? userPermissions.uploadLogo : 0;
        this.uploadProductPicture = userPermissions.uploadProductPicture !== undefined ? userPermissions.uploadProductPicture : 0;
        this.uploadFootprintData = userPermissions.uploadFootprintData !== undefined ? userPermissions.uploadFootprintData : 0;
        this.uploadQualityReport = userPermissions.uploadQualityReport !== undefined ? userPermissions.uploadQualityReport : 0;
        this.uploadCompliance = userPermissions.uploadCompliance !== undefined ? userPermissions.uploadCompliance : 0;
        this.linkQualityReport = userPermissions.linkQualityReport !== undefined ? userPermissions.linkQualityReport : 0;
        this.profileDetailsClientSCP = userPermissions.profileDetailsClientSCP !== undefined ? userPermissions.profileDetailsClientSCP : 0;
        this.complianceClientSCP = userPermissions.complianceClientSCP !== undefined ? userPermissions.complianceClientSCP : 0;
        this.certificatesClientSCP = userPermissions.certificatesClientSCP !== undefined ? userPermissions.certificatesClientSCP : 0;
        this.viewOriginalCompliance = userPermissions.viewOriginalCompliance !== undefined ? userPermissions.viewOriginalCompliance : 0;
        this.sendReminderCompliance = userPermissions.sendReminderCompliance !== undefined ? userPermissions.sendReminderCompliance : 0;
        this.downloadSignedCompliance = userPermissions.downloadSignedCompliance !== undefined ? userPermissions.downloadSignedCompliance : 0;
        this.downloadCertificates = userPermissions.downloadCertificates !== undefined ? userPermissions.downloadCertificates : 0;
        this.signCompliance = userPermissions.signCompliance !== undefined ? userPermissions.signCompliance : 0;
        this.previewQrCode = userPermissions.previewQrCode !== undefined ? userPermissions.previewQrCode : 0;
        this.publishUnpublishQrCode = userPermissions.publishUnpublishQrCode !== undefined ? userPermissions.publishUnpublishQrCode : 0;
        this.linkOrderToFootprint = userPermissions.linkOrderToFootprint !== undefined ? userPermissions.linkOrderToFootprint : 0;
        this.accessFootprintScreen = userPermissions.accessFootprintScreen !== undefined ? userPermissions.accessFootprintScreen : 0;
        this.unlinkOrderFromFootprint = userPermissions.unlinkOrderFromFootprint !== undefined ? userPermissions.unlinkOrderFromFootprint : 0;
        this.viewFootPrintDataForOrder = userPermissions.viewFootPrintDataForOrder !== undefined ? userPermissions.viewFootPrintDataForOrder : 0;
        this.agentTempEditorPermission = userPermissions.agentTempEditorPermission !== undefined ? userPermissions.agentTempEditorPermission : 0;
        this.createMandatorySteps = userPermissions.createMandatorySteps !== undefined ? userPermissions.createMandatorySteps : 0;
        this.readMandatorysteps = userPermissions.readMandatorysteps !== undefined ? userPermissions.readMandatorysteps : 0;
        this.updateMandatorySteps = userPermissions.updateMandatorySteps !== undefined ? userPermissions.updateMandatorySteps : 0;
        this.deleteMandatorySteps = userPermissions.deleteMandatorySteps !== undefined ? userPermissions.deleteMandatorySteps : 0;
        this.editSharingProfileSettings = userPermissions.editSharingProfileSettings !== undefined ? userPermissions.editSharingProfileSettings : 0;
        this.viewSharingProfileSettings = userPermissions.viewSharingProfileSettings !== undefined ? userPermissions.viewSharingProfileSettings : 0;
    }

    public assignSCPOrderLineSteps!: number;
    public assignSCPOrderLine!: number;
    public archieveCompliance!: number;
    public blockUser!: number;
    public confirmGeolocationCompany!: number;
    public confirmOrderLine!: number;
    public confirmCompliance!: number;
    public createOrderLine!: number;
    public createOrderLineSteps!: number;
    public createOrderLineStepsTemplate!: number;
    public createReports!: number;
    public deleteOrderLine!: number;
    public deleteOrderLineSteps!: number;
    public deleteOrderLineStepsTemplate!: number;
    public deleteReports!: number;
    public deleteBrands!: number;
    public deleteFootprint!: number;
    public deleteNextStep!: number;
    public deleteFootprintData!: number;
    public inviteCompany!: number;
    public inviteUser!: number;
    public readCompany!: number;
    public readLogo!: number;
    public readOrderLine!: number;
    public readOrderLineSteps!: number;
    public readOrderLineStepsTemplate!: number;
    public readProductPicture!: number;
    public readQrCode!: number;
    public readReports!: number;
    public readUser!: number;
    public readBrands!: number;
    public readFootprint!: number;
    public readNextStep!: number;
    public readSCPOrderLine!: number;
    public readQualityReport!: number;
    public readFootprintData!: number;
    public readSignedCompliance!: number;
    public readCertificates!: number;
    public updateCompany!: number;
    public updateOrderLine!: number;
    public updateOrderLineSteps!: number;
    public updateOrderLineStepsTemplate!: number;
    public updateReports!: number;
    public updateUser!: number;
    public updateBrands!: number;
    public updateFootprint!: number;
    public updateNextStep!: number;
    public uploadOrderLine!: number;
    public uploadLogo!: number;
    public uploadProductPicture!: number;
    public uploadFootprintData!: number;
    public uploadQualityReport!: number;
    public uploadCompliance!: number;
    public linkQualityReport!: number;
    public profileDetailsClientSCP!: number;
    public complianceClientSCP!: number;
    public certificatesClientSCP!: number;
    public viewOriginalCompliance!: number;
    public sendReminderCompliance!: number;
    public downloadSignedCompliance!: number;
    public downloadCertificates!: number;
    public signCompliance!: number;
    public previewQrCode!: number;
    public publishUnpublishQrCode!: number;
    public linkOrderToFootprint!: number;
    public accessFootprintScreen!: number;
    public unlinkOrderFromFootprint!: number;
    public viewFootPrintDataForOrder!: number;
    public agentTempEditorPermission!: number;
    public createMandatorySteps!: number;
    public readMandatorysteps!: number;
    public updateMandatorySteps!: number;
    public deleteMandatorySteps!: number;
    public editSharingProfileSettings!: number;
    public viewSharingProfileSettings!: number;
}

export class UserRolePermissionsModel {
    public companyID!: string;
    public groupID!: string;
    public groupName!: string;
    public permissions!: UserPermissionModel;
}

export class UserPermissionRequestModel {
    public companyID!: string;
    public companyType!: number;
    public groupID!: string;
    public isPilot!: boolean;
}

export class AgentPilotPermissionReqModel {
    public companyID!: string;
    public companyType!: string;
    public groupID!: string;
    public footprintFlag!: string;
}