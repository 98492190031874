import { Guid } from "./guid";

export class SupplierPartnerModel {
    public partnerName!: string;
    public companySubtypes!: number[];
    public ID!: string;
    public onboarded!: boolean;
    public isDelegatePartner?: boolean; // TTD-1866
    public isAgent?: boolean; // TTD-1866
    public isTier1?: boolean; // TTD-1866
    public relatioshipWithClient?: string; // TTD-1672
    public partnerOf?: string; // TTD-1672
    public associatedWithClientVia?: string // TTD-1672
}

export class PartnerModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.name = '';
        this.isTier1 = false;
        this.isSelected = false;
        this.isexpanded = false;
        this.showDDIcon = false;
        this.country = '';
    }
    public ID!: string;
    public country!: string;
    public name!: string;
    public isTier1!: boolean;
    public isSelected!: boolean;
    public isexpanded!: boolean;
    public isDisabled!: boolean;
    public showDDIcon!: boolean;
    public partners!: PartnerModel[];
}

export class CPSCPModel {
    public companyID!: string;
    public suppliers!: SCPModel[];
}

export class SCPModel {
    public invitedCompanyID!: string;
    public companyName!: string;
    public onboarded!: boolean;
    public companyType!: number;
    public country!: string;
    public isAgent!: boolean;
    public isDelegatePartner!: boolean;
    public isTier1!: boolean;
}

export class PartnerRequestModel {
    public t1SCPList!: string[];
}

export class PartnerResponseModel {
    public success!: boolean;
    public t1SCPpartners!: CPSCPModel[];
}

export class SelectedPartnerModel {
    public constructor(id: string, name: string, isTier1: boolean){
        this.ID = id;
        this.name = name;
        this.isTier1 = isTier1;
    }
    public ID!: string;
    public name!: string;
    public isTier1!: boolean;
}

export class SupplierOrderCountModel {
    public constructor(){
        this.publishedCount = 0;
        this.inProgressCount = 0;
    }
    public publishedCount!: number;
    public inProgressCount!: number;
}

export class SupplierPartnersRespModel {
    public success!: boolean;
    public supplierList!: SupplierPartnerModel[];
}

export class UniqueSupplierWithStepModel {
    public constructor(){
        this.supplierId = '';
        this.steps = [];
    }
    public supplierId!: string;
    public steps!: number[];
}

export class PartnersModel {
    public constructor(){
        this.partnerId = '';
        this.linkedPartners = [];
    }
    public partnerId!: string;
    public linkedPartners!: UniqueSupplierWithStepModel[];
}