import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from "vuex";

// Components
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";

// Services
import { MiddlewareService } from "@/services/middlewareService";
import { UserPermissionModel } from "@/models/permissionModel";
import { PublishUnpublishModel, PublishUnpublishReqModel } from "@/models/publishUnpublishOrderModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { OrderLineStepStatus } from "@/models/orderLineStepStatus";
import { Guid } from "@/models/guid";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";

@Component({
    computed: mapState(['userRole', 'userPermissions', 'companyType', 'totalQrCodeLive'])
})
export default class QrOrderLine extends Vue {

    @Prop()
    private orderLine!: OrderLineDisplayModel;

    private orderLineData: OrderLineDisplayModel = new OrderLineDisplayModel();

    private orderService: OrderService;
    private middlewareService: MiddlewareService;
    private supplierService: SupplierService;

    private showPublishButton: boolean = true;

    private orderStatusChanging: boolean = false;
    private accessToken: string = "";

    private userRole!: string;

    private userPermissions!: UserPermissionModel | null;

    private companyType!: number;

    private totalQrCodeLive!: number;

    public constructor() {
        super();
        this.orderService = new OrderService();
        this.middlewareService = new MiddlewareService();
        this.supplierService = new SupplierService();
    }

    private async created(): Promise<void> {
        this.orderLineData = this.orderLine;
        this.showPublishButton =
            this.orderLineData.orderLineStatus !== 5
            && this.orderLineData.orderLineStatus !== 6;
    }

  private async showPreview(): Promise<void> {
        if(this.orderLineData.isDisabled){
            return;
        }
        if (this.accessToken==''){
            const response = await this.middlewareService.signinConsumerApp();
            if (response.response_type == "success") {
                this.accessToken = response.access_token;
            }
        }
        window.open(`${process.env.VUE_APP_Consumer_app_preview_url}?o=${this.orderLineData.orderId}&l=${this.orderLineData.id}&at=${this.accessToken}`, "_blank");
    }

    private async publish(): Promise<void> {
        try {
            this.orderStatusChanging = true;
            const steps = this.$store.getters.orderLineSteps as OrderLineStepDisplayModel[];
            const filteredSteps = steps.filter(s => s.orderId === this.orderLineData.orderId);
            let hasStepEvidence: boolean = false;
            if(filteredSteps.length > 0){
                const index = filteredSteps.findIndex(s => s.status !== OrderLineStepStatus.Accepted);
                if(index === -1){
                    hasStepEvidence = true;
                }
            }
            const publishPhaseStatus: PublishUnpublishReqModel = {
                orderId: this.orderLineData.orderId,
                lineID: this.orderLineData.id,
                orderLinePhase: 2,
                orderLineStatus: hasStepEvidence ? 6 : 5,
            };
            this.updatePhaseStatus([publishPhaseStatus], 'INDIVIDUAL');
        }
        finally {
            this.orderStatusChanging = false;
        }
    }

    private async unpublish(): Promise<void> {
        try {
            this.orderStatusChanging = true;
            const steps = this.$store.getters.orderLineSteps as OrderLineStepDisplayModel[];
            const filteredSteps = steps.filter(s => s.orderId === this.orderLineData.orderId);
            let phase = 1;
            let status = 0;
            if(filteredSteps.length > 0){
                if(filteredSteps.findIndex(s => s.toCompanyId === Guid.Empty) === -1){
                    if(filteredSteps.findIndex(s => s.status !== OrderLineStepStatus.Accepted) === -1){
                        phase = 2;
                        status = 4;
                    }
                    else {
                        if(filteredSteps.findIndex(s => s.status === OrderLineStepStatus.WaitingForApproval) !== -1){
                            phase = 1;
                            status = 2;
                        }
                        else{
                            phase = 1;
                            status = 1;
                        }
                    }
                }
            }
            const unpublishPhaseStatus: PublishUnpublishReqModel = {
                orderId: this.orderLineData.orderId,
                lineID: this.orderLineData.id,
                orderLinePhase: phase,
                orderLineStatus: status,
            };
            this.updatePhaseStatus([unpublishPhaseStatus], 'INDIVIDUAL');
        }
        finally {
            this.orderStatusChanging = false;
        }
    }

    private get orderLineInfo(): OrderLineDisplayModel {
        return this.orderLineData;
    }

    private get publishPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.publishUnpublishQrCode : 0;
    }

    @Emit()
    private selectQrCode(order: OrderLineDisplayModel, type: string): void {}

    @Emit()
    private updatePhaseStatus(order: PublishUnpublishReqModel[], type: string): void {}
}