export class SupplierOverviewModel {
    public constructor(
        public id: string,
        public name: string,
        public country: string,
        public isCompliant: boolean,
        public isTier1: boolean,
        public isOnBoarded: boolean,
        public isAgent: boolean,
        public show: boolean = true,
        public relatioshipWithClient: string = '', // TTD-1672
        public partnerOf: string = '', // TTD-1672
        public isDelegatePartner: boolean = false, // TTD-1672
        public associatedWithClientVia: string = '' // TTD-1672
    ) {}
}