import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";

// Components
import SupplyChainPartner from '@/views/supplyChainPartners/components/supplyChainPartner.vue';
import Certificates from '@/views/supplyChainPartners/components/certificates.vue';
import SupplyChainPartnerLoader from '@/views/supplyChainPartners/components/supplyChainPartnerLoader.vue';
import SupplyChainPartnerDetails from '@/views/supplyChainPartners/components/supplyChainPartnerDetails.vue';
import SupplyChainPartnerDetailsLoader from '@/views/supplyChainPartners/components/supplyChainPartnerDetailsLoader.vue';
import InviteNew from '@/components/general/invite-new/inviteNew.vue';
import PartnerComplianceDetails from '@/views/supplyChainPartners/components/partnerComplianceDetails.vue';
import EsgData from "@/views/supplyChainPartners/components/esgData.vue";
import SharingSetting from "@/views/supplyChainPartners/components/sharingSetting.vue";

// Services
import { SupplierService } from "@/services/supplierService";
import { ComplianceService } from "@/services/complianceService";
import { ClientService } from "@/services/clientService";
import { OrderService } from "@/services/orderService";

// Helpers
import { CountryHelper } from "@/helpers/countryHelper";

// Models
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { DropdownModel } from '@/models/dropdownModel';
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { SupplierModel, SupplierContactInfoList } from "@/models/supplierModel";
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { UserModel } from "@/models/userModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { BrandWithSupplierModel, SupplierPartnersBrandsModel } from "@/models/brandWithSupplierModel";
import { Guid } from "@/models/guid";
import { CompanyModel } from "@/models/companyModel";
import store from "@/store";
import { SustainableCertificatesModel } from "@/models/sustainableCertificatesModel";
import { SocialCertificatesModel } from "@/models/socialCertificatesModel";
import { ProductQualityReportModel } from "@/models/productQualityReportModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { ActionTypes } from "@/store/actions/actions";
import { CountryListModel } from "@/models/countryLanguageModel";
import { CertificateModel, CertificateTypes } from "@/models/certificateModel";
import { AddressModel } from "@/models/addressModel";
import { ClientRelationship } from "@/models/sharingSetting";

@Component({
    components: { SupplyChainPartner, SupplyChainPartnerLoader, SupplyChainPartnerDetails, SupplyChainPartnerDetailsLoader, InviteNew, PartnerComplianceDetails, Certificates,
        EsgData, SharingSetting
     },
    computed: mapState(['userRole', 'userPermissions', 'orderLoading', 'orders', 'orderLineStepsLoading', 'orderLineSteps', 'user', 'company', 'suppliersCertificateLoading', 'companyType', 'countryList', 'supplierPartnersBrands', 'brandWithT1SCP', 'brandWithAgent', 'brandWithSCP'])
})
export default class SupplyChainPartners extends Vue {

    private supplierService: SupplierService;

    private complianceService: ComplianceService;

    private clientService: ClientService;

    private orderService: OrderService;

    private suppliers: SupplierOverviewModel[] = [];

    private suppliersData: OrderCompanyModel[] = [];

    private countries: DropdownModel[] = [];

    private allProductGroups: DropdownModel[] = [];

    private isLoading: boolean = false;

    private supplierNameFilter: string = "";

    private countryNameFilter: string = "";

    private sortOrder: string = "AZ";

    private showDetails: boolean = false;

    private showSearch: boolean = false;

    private selectedSupplierName: string = "";

    private selectedSupplier: SupplierModel = new SupplierModel();

    private supplierWithOrders: SupplierOverviewModel[] = [];

    private supplierWithoutOrders: SupplierOverviewModel[] = [];

    private selectedPartnerType: number = 1; // partnertype (1- tier1 , 2- agent , 3- regular/nominated , 4- invited)

    private tabIndex: number = 0;

    private detailTabIndex: number = 0;

    private supplierChanged: boolean = false;

    private loadingDoc: boolean = false;

    private complianceDocs: ComplianceDocSentResponseModel[] = [];

    private supplierId: string = '';

    private complianceDocCount: string = '';

    private brandList: string[] = [];

    private brands: DropdownModel[] = [];

    private brandWithT1SCP!: BrandWithSupplierModel[];

    private brandWithAgent!: BrandWithSupplierModel[];

    private brandWithSCP!: BrandWithSupplierModel[];

    private brandNameFilter: string = "";

    private sustainableCertificates: SustainableCertificatesModel[] = [];

    private socialCertificates: SocialCertificatesModel[] = [];

    private contactInfoList: SupplierContactInfoList[] = [];

    private certificates: { [key: string]: CertificateModel[] } = {
        social: [],
        sustainable: [],
    };

    private loadingQualityReport: boolean = false;

    private qualityReports: ProductQualityReportModel[] = [];

    private userRole!: string;

    private userPermissions!: UserPermissionModel | null;

    private orderLoading!: boolean;

    private orders!: OrderLineDisplayModel[];

    private orderLineStepsLoading!: boolean;

    private orderLineSteps!: OrderLineStepDisplayModel[];

    private user!: UserModel;

    private company!: CompanyModel;

    private suppliersCertificateLoading!: boolean;

    private companyType!: number;

    private countryList!: CountryListModel[];

    private supplierPartnersBrands!: SupplierPartnersBrandsModel;

    private allAddressess: AddressModel[] = [];

    private isPartnerSelected: boolean = false;

    private isDelegatePartner: boolean = false; // TTD-1866

    private supplierInfo: SupplierOverviewModel = new SupplierOverviewModel('', '', '', false, false, false, false); // TTD-1672

    private sharePartnersInfo: SupplierOverviewModel[] = []; // TTD-1672

    @Ref('contentElement') contentElement!: HTMLDivElement;

    public constructor() {
        super();
        this.supplierService = new SupplierService();
        this.complianceService = new ComplianceService();
        this.clientService = new ClientService();
        this.orderService = new OrderService();
        // this.brands.splice(0, 0, new DropdownModel('', 'All brands'));
    }

    private async created(): Promise<void> {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const suppliers = store.getters.suppliers as OrderCompanyModel[];
        if(this.orders.length === 0 && this.orderLoading === false && this.orderLineSteps.length === 0 && this.orderLineStepsLoading === false && suppliers.length <= 1 && this.suppliersCertificateLoading === false){
            store.dispatch(ActionTypes.SET_ORDER_DETAIL);
        } else {
            if(this.suppliersCertificateLoading === false && this.suppliers.length === 0){
                await this.getSuppliers();
            }
        }
        this.countries = CountryHelper.getCountryList();
        this.isLoading = true;
        if (this.companyType === 5) {
            this.selectedPartnerType = 3;
        }
        if(this.companyType !== 5){
            await this.getBrandList();
        }
    }

    private async getSuppliers(): Promise<void> {
        try {
            this.isLoading = true;
            this.suppliers = await this.supplierService.getSupplierOverviewAsync(this.supplierChanged);
            if(this.suppliers.length > 0){
                const index = this.suppliers.findIndex(s => s.id === this.user.companyId);
                if(index !== -1){
                    this.suppliers.splice(index, 1);
                }
                this.suppliers.forEach(s => {
                    if(s.country !== '' && s.country.length !== 2){
                        const country = this.countryList.filter(c => c.name.toLowerCase() === s.country.toLowerCase())[0];
                        if(country){
                            s.country = country.iso2;
                        }
                    }
                })
            }
        }
        finally {
            this.isLoading = false;
        }
    }

    private async getBrandList(): Promise<void> {
        // try {
            if(this.orderLoading === false && this.orders.length === 0 && this.orderLineStepsLoading === false && this.orderLineSteps.length === 0 && this.suppliersCertificateLoading === false && this.suppliers.length <= 1){
                await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
            }
            if((this.user.topBrands !== null || this.user.topBrands !== undefined) && this.user.topBrands.length > 0){
                this.brands = this.user.topBrands.map(b => new DropdownModel(b.brandName, b.brandName));
            }
            else{
                this.getCompanyProfile();
            }
            // this.brands.splice(0, 0, new DropdownModel('', 'All brands'));
    }

    private async getCompanyProfile(): Promise<void> {
        if(this.company === null){
            await store.dispatch(ActionTypes.SET_MYACCOUNT);
        }
        // this.brands = this.company.topBrands.map(brand => new DropdownModel((brand.brandName.charAt(0).toUpperCase() + brand.brandName.slice(1).toLowerCase()), (brand.brandName.charAt(0).toUpperCase() + brand.brandName.slice(1).toLowerCase())));
        this.brands = this.company.topBrands.map(brand => new DropdownModel(brand.brandName, brand.brandName));
    }

    private sortSuppliers(): void {
        if (this.sortOrder === "AZ") {
            this.sortOrder = "ZA";
        } else {
            this.sortOrder = "AZ";
        }
    }

    private get title(): string {
        if (!this.showDetails) {
            return this.$t("pages.supply_chain_partners.title").toString();
        }
        return this.selectedSupplierName;
    }

    private get isFilterResultEmpty(): boolean{
        if((this.supplierNameFilter!=='' || this.brandNameFilter!=='' || this.countryNameFilter!=='') && this.sortedSuppliers.length === 0){
            return true;
        }
        return false;
    }

    private get inviteCompanyPermission(): number {
        return this.userPermissions !== null ? this.userPermissions.inviteCompany : 0;
    }

    private get filteredSuppliers(): SupplierOverviewModel[] {

        this.suppliers.forEach(o => {
            o.show = true;
            // TTD-1672
            if(o.isTier1){
                o.relatioshipWithClient = ClientRelationship.CP;
            } else if(o.isAgent){
                o.relatioshipWithClient = ClientRelationship.AGENT;
            } else {
                o.relatioshipWithClient = ClientRelationship.NP;
            }
            o.partnerOf = this.user.companyId;
            o.associatedWithClientVia = ''; // discussed on 11th Feb, 2025 as this is not required for immediate partners of client
        });
        let suppliers: SupplierOverviewModel[] = [];

        if(this.companyType === 1){
            if(this.selectedPartnerType === 1){
                suppliers =  this.suppliers.filter((supplier) => supplier.isTier1 === true)
            }else if(this.selectedPartnerType === 3){
                suppliers = this.suppliers.filter((supplier) => supplier.isTier1 === false && supplier.isAgent === false)
            }else{
                suppliers = this.suppliers.filter((supplier) => supplier.isAgent === true)
            }
        } else {
            suppliers = this.suppliers;
        }

        if (this.supplierNameFilter !== "") {
            suppliers = suppliers.filter(o => o.name.toLowerCase().includes(this.supplierNameFilter.toLowerCase()));
        }

        if (this.countryNameFilter !== "") {
            suppliers = suppliers.filter(o => o.country.toLowerCase().includes(this.countryNameFilter.toLowerCase()));
        }

        if (this.brandNameFilter !== "" && this.brandNameFilter !== "All") {
            let brandSuppliers: string[] = [];
            const supplierList: SupplierOverviewModel[] = [];
            let brandWithSuppliers: BrandWithSupplierModel[] = [];
            if(this.companyType === 1){
                if (this.selectedPartnerType === 1) {
                    brandWithSuppliers = this.brandWithT1SCP.filter(brand => brand.brandName === this.brandNameFilter);
                    brandSuppliers = brandWithSuppliers.length > 0 ? brandWithSuppliers[0].IDs : [];
                } else if (this.selectedPartnerType === 3) {
                    brandWithSuppliers = this.brandWithSCP.filter(brand => brand.brandName === this.brandNameFilter);
                    brandSuppliers = brandWithSuppliers.length > 0 ? brandWithSuppliers[0].IDs : [];
                } else {
                    brandWithSuppliers = this.brandWithAgent.filter(brand => brand.brandName === this.brandNameFilter);
                    brandSuppliers = brandWithSuppliers.length > 0 ? brandWithSuppliers[0].IDs : [];
                }
            } else {
                brandWithSuppliers = this.brandWithSCP.filter(brand => brand.brandName === this.brandNameFilter);
                brandSuppliers = brandWithSuppliers.length > 0 ? brandWithSuppliers[0].IDs : [];
            }
            if (brandSuppliers.length > 0) {
                brandSuppliers.forEach(brand => {
                    if(suppliers.filter(s => s.id === brand)[0]){
                        supplierList.push(suppliers.filter(s => s.id === brand)[0]);
                    }
                });
                suppliers = supplierList;
            } else {
                suppliers = [];
            }
        }

        const hideSuppliers = this.suppliers.filter(supplier => suppliers.indexOf(supplier) < 0);
        hideSuppliers.forEach(supplier => {
            const supplierToHideArray = this.suppliers.filter(o => o.id === supplier.id);
            supplierToHideArray.forEach(supplierToHide => {
                supplierToHide.show = false;
            });
        });

       return suppliers;
    }

    private get sortedSuppliers(): SupplierOverviewModel[] {
        return this.filteredSuppliers.sort((supplier1, supplier2) => {
            if (supplier1.name.toLowerCase() < supplier2.name.toLowerCase()) {
                if (this.sortOrder === "AZ") {
                    return -1;
                } else {
                    return 1;
                }
            }
            if (supplier1.name.toLowerCase() > supplier2.name.toLowerCase()) {
                if (this.sortOrder === "AZ") {
                    return 1;
                } else {
                    return -1;
                }
            }
            return 0;
        });
    }

    private get activeTabName(): string {
        let tabName = '';
        if(this.tabIndex === 1){
            tabName = 'agent';
        } else if(this.tabIndex === 2){
            tabName = 'np';
        }
        return tabName;
    }

    private async openDetails(supplier: SupplierOverviewModel, isScpSelected: boolean, isDelegatePartner: boolean): Promise<void> {
        try {
            if(isScpSelected){
                this.isPartnerSelected = true;
            }
            this.detailTabIndex = 0;
            this.isLoading = true;
            this.showDetails = true;
            this.supplierInfo = supplier;
            this.selectedSupplierName = supplier.name;
            this.supplierId = supplier.id;
            this.isDelegatePartner = isDelegatePartner; // TTD-1866
            // to put api for fetching social and sustainable docs
            this.selectedSupplier = await this.supplierService.getSupplierAsync(supplier.id);
            // Sprint-26 TTD-3068
            this.contactInfoList = await this.supplierService.getSupplierContactInfoAsync(supplier.id, this.user.companyId);
            const sustainables = await this.supplierService.getSustainableCertsAsync(supplier.id);
            this.certificates.sustainable = sustainables.sustainablePdf;
            if(this.certificates.sustainable.length > 0){
                this.certificates.sustainable.forEach(sus => {
                    sus.isShowArchive = false;
                    sus.relatableType = CertificateTypes.SUSTAINABILITY;
                    sus.isArchiving = false;
                    sus.expiryInfo = '';
                    sus.expiryStatus = '';
                });
            }
            const socials = await this.supplierService.getSocialCertsAsync(supplier.id);
            this.certificates.social = socials.socialPdf;
            if(this.certificates.social.length > 0){
                this.certificates.social.forEach(soc => {
                    soc.isShowArchive = false;
                    soc.relatableType = CertificateTypes.SOCIAL;
                    soc.isArchiving = false;
                    soc.expiryInfo = '';
                    soc.expiryStatus = '';
                });
            }
            if (this.companyType!==5) {
                this.getActivityLog();
            }
            store.dispatch(ActionTypes.SET_ESG_SOCIAL_DATA, supplier.id);
            await this.getQualityReports();
        }
        finally {
            this.isLoading = false;
        }
    }

    private async getActivityLog(): Promise<void> {
        try {
            this.loadingDoc = true;
            const user = this.$store.getters.user as UserModel;
            this.complianceDocs = await this.complianceService.getActivityLogAsync(user.companyId, this.supplierId);
            this.complianceDocCount = this.complianceDocs.length === 0 ? '' : this.complianceDocs.length.toString();
        }
        finally {
            this.loadingDoc = false;
        }
    }

    private closeDetails(): void {
        this.showDetails = false;
        this.isPartnerSelected = false;
        // this.brands.splice(0, 0, new DropdownModel('', 'All brands'));
    }

    private openSearchSelectHandler(): void {
        this.showSearch = true;
    }

    private closeSearchHandler(invited: boolean): void {
        this.showSearch = false;
        if (invited) {
            this.supplierChanged = true;
            this.getSuppliers();
        }
    }

    private setSupplierNameHandler(value: string): void {
        this.showSearch = false;
    }

    private get supplierDropdownData(): DropdownModel[] {
        const suppliersOrdered = lodash.orderBy(this.suppliersData, ['name'], ['asc']);
        return suppliersOrdered.map(s => new DropdownModel(s.id, this.getSupplierDropdownText(s)));
    }

    private getSupplierDropdownText(supplierModel: OrderCompanyModel): string {
        if (supplierModel.isCompliant) {
            return `${supplierModel.companyName} (${supplierModel.country})`;
        }
        return `${supplierModel.companyName} (${this.$t('pages.home.pending_invite')})`;
    }

    private partnerType(id: number) {
        this.selectedPartnerType = id + 1;
        this.tabIndex = id;
        this.isPartnerSelected = false;
    }

    private selectedDetailTab(index: number) {
        this.detailTabIndex = index;
    }

    private async getQualityReports(): Promise<void> {
        try {
            this.loadingQualityReport = true;
            const user = this.$store.getters.user as UserModel;
            this.qualityReports = await this.supplierService.getQualityReportsAsync(user.companyId, this.supplierId);
            this.loadingQualityReport = false;
        }
        finally {
            // this.loadingQualityReport = false;
        }
    }

    private reloadQualityReports(reports: ProductQualityReportModel[]): void {
		this.qualityReports = reports;
	}

    private addressess(allAddressess: AddressModel[]): void {
        this.allAddressess = allAddressess;
    }

    private showFromTop(): void{
    setTimeout(() => this.$nextTick(() => {
        this.contentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }), 0);
    }

    // TTD-1672
    private sharePartnersDetails(partners: SupplierOverviewModel[]): void {
        this.sharePartnersInfo = partners;
    }

    @Watch('suppliersCertificateLoading', { deep: true })
    private callSuppliers(): void {
        if(!this.suppliersCertificateLoading){
            this.getSuppliers();
        }
    }

}
