import { Vue, Component, Prop, Emit, Ref } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from 'vuex';

// Services
import { SupplierService } from "@/services/supplierService";

// Helpers
import { SuppliersHelper } from "@/helpers/suppliersHelper";

// Models
import { SupplierModel, ContactUserList } from "@/models/supplierModel";
import { ContactModel } from "@/models/contactModel";
import { AddressModel } from "@/models/addressModel";
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { CPSCPModel, SupplierPartnerModel, UniqueSupplierWithStepModel } from "@/models/supplierPartnerModel";
import { CompanyPhotoModel } from "@/models/companyPhotoModel";
import { ComplianceDataModel } from "@/models/complianceDataModel";
import { CountryListModel } from "@/models/countryLanguageModel";
import { SupplierPartnersBrandsModel } from "@/models/brandWithSupplierModel";
import { ClientRelationship } from "@/models/sharingSetting";

@Component({
  computed: mapState(['countryList', 'supplierPartnersBrands', 't1AssociatedPartners', 'agentAssociatedPartners', 'facilities'])
})
export default class SupplyChainPartnerDetails extends Vue {

  @Prop()
  private supplier!: SupplierModel;

  @Prop()
  private selectedPartnerType!: number;

  @Prop()
  private userDetails!: ContactUserList[];

  @Prop()
  private isPartnerSelected!: boolean;

  @Prop()
  private isDelegatePartner!: boolean;

  @Prop()
  private supplierInfo!: SupplierOverviewModel;

  private supplierService: SupplierService;

  private selectedUploads: string = "photos";

  private partners: SupplierPartnerModel[] = [];

  private partnersLoading: boolean = false;

  private countryList!: CountryListModel[];

  private supplierPartnersBrands!: SupplierPartnersBrandsModel;

  private t1AssociatedPartners!: CPSCPModel[];

  private agentAssociatedPartners!: CPSCPModel[];

  private allAddressess: AddressModel[] = [];

  private sharePartnersInfo: SupplierOverviewModel[] = [];

  public constructor() {
    super();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.partnersLoading = true;
      const associatedPartners: SupplierPartnerModel[] = [];
      let uniqPartners: UniqueSupplierWithStepModel[] = [];
      let allPartners: CPSCPModel[] = [];
      this.sharePartnersInfo.push(this.supplierInfo);
      if(this.isDelegatePartner){
        const partners = await this.supplierService.getDPPartnersAsync(this.supplier.id);
        if(partners.success){
          // associatedPartners.push(...partners.supplierList);
          partners.supplierList.forEach(sup => {
            const ap: SupplierPartnerModel = sup;
            ap.isTier1 = false;
            ap.isDelegatePartner = false;
            ap.isAgent = false;
            ap.partnerOf = this.supplier.id;
            ap.relatioshipWithClient = ClientRelationship.NP_OF_DP;
            ap.associatedWithClientVia = this.supplierInfo.associatedWithClientVia;
            associatedPartners.push(ap);
            this.sharePartnersInfo.push(new SupplierOverviewModel(sup.ID, sup.partnerName, '', false, ap.isTier1, sup.onboarded, ap.isAgent, true, ap.relatioshipWithClient, ap.partnerOf, ap.isDelegatePartner, ap.associatedWithClientVia));
          })
        }
      } else {
        // TTD-3978: show suppliers associated with agent
        if(this.selectedPartnerType === 2){
          uniqPartners = SuppliersHelper.getAgentRelatedSuppliers(this.supplier.id);
          allPartners = this.agentAssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
        } else {
          // TTD-3916: show suppliers based on client and cp
          uniqPartners = SuppliersHelper.getCPRelatedSuppliers(this.supplier.id);
          allPartners = this.t1AssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
        }
        if(allPartners.length > 0){
          allPartners[0].suppliers.forEach(sup => {
            const partners = uniqPartners.filter(ap => ap.supplierId === sup.invitedCompanyID);
            if(partners.length > 0){
              const ap: SupplierPartnerModel = {
                partnerName: sup.companyName,
                companySubtypes: partners[0].steps,
                ID: sup.invitedCompanyID,
                onboarded: sup.onboarded,
                isDelegatePartner: sup.isDelegatePartner,
                isAgent: sup.isAgent,
                isTier1: sup.isTier1,
                partnerOf: allPartners[0].companyID,
                associatedWithClientVia: allPartners[0].companyID
              }
              if(!sup.isTier1 && !sup.isAgent && !sup.isDelegatePartner && this.supplierInfo.isTier1){
                ap.relatioshipWithClient = ClientRelationship.NP_OF_CP;
              } else if(!sup.isTier1 && !sup.isAgent && sup.isDelegatePartner && this.supplierInfo.isTier1){
                ap.relatioshipWithClient = ClientRelationship.DP_OF_CP;
              } else if(!sup.isTier1 && !sup.isAgent && !sup.isDelegatePartner && !this.supplierInfo.isTier1 && this.supplierInfo.isAgent){
                ap.relatioshipWithClient = ClientRelationship.NP_OF_AGENT;
              } else if(!sup.isTier1 && !sup.isAgent && !sup.isDelegatePartner && !this.supplierInfo.isTier1 && !this.supplierInfo.isAgent && this.supplierInfo.isDelegatePartner){
                ap.relatioshipWithClient = ClientRelationship.NP_OF_DP;
              }
              ap.associatedWithClientVia = (ap.relatioshipWithClient === ClientRelationship.NP_OF_CP || ap.relatioshipWithClient === ClientRelationship.DP_OF_CP || ap.relatioshipWithClient === ClientRelationship.NP_OF_AGENT || ap.relatioshipWithClient === ClientRelationship.NP_OF_DP) ? allPartners[0].companyID : '';
              associatedPartners.push(ap);
              this.sharePartnersInfo.push(new SupplierOverviewModel(sup.invitedCompanyID, sup.companyName, sup.country, false, sup.isTier1, sup.onboarded, sup.isAgent, true, ap.relatioshipWithClient, ap.partnerOf, ap.isDelegatePartner, ap.associatedWithClientVia));
            }
          })
        }
      }
      this.partners = associatedPartners;
      this.syncFacilityAddress();
      this.showTop();
      this.sharePartnersDetails(this.sharePartnersInfo);
    } finally {
      this.partnersLoading = false;
    }
  }

  private get partnersSorted(): SupplierPartnerModel[] {
    return this.partners.sort((partner1, partner2) => {
        if (partner1.partnerName < partner2.partnerName) {
            return -1;
        }
        if (partner1.partnerName > partner2.partnerName) {
            return 1;
        }
        return 0;
    });
  }

  private get selectedUploadText(): string {
    if (this.selectedUploads === "photos") {
      return this.$t("pages.supply_chain_partners.company_photos").toString();
    }
    return this.$t("pages.supply_chain_partners.certificates").toString();
  }

  private get contact(): ContactModel {
    return this.supplier.admin;
  }

  private photos(addressId:string): CompanyPhotoModel[] {
    return this.supplier.profilePictures.filter(p => p.addressID === addressId);
  }

  private get certificates(): ComplianceDataModel[] {
    const socialCertificates =
      this.supplier.social
        .map(item => new ComplianceDataModel (
          item.id,
          item.issuer,
          item.referenceIssuer,
          item.type,
          item.validToDate,
          this.$t(`enums.social_certificates.social.${item.type}`).toString(),
          this.$t("pages.supply_chain_partners.social").toString()
        ));

    const sustainabilityCertificates =
      this.supplier.sustainability
      .map(item => new ComplianceDataModel (
        item.id,
        item.issuer,
        item.referenceIssuer,
        item.type,
        item.validToDate,
        this.$t(`enums.sustainability_certificates.sustainability.${item.type}`).toString(),
        this.$t("pages.supply_chain_partners.sustainable").toString()
      ));

    const combined = socialCertificates.concat(sustainabilityCertificates);
    return combined;
  }

  private get officeAddress(): AddressModel | null {
    if (this.supplier.officeAddress == null) {
      return null;
    }

    return this.supplier.officeAddress[0]!;
  }

  private get productGroups(): string {
    if (this.supplier.productGroups.length === 0) {
      return "-";
    }
    const productGroupNames = lodash.uniq(this.supplier.productGroups).map(p => this.$t(`enums.product_group.${p}`).toString().charAt(0).toUpperCase() + this.$t(`enums.product_group.${p}`).toString().slice(1).split(/(?=[A-Z])/).join(" ").toLowerCase()).join(", ");
    return productGroupNames.split(/(?=[A-Z])/).join(" ");
  }

  private get categories(): string {
    if (this.supplier.categories.length === 0) {
      return "-";
    }

    return lodash.uniq(this.supplier.categories).map(c => this.$t(`enums.category.${c}`).toString()).join(", ");
  }

  private get companySubtypes(): string {
    if (this.supplier.companySubtypes.length === 0) {
      return "-";
    }

    return lodash.uniq(this.supplier.companySubtypes).map(c => this.$t(`enums.steps.${c}`).toString()).join(", ");
  }
  // Sprint-26 TTD-3068
  private faciclitySubTypes(index:number): string {
      if(this.allAddressess[index].companySubtypes.length > 0){
        return lodash.uniq(this.allAddressess[index].companySubtypes).map(c => this.$t(`enums.steps.${c}`).toString()).join(", ");
      }
      return "-"
  }

  private get companyType(): string {
    if (this.supplier.companyType === undefined || this.supplier.companyType === null || this.supplier.companyType === 0) {
      return "-";
    }

    return this.$t(`enums.company_type.${this.supplier.companyType}`).toString();
  }
  // Sprint-26 TTD-3068
  private facilityCountry(index:number, type:string): string{
    let countryName = '';
    if (this.allAddressess.length === 0) {
      return "-";
    }
    if(this.allAddressess[index].country.length === 2){
      countryName = this.$t(`countries.${this.allAddressess[index].country}`).toString();
    }else{
      const address = this.allAddressess[index];
      if(address !== null && address !== undefined){
        const country = this.countryList.filter(c => c.name.toLowerCase() === address.country.toLowerCase())[0];
        if(country){
          countryName = this.$t(`countries.${country.iso2}`).toString();
        } else {
          countryName = address.country.toString();
        }
      }
    }
    return countryName;
  }

  private getTypeText(type: number): string {
    return this.$t(`enums.steps.${type}`).toString();
  }

  private toggleUploadType(): void {
    this.selectedUploads = this.selectedUploads === "photos" ? "certificates" : "photos";
  }

  private openScpDetails(partner: any, e: any): void {
    e.stopPropagation();
    let scpPartner:SupplierOverviewModel;
     scpPartner = {
      id: partner.ID,
      name: partner.partnerName,
      country: partner.country,
      isTier1: partner.isTier1,
      isOnBoarded: partner.onboarded,
      isAgent: partner.isAgent,
      isDelegatePartner: partner.isDelegatePartner,
      relatioshipWithClient: partner.relatioshipWithClient,
      associatedWithClientVia: partner.associatedWithClientVia,
      partnerOf: partner.partnerOf,
      isCompliant: false,
      show: true
    };
    this.openDetails(scpPartner, !partner.isAgent && !partner.isDelegatePartner && !partner.isTier1 ? true : false, partner.isDelegatePartner);
  }

  private syncFacilityAddress(): void {
    if(this.supplier.newFacilityData === 'YES'){
      const officeAddress = this.supplier.officeAddress.filter(add => add.addressStatus !== 'DISABLED');
      const factoryAddresses = this.supplier.factoryAddresses.filter(add => add.addressStatus !== 'DISABLED');
      this.allAddressess.push(...officeAddress, ...factoryAddresses);
      this.allAddressess = lodash.uniqBy(this.allAddressess, 'id');
      this.allAddressess.sort((a, b) => a.position - b.position);
    } else {
      const officeAddress: AddressModel[] = [];
      this.supplier.officeAddress.forEach(add => {
        if(add.addressStatus !== 'DISABLED'){
          add.facilityType = 'Office';
          officeAddress.push(add);
        }
      });
      officeAddress.sort((a, b) => a.sequence - b.sequence);
      const factoryAddress: AddressModel[] = [];
      if(this.supplier.factoryAddressDiffers){
        this.supplier.factoryAddresses.forEach(add => {
          if(add.addressStatus !== 'DISABLED'){
            add.facilityType = 'Factory';
            factoryAddress.push(add);
          }
        });
        factoryAddress.sort((a, b) => a.sequence - b.sequence);
      }
      this.allAddressess.push(...officeAddress, ...factoryAddress);
      this.allAddressess = lodash.uniqBy(this.allAddressess, 'id');
    }
    if(this.allAddressess.length > 0){
      const oldAddIndex = this.allAddressess.findIndex(a => a.employees === -1);
      if(oldAddIndex!==-1){
        this.allAddressess[oldAddIndex].employees = this.supplier.employees == 0 ? -1 : this.supplier.employees;
        this.allAddressess[oldAddIndex].femaleEmployees = this.supplier.femaleEmployees == 0 ? -1 : this.supplier.femaleEmployees;
        this.allAddressess[oldAddIndex].maleEmployees = this.supplier.maleEmployees == 0 ? -1 : this.supplier.maleEmployees;
        this.allAddressess[oldAddIndex].monthlyCapacity = this.supplier.monthlyCapacity == 0 ? -1 : this.supplier.monthlyCapacity;
      }
    }
    this.addressess(this.allAddressess);
  }
  // Sprint-26 TTD-3068
  private toggleAddress(index:number){
    this.allAddressess[index].isExpanded=!this.allAddressess[index].isExpanded;
    const address = this.allAddressess[index];
    this.allAddressess.splice(index, 1);
    this.allAddressess.splice(index, 0, address);
  }
  // Sprint-26 TTD-3068
  // private toggleFactoryAddress(index:number){
  //   if(this.supplier.factoryAddresses){
  //     this.supplier.factoryAddresses[index].isExpanded=!this.supplier.factoryAddresses[index].isExpanded;
  //     const factoryAdd = this.supplier.factoryAddresses[index];
  //     this.supplier.factoryAddresses.splice(index, 1);
  //     this.supplier.factoryAddresses.splice(index, 0, factoryAdd);
  //   }
  // }
  // Sprint-26 TTD-3068
  private getContactName(firstName:string, lastName:string): string {
    return firstName + ' ' + lastName;
  }

  private getUnits(val: string): string{
    return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
  }

  @Emit()
  private showTop(): void{}

  @Emit()
  private openDetails(supplier: SupplierOverviewModel, selected: boolean, isDelegatePartner: boolean): void {
  }

  @Emit()
  private addressess(allAddressess: AddressModel[]): void {}

  @Emit()
  private sharePartnersDetails(partners: SupplierOverviewModel[]): void {}
}
