import { Vue, Component } from "vue-property-decorator";
import i18n from "@/i18n";
import * as XLSX from 'xlsx';
import moment from "moment";
import { mapState } from 'vuex';
import { ActionTypes } from "@/store/actions/actions";

// Service
import { ClientService } from "@/services/clientService";

// Helper
import { NotificationHelper } from "@/helpers/notificationHelper";
import { SuppliersHelper } from "@/helpers/suppliersHelper";
import { ProductGroupHelper } from "@/helpers/productGroupHelper";

// Model
import { ClientRelatedSupplierModel } from "@/models/clientRelatedSupplierModel";
import { SustainableCertificatesModel } from "@/models/sustainableCertificatesModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { UserModel } from "@/models/userModel";
import { CompanyNameModel } from "@/models/companyModel";
import {
  CertificateOnTypeModel,
  CertificateTypes,
} from "@/models/certificateModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { StepsHelper } from "@/helpers/stepsHelper";

@Component({
  computed: mapState([
    'orderLoading',
    'orders',
    'orderLineStepsLoading',
    'clientRelatedSupplierIds',
    'user',
    'supplierCompanies',
    'companyNameList',
    'orderLineSteps',
  ]),
})
export default class Reports extends Vue {
  private clientService: ClientService;

  private orderLoading!: boolean;

  private orders!: OrderLineDisplayModel[];

  private orderLineStepsLoading!: boolean;

  private clientRelatedSupplierIds!: string[];

  private user!: UserModel;

  private supplierCompanies!: ClientRelatedSupplierModel[];

  private isSupplierReportLoading: boolean = false;

  private isOrderLineStepsReportLoading: boolean = false;

  private isOrderlineReportLoading: boolean = false;

  private companyNameList!: CompanyNameModel[];

  private sustainableCerts: CertificateOnTypeModel[] = [];
  private sustainableAuditCerts: CertificateOnTypeModel[] = [];
  private sustainableMemCerts: CertificateOnTypeModel[] = [];
  private socialCerts: CertificateOnTypeModel[] = [];
  private socailAuditCerts: CertificateOnTypeModel[] = [];
  private socailMemCerts: CertificateOnTypeModel[] = [];
  private membershipCerts: CertificateOnTypeModel[] = [];
  private orderLineSteps!: OrderLineStepDisplayModel[]; // TTD-5246 Sprint 30

  public constructor() {
    super();
    this.clientService = new ClientService();
  }

  private async created(): Promise<void> {
    if (this.orderLoading === false && this.orders.length === 0) {
      await this.$store.dispatch(ActionTypes.SET_ORDER_DETAIL);
    }
    const sustainable = i18n.t(
      `enums.sustainability_certificates.sustainability`
    ) as any;
    for (const key of Object.keys(sustainable)) {
      const certifcateName = sustainable[key];
      this.sustainableCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.SUSTAINABILITY,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const susMembership = i18n.t(
      `enums.sustainability_certificates.membership`
    ) as any;
    for (const key of Object.keys(susMembership)) {
      const certifcateName = susMembership[key];
      this.sustainableMemCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SUSTAINABILITY
        )
      );
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
    for (const key of Object.keys(susAudit)) {
      const certifcateName = susAudit[key];
      this.sustainableAuditCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.AUDIT,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const social = i18n.t(`enums.social_certificates.social`) as any;
    for (const key of Object.keys(social)) {
      const certifcateName = social[key];
      this.socialCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.SOCIAL,
          CertificateTypes.SOCIAL
        )
      );
    }
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
      const certifcateName = socialAudit[key];
      this.socailAuditCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.AUDIT,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const socialMembership = i18n.t(
      `enums.social_certificates.membership`
    ) as any;
    for (const key of Object.keys(socialMembership)) {
      const certifcateName = socialMembership[key];
      this.socailMemCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SOCIAL
        )
      );
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SOCIAL
        )
      );
    }
  }

  private async generateSupplierReport(): Promise<void> {
    this.isSupplierReportLoading = true;
    if (this.supplierCompanies.length === 0) {
      await this.clientService.setSuppliersListForReportAsync(
        this.clientRelatedSupplierIds,
        this.user.companyId
      );
    }
    if (this.supplierCompanies.length > 0) {
      const data = [
        [
          '',
          '',
          '',
          '',
          'OFFICE ADDRESS',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'FACTORY ADDRESS',
          '',
          '',
          '',
          '',
          '',
        ],
        [
          'TYPE OF SCP',
          'NAME',
          'BUSINESS REG NUMBER',
          'STATUS (ONBOARDED YES/NO)',
          'COUNTRY',
          'POSTAL CODE',
          'CITY',
          'ADDRESS',
          'GEO LAT',
          'GEO LONG',
          'CONTACT',
          'EMAIL',
          'TELEPHONE',
          'NUMBER OF EMPLOYEES',
          'BRANDS',
          'MONTHLY CAPACITY',
          'TURNOVER',
          'PROFILE',
          'COMPANY PICTURES UPLOADED',
          'CERTIFICATE NAME',
          'ISSUER',
          'REFERENCE ISSUER',
          'UPLOADED AT',
          'VALID TO',
          'Quality report description',
          'Quality report issuer',
          'Quality report reference issuer',
          'Quality report valid to',
          'COUNTRY',
          'POSTAL CODE',
          'CITY',
          'ADDRESS',
          'GEO LAT',
          'GEO LONG',
        ],
      ];
      this.supplierCompanies.forEach((company) => {
        company.name =
          this.companyNameList.filter((c) => c.ID === company.companyID)
            .length > 0
            ? this.companyNameList.filter((c) => c.ID === company.companyID)[0]
                .name
            : company.name;
        const allCertificates: SustainableCertificatesModel[] = [];
        allCertificates.push(
          ...company.social.map(
            (c) =>
              new SustainableCertificatesModel(
                c.ID,
                c.type,
                c.issuer,
                c.referenceIssuer,
                c.createdAt,
                c.validtoDate,
                c.otherCertName,
                'social'
              )
          )
        );
        allCertificates.push(
          ...company.sustainability.map(
            (c) =>
              new SustainableCertificatesModel(
                c.ID,
                c.type,
                c.issuer,
                c.referenceIssuer,
                c.createdAt,
                c.validtoDate,
                c.otherCertName,
                'sustainable'
              )
          )
        );
        company.factoryAddresses =
          company.factoryAddresses === null ||
          company.factoryAddresses === undefined
            ? []
            : company.factoryAddresses;
        company.productQualityReport =
          company.productQualityReport === null ||
          company.productQualityReport === undefined
            ? []
            : company.productQualityReport;
        if (
          allCertificates.length > company.factoryAddresses.length &&
          allCertificates.length > company.productQualityReport.length
        ) {
          allCertificates.forEach((cert, index) => {
            const factoryAddress = {
              id: '',
              country: '',
              city: '',
              postal: '',
              address: '',
              geoLat: '',
              geoLong: '',
            };
            const qualityReport = {
              description: '',
              issuer: '',
              referenceIssuer: '',
              validTo: '',
            };
            let certName = '';
            if (cert.certType === 'social') {
              if (
                this.socialCerts.findIndex(
                  (so) => so.ID === cert.type.toString()
                ) !== -1
              ) {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.social.${cert.type}`)
                        .toString();
              } else if (
                this.socailAuditCerts.findIndex(
                  (au) => au.ID === cert.type.toString()
                ) !== -1
              ) {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.audit.${cert.type}`)
                        .toString();
              } else {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.membership.${cert.type}`)
                        .toString();
              }
            } else {
              if (
                this.sustainableCerts.findIndex(
                  (so) => so.ID === cert.type.toString()
                ) !== -1
              ) {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.sustainability.${cert.type}`
                        )
                        .toString();
              } else if (
                this.socailAuditCerts.findIndex(
                  (au) => au.ID === cert.type.toString()
                ) !== -1
              ) {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.audit.${cert.type}`
                        )
                        .toString();
              } else {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.membership.${cert.type}`
                        )
                        .toString();
              }
            }
            if (
              company.factoryAddresses.length > 0 &&
              index < company.factoryAddresses.length
            ) {
              factoryAddress.id = company.factoryAddresses[index].id;
              factoryAddress.address =
                company.factoryAddresses[index].line1 +
                ' ' +
                company.factoryAddresses[index].line2;
              factoryAddress.country =
                company.factoryAddresses[index].country !== ''
                  ? this.$t(
                      `countries.${company.factoryAddresses[index].country}`
                    ).toString()
                  : '';
              factoryAddress.city = company.factoryAddresses[index].city;
              factoryAddress.postal = company.factoryAddresses[index].postal;
              factoryAddress.geoLat =
                company.factoryAddresses[index].geoLat.toString();
              factoryAddress.geoLong =
                company.factoryAddresses[index].geoLong.toString();
            }
            if (
              company.productQualityReport.length > 0 &&
              index < company.productQualityReport.length
            ) {
              qualityReport.description =
                company.productQualityReport[index].description;
              qualityReport.issuer = company.productQualityReport[index].issuer;
              qualityReport.referenceIssuer =
                company.productQualityReport[index].referenceIssuer;
              qualityReport.validTo = moment(
                company.productQualityReport[index].validToDate
              ).format('D MMM YYYY');
            }
            if (index === 0) {
              let companyType = '';
              let isOnboarded = '';
              switch (company.companyType) {
                case 0:
                  companyType = 'Manufacturer';
                  break;
                case 1:
                  companyType = 'Brand / Retailer';
                  break;
                case 2:
                  companyType = 'Agent / Importer';
                  break;
                case 3:
                  companyType = 'Manufacturer';
                  break;
                case 4:
                  companyType = 'Logistics partner';
                  break;
              }
              company.companyType === 0
                ? (isOnboarded = 'No')
                : (isOnboarded = 'Yes');
              const companyPicturesUploaded =
                company.profilePics === null ||
                company.profilePics === undefined
                  ? 'No'
                  : company.profilePics.length === 0
                  ? 'No'
                  : 'Yes';
              data.push([
                companyType,
                company.name,
                company.companyID,
                isOnboarded,
                this.$t(
                  `countries.${company.officeAddresses[0].country}`
                ).toString(),
                company.officeAddresses[0].postal,
                company.officeAddresses[0].city,
                company.officeAddresses[0].line1 +
                  ' ' +
                  company.officeAddresses[0].line2,
                company.officeAddresses[0].geoLat.toString(),
                company.officeAddresses[0].geoLong.toString(),
                company.contact.firstName + ' ' + company.contact.lastName,
                company.contact.email,
                company.contact.phone,
                company.employees.toString(),
                company.brands,
                company.monthlyCapacity.toString(),
                company.turnover.toString(),
                company.profile,
                companyPicturesUploaded,
                certName,
                cert.issuer,
                cert.referenceIssuer,
                moment(cert.createdAt).format('D MMM YYYY'),
                moment(cert.validtoDate).format('D MMM YYYY'),
                qualityReport.description,
                qualityReport.issuer,
                qualityReport.referenceIssuer,
                qualityReport.validTo,
                factoryAddress.country,
                factoryAddress.postal,
                factoryAddress.city,
                factoryAddress.address,
                factoryAddress.geoLat,
                factoryAddress.geoLong,
              ]);
            } else {
              data.push([
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                certName,
                cert.issuer,
                cert.referenceIssuer,
                moment(cert.createdAt).format('D MMM YYYY'),
                moment(cert.validtoDate).format('D MMM YYYY'),
                qualityReport.description,
                qualityReport.issuer,
                qualityReport.referenceIssuer,
                qualityReport.validTo,
                factoryAddress.country,
                factoryAddress.postal,
                factoryAddress.city,
                factoryAddress.address,
                factoryAddress.geoLat,
                factoryAddress.geoLong,
              ]);
            }
          });
        } else if (
          company.factoryAddresses.length > allCertificates.length &&
          company.factoryAddresses.length > company.productQualityReport.length
        ) {
          company.factoryAddresses.forEach((address, index) => {
            const certificate = {
              certName: '',
              issuer: '',
              referenceIssuer: '',
              createdAt: '',
              validtoDate: '',
            };
            const qualityReport = {
              description: '',
              issuer: '',
              referenceIssuer: '',
              validTo: '',
            };
            if (allCertificates.length > 0 && index < allCertificates.length) {
              let certName = '';
              const cert = allCertificates[index];
              if (allCertificates[index].certType === 'social') {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.social.${cert.type}`)
                        .toString();
              } else {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.sustainability.${cert.type}`
                        )
                        .toString();
              }
              certificate.certName = certName;
              certificate.issuer = allCertificates[index].issuer;
              certificate.referenceIssuer =
                allCertificates[index].referenceIssuer;
              certificate.createdAt = moment(
                allCertificates[index].createdAt
              ).format('D MMM YYYY');
              certificate.validtoDate = moment(
                allCertificates[index].validtoDate
              ).format('D MMM YYYY');
            }
            if (
              company.productQualityReport.length > 0 &&
              index < company.productQualityReport.length
            ) {
              qualityReport.description =
                company.productQualityReport[index].description;
              qualityReport.issuer = company.productQualityReport[index].issuer;
              qualityReport.referenceIssuer =
                company.productQualityReport[index].referenceIssuer;
              qualityReport.validTo = moment(
                company.productQualityReport[index].validToDate
              ).format('D MMM YYYY');
            }
            address.country =
              address.country !== ''
                ? this.$t(`countries.${address.country}`).toString()
                : '';
            if (index === 0) {
              let companyType = '';
              let isOnboarded = '';
              switch (company.companyType) {
                case 0:
                  companyType = 'Manufacturer';
                  break;
                case 1:
                  companyType = 'Brand / Retailer';
                  break;
                case 2:
                  companyType = 'Agent / Importer';
                  break;
                case 3:
                  companyType = 'Manufacturer';
                  break;
                case 4:
                  companyType = 'Logistics partner';
                  break;
              }
              company.companyType === 0
                ? (isOnboarded = 'No')
                : (isOnboarded = 'Yes');
              const companyPicturesUploaded =
                company.profilePics === null ||
                company.profilePics === undefined
                  ? 'No'
                  : company.profilePics.length === 0
                  ? 'No'
                  : 'Yes';
              data.push([
                companyType,
                company.name,
                company.companyID,
                isOnboarded,
                this.$t(
                  `countries.${company.officeAddresses[0].country}`
                ).toString(),
                company.officeAddresses[0].postal,
                company.officeAddresses[0].city,
                company.officeAddresses[0].line1 +
                  ' ' +
                  company.officeAddresses[0].line2,
                company.officeAddresses[0].geoLat.toString(),
                company.officeAddresses[0].geoLong.toString(),
                company.contact.firstName + ' ' + company.contact.lastName,
                company.contact.email,
                company.contact.phone,
                company.employees.toString(),
                company.brands,
                company.monthlyCapacity.toString(),
                company.turnover.toString(),
                company.profile,
                companyPicturesUploaded,
                certificate.certName,
                certificate.issuer,
                certificate.referenceIssuer,
                certificate.createdAt,
                certificate.validtoDate,
                qualityReport.description,
                qualityReport.issuer,
                qualityReport.referenceIssuer,
                qualityReport.validTo,
                address.country,
                address.postal,
                address.city,
                address.line1 + ' ' + address.line2,
                address.geoLat.toString(),
                address.geoLong.toString(),
              ]);
            } else {
              data.push([
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                certificate.certName,
                certificate.issuer,
                certificate.referenceIssuer,
                certificate.createdAt,
                certificate.validtoDate,
                qualityReport.description,
                qualityReport.issuer,
                qualityReport.referenceIssuer,
                qualityReport.validTo,
                address.country,
                address.postal,
                address.city,
                address.line1 + ' ' + address.line2,
                address.geoLat.toString(),
                address.geoLong.toString(),
              ]);
            }
          });
        } else if (
          company.productQualityReport.length > allCertificates.length &&
          company.productQualityReport.length > company.factoryAddresses.length
        ) {
          company.productQualityReport.forEach((report, index) => {
            const certificate = {
              certName: '',
              issuer: '',
              referenceIssuer: '',
              createdAt: '',
              validtoDate: '',
            };
            const factoryAddress = {
              id: '',
              country: '',
              city: '',
              postal: '',
              address: '',
              geoLat: '',
              geoLong: '',
            };
            if (allCertificates.length > 0 && index < allCertificates.length) {
              let certName = '';
              const cert = allCertificates[index];
              if (allCertificates[index].certType === 'social') {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.social.${cert.type}`)
                        .toString();
              } else {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.sustainability.${cert.type}`
                        )
                        .toString();
              }
              certificate.certName = certName;
              certificate.issuer = allCertificates[index].issuer;
              certificate.referenceIssuer =
                allCertificates[index].referenceIssuer;
              certificate.createdAt = moment(
                allCertificates[index].createdAt
              ).format('D MMM YYYY');
              certificate.validtoDate = moment(
                allCertificates[index].validtoDate
              ).format('D MMM YYYY');
            }
            if (
              company.factoryAddresses.length > 0 &&
              index < company.factoryAddresses.length
            ) {
              factoryAddress.id = company.factoryAddresses[index].id;
              factoryAddress.address =
                company.factoryAddresses[index].line1 +
                ' ' +
                company.factoryAddresses[index].line2;
              factoryAddress.country =
                company.factoryAddresses[index].country !== ''
                  ? this.$t(
                      `countries.${company.factoryAddresses[index].country}`
                    ).toString()
                  : '';
              factoryAddress.city = company.factoryAddresses[index].city;
              factoryAddress.postal = company.factoryAddresses[index].postal;
              factoryAddress.geoLat =
                company.factoryAddresses[index].geoLat.toString();
              factoryAddress.geoLong =
                company.factoryAddresses[index].geoLong.toString();
            }
            if (index === 0) {
              let companyType = '';
              let isOnboarded = '';
              switch (company.companyType) {
                case 0:
                  companyType = 'Manufacturer';
                  break;
                case 1:
                  companyType = 'Brand / Retailer';
                  break;
                case 2:
                  companyType = 'Agent / Importer';
                  break;
                case 3:
                  companyType = 'Manufacturer';
                  break;
                case 4:
                  companyType = 'Logistics partner';
                  break;
              }
              company.companyType === 0
                ? (isOnboarded = 'No')
                : (isOnboarded = 'Yes');
              const companyPicturesUploaded =
                company.profilePics === null ||
                company.profilePics === undefined
                  ? 'No'
                  : company.profilePics.length === 0
                  ? 'No'
                  : 'Yes';
              data.push([
                companyType,
                company.name,
                company.companyID,
                isOnboarded,
                this.$t(
                  `countries.${company.officeAddresses[0].country}`
                ).toString(),
                company.officeAddresses[0].postal,
                company.officeAddresses[0].city,
                company.officeAddresses[0].line1 +
                  ' ' +
                  company.officeAddresses[0].line2,
                company.officeAddresses[0].geoLat.toString(),
                company.officeAddresses[0].geoLong.toString(),
                company.contact.firstName + ' ' + company.contact.lastName,
                company.contact.email,
                company.contact.phone,
                company.employees.toString(),
                company.brands,
                company.monthlyCapacity.toString(),
                company.turnover.toString(),
                company.profile,
                companyPicturesUploaded,
                certificate.certName,
                certificate.issuer,
                certificate.referenceIssuer,
                certificate.createdAt,
                certificate.validtoDate,
                report.description,
                report.issuer,
                report.referenceIssuer,
                moment(report.validToDate).format('D MMM YYYY'),
                factoryAddress.country,
                factoryAddress.postal,
                factoryAddress.city,
                factoryAddress.address,
                factoryAddress.geoLat,
                factoryAddress.geoLong,
              ]);
            } else {
              data.push([
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                certificate.certName,
                certificate.issuer,
                certificate.referenceIssuer,
                certificate.createdAt,
                certificate.validtoDate,
                report.description,
                report.issuer,
                report.referenceIssuer,
                moment(report.validToDate).format('D MMM YYYY'),
                factoryAddress.country,
                factoryAddress.postal,
                factoryAddress.city,
                factoryAddress.address,
                factoryAddress.geoLat,
                factoryAddress.geoLong,
              ]);
            }
          });
        } else {
          if (
            allCertificates.length === 0 &&
            company.factoryAddresses.length === 0 &&
            company.productQualityReport.length === 0
          ) {
            let companyType = '';
            let isOnboarded = '';
            switch (company.companyType) {
              case 0:
                companyType = 'Manufacturer';
                break;
              case 1:
                companyType = 'Brand / Retailer';
                break;
              case 2:
                companyType = 'Agent / Importer';
                break;
              case 3:
                companyType = 'Manufacturer';
                break;
              case 4:
                companyType = 'Logistics partner';
                break;
            }
            company.companyType === 0
              ? (isOnboarded = 'No')
              : (isOnboarded = 'Yes');
            const companyPicturesUploaded =
              company.profilePics === null || company.profilePics === undefined
                ? 'No'
                : company.profilePics.length === 0
                ? 'No'
                : 'Yes';
            data.push([
              companyType,
              company.name,
              company.companyID,
              isOnboarded,
              this.$t(
                `countries.${company.officeAddresses[0].country}`
              ).toString(),
              company.officeAddresses[0].postal,
              company.officeAddresses[0].city,
              company.officeAddresses[0].line1 +
                ' ' +
                company.officeAddresses[0].line2,
              company.officeAddresses[0].geoLat.toString(),
              company.officeAddresses[0].geoLong.toString(),
              company.contact.firstName + ' ' + company.contact.lastName,
              company.contact.email,
              company.contact.phone,
              company.employees.toString(),
              company.brands,
              company.monthlyCapacity.toString(),
              company.turnover.toString(),
              company.profile,
              companyPicturesUploaded,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ]);
          } else if (
            allCertificates.length === company.factoryAddresses.length
          ) {
            allCertificates.forEach((cert, index) => {
              const factoryAddress = {
                id: '',
                country: '',
                city: '',
                postal: '',
                address: '',
                geoLat: '',
                geoLong: '',
              };
              const qualityReport = {
                description: '',
                issuer: '',
                referenceIssuer: '',
                validTo: '',
              };
              let certName = '';
              if (cert.certType === 'social') {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.social.${cert.type}`)
                        .toString();
              } else {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.sustainability.${cert.type}`
                        )
                        .toString();
              }
              if (company.factoryAddresses.length > 0) {
                factoryAddress.id = company.factoryAddresses[index].id;
                factoryAddress.address =
                  company.factoryAddresses[index].line1 +
                  ' ' +
                  company.factoryAddresses[index].line2;
                factoryAddress.country =
                  company.factoryAddresses[index].country !== ''
                    ? this.$t(
                        `countries.${company.factoryAddresses[index].country}`
                      ).toString()
                    : '';
                factoryAddress.city = company.factoryAddresses[index].city;
                factoryAddress.postal = company.factoryAddresses[index].postal;
                factoryAddress.geoLat =
                  company.factoryAddresses[index].geoLat.toString();
                factoryAddress.geoLong =
                  company.factoryAddresses[index].geoLong.toString();
              }
              if (
                company.productQualityReport.length > 0 &&
                index < company.productQualityReport.length
              ) {
                qualityReport.description =
                  company.productQualityReport[index].description;
                qualityReport.issuer =
                  company.productQualityReport[index].issuer;
                qualityReport.referenceIssuer =
                  company.productQualityReport[index].referenceIssuer;
                qualityReport.validTo = moment(
                  company.productQualityReport[index].validToDate
                ).format('D MMM YYYY');
              }
              if (index === 0) {
                let companyType = '';
                let isOnboarded = '';
                switch (company.companyType) {
                  case 0:
                    companyType = 'Manufacturer';
                    break;
                  case 1:
                    companyType = 'Brand / Retailer';
                    break;
                  case 2:
                    companyType = 'Agent / Importer';
                    break;
                  case 3:
                    companyType = 'Manufacturer';
                    break;
                  case 4:
                    companyType = 'Logistics partner';
                    break;
                }
                company.companyType === 0
                  ? (isOnboarded = 'No')
                  : (isOnboarded = 'Yes');
                const companyPicturesUploaded =
                  company.profilePics === null ||
                  company.profilePics === undefined
                    ? 'No'
                    : company.profilePics.length === 0
                    ? 'No'
                    : 'Yes';
                data.push([
                  companyType,
                  company.name,
                  company.companyID,
                  isOnboarded,
                  this.$t(
                    `countries.${company.officeAddresses[0].country}`
                  ).toString(),
                  company.officeAddresses[0].postal,
                  company.officeAddresses[0].city,
                  company.officeAddresses[0].line1 +
                    ' ' +
                    company.officeAddresses[0].line2,
                  company.officeAddresses[0].geoLat.toString(),
                  company.officeAddresses[0].geoLong.toString(),
                  company.contact.firstName + ' ' + company.contact.lastName,
                  company.contact.email,
                  company.contact.phone,
                  company.employees.toString(),
                  company.brands,
                  company.monthlyCapacity.toString(),
                  company.turnover.toString(),
                  company.profile,
                  companyPicturesUploaded,
                  certName,
                  cert.issuer,
                  cert.referenceIssuer,
                  moment(cert.createdAt).format('D MMM YYYY'),
                  moment(cert.validtoDate).format('D MMM YYYY'),
                  qualityReport.description,
                  qualityReport.issuer,
                  qualityReport.referenceIssuer,
                  qualityReport.validTo,
                  factoryAddress.country,
                  factoryAddress.postal,
                  factoryAddress.city,
                  factoryAddress.address,
                  factoryAddress.geoLat,
                  factoryAddress.geoLong,
                ]);
              } else {
                data.push([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  certName,
                  cert.issuer,
                  cert.referenceIssuer,
                  moment(cert.createdAt).format('D MMM YYYY'),
                  moment(cert.validtoDate).format('D MMM YYYY'),
                  qualityReport.description,
                  qualityReport.issuer,
                  qualityReport.referenceIssuer,
                  qualityReport.validTo,
                  factoryAddress.country,
                  factoryAddress.postal,
                  factoryAddress.city,
                  factoryAddress.address,
                  factoryAddress.geoLat,
                  factoryAddress.geoLong,
                ]);
              }
            });
          } else if (
            allCertificates.length === company.productQualityReport.length
          ) {
            allCertificates.forEach((cert, index) => {
              const factoryAddress = {
                id: '',
                country: '',
                city: '',
                postal: '',
                address: '',
                geoLat: '',
                geoLong: '',
              };
              const qualityReport = {
                description: '',
                issuer: '',
                referenceIssuer: '',
                validTo: '',
              };
              let certName = '';
              if (cert.certType === 'social') {
                certName =
                  cert.type === 10
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(`enums.social_certificates.social.${cert.type}`)
                        .toString();
              } else {
                certName =
                  cert.type === 12
                    ? cert.otherCertName !== undefined ||
                      cert.otherCertName !== null
                      ? cert.otherCertName
                      : ''
                    : i18n
                        .t(
                          `enums.sustainability_certificates.sustainability.${cert.type}`
                        )
                        .toString();
              }
              if (
                company.factoryAddresses.length > 0 &&
                index < company.factoryAddresses.length
              ) {
                factoryAddress.id = company.factoryAddresses[index].id;
                factoryAddress.address =
                  company.factoryAddresses[index].line1 +
                  ' ' +
                  company.factoryAddresses[index].line2;
                factoryAddress.country =
                  company.factoryAddresses[index].country !== ''
                    ? this.$t(
                        `countries.${company.factoryAddresses[index].country}`
                      ).toString()
                    : '';
                factoryAddress.city = company.factoryAddresses[index].city;
                factoryAddress.postal = company.factoryAddresses[index].postal;
                factoryAddress.geoLat =
                  company.factoryAddresses[index].geoLat.toString();
                factoryAddress.geoLong =
                  company.factoryAddresses[index].geoLong.toString();
              }
              if (company.productQualityReport.length > 0) {
                qualityReport.description =
                  company.productQualityReport[index].description;
                qualityReport.issuer =
                  company.productQualityReport[index].issuer;
                qualityReport.referenceIssuer =
                  company.productQualityReport[index].referenceIssuer;
                qualityReport.validTo = moment(
                  company.productQualityReport[index].validToDate
                ).format('D MMM YYYY');
              }
              if (index === 0) {
                let companyType = '';
                let isOnboarded = '';
                switch (company.companyType) {
                  case 0:
                    companyType = 'Manufacturer';
                    break;
                  case 1:
                    companyType = 'Brand / Retailer';
                    break;
                  case 2:
                    companyType = 'Agent / Importer';
                    break;
                  case 3:
                    companyType = 'Manufacturer';
                    break;
                  case 4:
                    companyType = 'Logistics partner';
                    break;
                }
                company.companyType === 0
                  ? (isOnboarded = 'No')
                  : (isOnboarded = 'Yes');
                const companyPicturesUploaded =
                  company.profilePics === null ||
                  company.profilePics === undefined
                    ? 'No'
                    : company.profilePics.length === 0
                    ? 'No'
                    : 'Yes';
                data.push([
                  companyType,
                  company.name,
                  company.companyID,
                  isOnboarded,
                  this.$t(
                    `countries.${company.officeAddresses[0].country}`
                  ).toString(),
                  company.officeAddresses[0].postal,
                  company.officeAddresses[0].city,
                  company.officeAddresses[0].line1 +
                    ' ' +
                    company.officeAddresses[0].line2,
                  company.officeAddresses[0].geoLat.toString(),
                  company.officeAddresses[0].geoLong.toString(),
                  company.contact.firstName + ' ' + company.contact.lastName,
                  company.contact.email,
                  company.contact.phone,
                  company.employees.toString(),
                  company.brands,
                  company.monthlyCapacity.toString(),
                  company.turnover.toString(),
                  company.profile,
                  companyPicturesUploaded,
                  certName,
                  cert.issuer,
                  cert.referenceIssuer,
                  moment(cert.createdAt).format('D MMM YYYY'),
                  moment(cert.validtoDate).format('D MMM YYYY'),
                  qualityReport.description,
                  qualityReport.issuer,
                  qualityReport.referenceIssuer,
                  qualityReport.validTo,
                  factoryAddress.country,
                  factoryAddress.postal,
                  factoryAddress.city,
                  factoryAddress.address,
                  factoryAddress.geoLat,
                  factoryAddress.geoLong,
                ]);
              } else {
                data.push([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  certName,
                  cert.issuer,
                  cert.referenceIssuer,
                  moment(cert.createdAt).format('D MMM YYYY'),
                  moment(cert.validtoDate).format('D MMM YYYY'),
                  qualityReport.description,
                  qualityReport.issuer,
                  qualityReport.referenceIssuer,
                  qualityReport.validTo,
                  factoryAddress.country,
                  factoryAddress.postal,
                  factoryAddress.city,
                  factoryAddress.address,
                  factoryAddress.geoLat,
                  factoryAddress.geoLong,
                ]);
              }
            });
          } else if (
            company.factoryAddresses.length ===
            company.productQualityReport.length
          ) {
            company.factoryAddresses.forEach((address, index) => {
              const certificate = {
                certName: '',
                issuer: '',
                referenceIssuer: '',
                createdAt: '',
                validtoDate: '',
              };
              const qualityReport = {
                description: '',
                issuer: '',
                referenceIssuer: '',
                validTo: '',
              };
              if (
                allCertificates.length > 0 &&
                index < allCertificates.length
              ) {
                let certName = '';
                const cert = allCertificates[index];
                if (cert.certType === 'social') {
                  certName =
                    cert.type === 10
                      ? cert.otherCertName !== undefined ||
                        cert.otherCertName !== null
                        ? cert.otherCertName
                        : ''
                      : i18n
                          .t(`enums.social_certificates.social.${cert.type}`)
                          .toString();
                } else {
                  certName =
                    cert.type === 12
                      ? cert.otherCertName !== undefined ||
                        cert.otherCertName !== null
                        ? cert.otherCertName
                        : ''
                      : i18n
                          .t(
                            `enums.sustainability_certificates.sustainability.${cert.type}`
                          )
                          .toString();
                }
                certificate.certName = certName;
                certificate.issuer = allCertificates[index].issuer;
                certificate.referenceIssuer =
                  allCertificates[index].referenceIssuer;
                certificate.createdAt = moment(
                  allCertificates[index].createdAt
                ).format('D MMM YYYY');
                certificate.validtoDate = moment(
                  allCertificates[index].validtoDate
                ).format('D MMM YYYY');
              }
              if (company.productQualityReport.length > 0) {
                qualityReport.description =
                  company.productQualityReport[index].description;
                qualityReport.issuer =
                  company.productQualityReport[index].issuer;
                qualityReport.referenceIssuer =
                  company.productQualityReport[index].referenceIssuer;
                qualityReport.validTo = moment(
                  company.productQualityReport[index].validToDate
                ).format('D MMM YYYY');
              }
              address.country =
                address.country !== ''
                  ? this.$t(`countries.${address.country}`).toString()
                  : '';
              if (index === 0) {
                let companyType = '';
                let isOnboarded = '';
                switch (company.companyType) {
                  case 0:
                    companyType = 'Manufacturer';
                    break;
                  case 1:
                    companyType = 'Brand / Retailer';
                    break;
                  case 2:
                    companyType = 'Agent / Importer';
                    break;
                  case 3:
                    companyType = 'Manufacturer';
                    break;
                  case 4:
                    companyType = 'Logistics partner';
                    break;
                }
                company.companyType === 0
                  ? (isOnboarded = 'No')
                  : (isOnboarded = 'Yes');
                const companyPicturesUploaded =
                  company.profilePics === null ||
                  company.profilePics === undefined
                    ? 'No'
                    : company.profilePics.length === 0
                    ? 'No'
                    : 'Yes';
                data.push([
                  companyType,
                  company.name,
                  company.companyID,
                  isOnboarded,
                  this.$t(
                    `countries.${company.officeAddresses[0].country}`
                  ).toString(),
                  company.officeAddresses[0].postal,
                  company.officeAddresses[0].city,
                  company.officeAddresses[0].line1 +
                    ' ' +
                    company.officeAddresses[0].line2,
                  company.officeAddresses[0].geoLat.toString(),
                  company.officeAddresses[0].geoLong.toString(),
                  company.contact.firstName + ' ' + company.contact.lastName,
                  company.contact.email,
                  company.contact.phone,
                  company.employees.toString(),
                  company.brands,
                  company.monthlyCapacity.toString(),
                  company.turnover.toString(),
                  company.profile,
                  companyPicturesUploaded,
                  certificate.certName,
                  certificate.issuer,
                  certificate.referenceIssuer,
                  certificate.createdAt,
                  certificate.validtoDate,
                  qualityReport.description,
                  qualityReport.issuer,
                  qualityReport.referenceIssuer,
                  qualityReport.validTo,
                  address.country,
                  address.postal,
                  address.city,
                  address.line1 + ' ' + address.line2,
                  address.geoLat.toString(),
                  address.geoLong.toString(),
                ]);
              } else {
                data.push([
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  certificate.certName,
                  certificate.issuer,
                  certificate.referenceIssuer,
                  certificate.createdAt,
                  certificate.validtoDate,
                  qualityReport.description,
                  qualityReport.issuer,
                  qualityReport.referenceIssuer,
                  qualityReport.validTo,
                  address.country,
                  address.postal,
                  address.city,
                  address.line1 + ' ' + address.line2,
                  address.geoLat.toString(),
                  address.geoLong.toString(),
                ]);
              }
            });
          }
        }
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      ws['!merges'] = [
        XLSX.utils.decode_range('E1:J1'),
        XLSX.utils.decode_range('AC1:AH1'),
      ];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(
        wb,
        `${this.user.companyName.trim()} - tex.tracer master report SCP - ${moment().format(
          'DDMMYYYY'
        )}.xlsx`
      );
      this.isSupplierReportLoading = false;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.reports.no_suppliers').toString()
      );
    }
  }

  private generateOrderlineReport(): void {
    this.isOrderlineReportLoading = true;
    // TTD-5247 Sprint 30 changes updated
    if (this.orders.length > 0) {
      let status = '';
      const data = [
        [
          'Brand',
          'Order number',
          'Style number',
          'Style name',
          'Colour',
          'Season',
          'Contractual partner',
          'Total quantity',
          'Publication status',
          'URL',
        ],
      ];
      const filteredOrders = this.orders.filter(o => o.orderLines[0].orderLinePhase !== 0);
      filteredOrders.forEach(o => {
        let orderline: any = '';
        status = '';
        if (o.orderLineStatus === 5 || o.orderLineStatus === 6) {
          status = 'published';
        } else {
          status = 'not published';
        }
        orderline = o.orderLines[0];
        data.push([
          o.brandName,
          o.orderNumber,
          o.styleNumber,
          o.styleName,
          orderline.colourway,
          orderline.season,
          o.toCompanyName,
          orderline.totalQuantityPerCw,
          status,
          orderline.qrCodeRef,
        ]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Orders');
      const user = this.$store.getters.user as UserModel;
      XLSX.writeFile(
        wb,
        `${
          user.companyName
        } - Master report DPP publication status - ${moment().format(
          'DDMMYYYY'
        )}.xlsx`
      );
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.reports.no_orders').toString()
      );
    }
    this.isOrderlineReportLoading = false;
  }

  // TTD-5246 Sprint 30
  private generateOrderlineStepsReport(): void {
    this.isOrderLineStepsReportLoading = true;
    if (this.orderLineSteps.length > 0) {
      const data = [
        [
          'Product group',
          'Orderline uploaded at',
          'Brand',
          'Order number',
          'Style number',
          'Style name',
          'Colour',
          'Season',
          'Composition main fabric',
          'Quantity',
          'Delivery date',
          'Orderline status',
          'CP',
          'Agent',
          'Delegate company',
          'Tier',
          'Supply chain step',
          'Supplier name',
          'Order evidence status',
          'PC 1 - Supply chain step',
          'PC 1 - Supplier name',
          'PC 2 - Supply chain step',
          'PC 2 - Supplier name',
          'PC 3 - Supply chain step',
          'PC 3 - Supplier name',
          'PC 4 - Supply chain step',
          'PC 4 - Supplier name',
          'PC 5 - Supply chain step',
          'PC 5 - Supplier name',
        ],
      ];
      const allSteps = StepsHelper.getSteps();
      let order: OrderLineDisplayModel = new OrderLineDisplayModel();
      let productGroup: string = '';
      let orderStatus = '';
      this.orderLineSteps.forEach((step) => {
        if(this.orders.filter((o) => o.orderId === step.orderId).length > 0) {
          if (order.orderId !== step.orderId) {
            order = this.orders.filter((o) => o.orderId === step.orderId)[0];
            productGroup = ProductGroupHelper.getProductGroups('en').filter(
              (f) => f.id == order.productGroup
            )[0].description;
            if (order.orderLinePhase === 2) {
              orderStatus = 'Completed';
            } else if (order.orderLineStatus === 7) {
              orderStatus = 'Deleted';
            } else if (
              order.orderLinePhase === 1 &&
              order.orderLineStatus !== 0
            ) {
              orderStatus = 'Verification in Progress';
            } else if (
              order.orderLinePhase === 1 &&
              order.orderLineStatus === 0
            ) {
              orderStatus = 'Waiting for CP';
            } else {
              orderStatus = 'Pending';
            }
          }
          const filteredStep = allSteps.filter((s) => s.id === step.stepId)[0];
          let evidenceStatus = '';
          if (step.evidencePhotos.length > 0) {
            if (step.evidencePhotos[0].status === 0) {
              evidenceStatus = 'No evidence uploaded';
            } else if (step.evidencePhotos[0].status === 1) {
              evidenceStatus = 'Evidence uploaded, approval pending';
            } else if (step.evidencePhotos[0].status === 2) {
              evidenceStatus = 'Evidence approved';
            } else if (step.evidencePhotos[0].status === 3) {
              evidenceStatus = 'Evidence rejected';
            }
          } else {
            evidenceStatus = 'No evidence uploaded';
          }
          if (step.parallelSupplyChain0.length === 0) {
            data.push([
              productGroup,
              moment(order.createdAt).format('DD-MM-YYYY'),
              order.brandName,
              order.orderNumber,
              order.styleNumber,
              order.styleName,
              order.orderLines[0].colourway !== undefined
                ? order.orderLines[0].colourway
                : '',
              order.orderLines[0].season,
              order.orderLines[0].compositionMainFabric,
              order.orderLines[0].totalQuantityPerCw.toString(),
              moment(order.orderLines[0].etd).format('DD-MM-YYYY'),
              orderStatus,
              order.toCompanyName,
              order.agentCompanyUser.name,
              order.delegateCompanyUser.companyName,
              filteredStep.tier !== undefined ? filteredStep.tier : '',
              filteredStep.description,
              step.toCompanyName,
              evidenceStatus,
              '',
              '',
            ]);
          } else {
            const lengths = [
              { name: 'parallelSupplyChain0', length: step.parallelSupplyChain0.length },
              { name: 'parallelSupplyChain1', length: step.parallelSupplyChain1.length },
              { name: 'parallelSupplyChain2', length: step.parallelSupplyChain2.length },
              { name: 'parallelSupplyChain3', length: step.parallelSupplyChain3.length },
              { name: 'parallelSupplyChain4', length: step.parallelSupplyChain4.length },
            ];
            const maxLength = lengths.reduce((max, current) => (current.length > max.length ? current : max), lengths[0]);
            (step[maxLength.name as keyof OrderLineStepDisplayModel] as any[]).forEach((parallel, index) => {
              data.push([
                productGroup,
                moment(order.createdAt).format('DD-MM-YYYY'),
                order.brandName,
                order.orderNumber,
                order.styleNumber,
                order.styleName,
                order.orderLines[0].colourway !== undefined
                  ? order.orderLines[0].colourway
                  : '',
                order.orderLines[0].season,
                order.orderLines[0].compositionMainFabric,
                order.orderLines[0].totalQuantityPerCw.toString(),
                moment(order.orderLines[0].etd).format('DD-MM-YYYY'),
                orderStatus,
                order.toCompanyName,
                order.agentCompanyUser.name,
                order.delegateCompanyUser.companyName,
                filteredStep.tier !== undefined ? filteredStep.tier : '',
                filteredStep.description,
                step.toCompanyName,
                evidenceStatus,
                step.parallelSupplyChain0[index] !== undefined ? step.parallelSupplyChain0[index].displayName : '',
                step.parallelSupplyChain0[index] !== undefined ? step.parallelSupplyChain0[index].toCompanyName : '',
                step.parallelSupplyChain1[index] !== undefined ? step.parallelSupplyChain1[index].displayName : '',
                step.parallelSupplyChain1[index] !== undefined ? step.parallelSupplyChain1[index].toCompanyName : '',
                step.parallelSupplyChain2[index] !== undefined ? step.parallelSupplyChain2[index].displayName : '',
                step.parallelSupplyChain2[index] !== undefined ? step.parallelSupplyChain2[index].toCompanyName : '',
                step.parallelSupplyChain3[index] !== undefined ? step.parallelSupplyChain3[index].displayName : '',
                step.parallelSupplyChain3[index] !== undefined ? step.parallelSupplyChain3[index].toCompanyName : '',
                step.parallelSupplyChain4[index] !== undefined ? step.parallelSupplyChain4[index].displayName : '',
                step.parallelSupplyChain4[index] !== undefined ? step.parallelSupplyChain4[index].toCompanyName : '',
              ]);
            });
          }
        }
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Steps');
      const user = this.$store.getters.user as UserModel;
      XLSX.writeFile(
        wb,
        `${
          user.companyName
        } - Master report orderline steps and evidence - ${moment().format(
          'DDMMYYYY'
        )}.xlsx`
      );
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.reports.no_steps').toString()
      );
    }
    this.isOrderLineStepsReportLoading = false;
  }
}
