export class SharingSettingModel {
    public ID!: string;
    public supplierID!: string;
    public showDetails!: string;
    public showSupplierName!: string;
    public showFactoryAddress!: string;
    public showProfile!: string;
    public showProfilePicture!: string;
    public relatioshipWithClient!: string;
    public associatedWithClientVia!: string;
    public partnerOf!: string;
    public createdAt!: string;
    public createdBy!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public applySettingsTo!: string;

    public constructor() {
        this.ID = '';
        this.supplierID = '';
        this.showDetails = 'ENABLED';
        this.showSupplierName = 'ENABLED';
        this.showFactoryAddress = 'FULL-ADDRESS';
        this.showProfile = 'ENABLED';
        this.showProfilePicture = 'ENABLED';
        this.relatioshipWithClient = '';
        this.associatedWithClientVia = '';
        this.partnerOf = '';
        this.createdAt = '0001-01-01T00:00:00Z';
        this.createdBy = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
        this.applySettingsTo = '';
    }
}

export class ClientPartnerSharingSettingModel {
    public clientID!: string;
    public clientName!: string;
    public sharingSettings!: SharingSettingModel[];
    public createdAt!: string;
    public createdBy!: string;
    public updatedAt!: string;
    public updatedBy!: string;

    public constructor() {
        this.clientID = '';
        this.clientName = '';
        this.sharingSettings = [];
        this.createdAt = '0001-01-01T00:00:00Z';
        this.createdBy = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class ClientPartnerSettingRespModel {
    public success!: boolean;
    public sharingSettings!: ClientPartnerSharingSettingModel;
}

export enum ClientRelationship {
    NP = 'NOMINATED_PARTNER',
    CP = "CONTRACTUAL_PARTNER",
    AGENT = "AGENT",
    DP_OF_CP = "DELEGATE_PARTNER_OF_CONTRACTUAL_PARTNER",
    NP_OF_CP = "NOMINATED_PARTNER_OF_CONTRACTUAL_PARTNER",
    NP_OF_DP = "NOMINATED_PARTNER_OF_DELEGATE_PARTNER",
    NP_OF_AGENT = "NOMINATED_PARTNER_OF_AGENT",
}