import moment from "moment";
import { OrderLineStepStatus } from "./orderLineStepStatus";
import { ProductGroup } from "./productGroup";
import { ProductPictureDisplayModel } from "./productPictureDisplayModel";
import { FromCompanyUserModel } from "./fromCompanyUserModel";
import { ConfirmOrderLineModel } from "./confirmOrderLineModel";
import { ProductQualityReportModel } from "./productQualityReportModel";
import { YarnDataModel } from "./yarnFootprintModel";
import { WetProcessDataModel } from "./wetProcessFootprintModel";
import { FabricDataModel } from "./fabricFootprintModel";
import { GarmentDataModel } from "./garmentFootprintModel";
import { AgentCompanyUserModel } from "./agentCompanyUserModel";
import { DelegateCompanyUserModel } from './delegateCompanyUserModel';
import {ActionOnFootprintModel } from './actionOnFootprintModel'
import { TCType } from "./tcTableModel";

export class OrderLineDisplayModel {
    [x: string]: any;
    public id!: string;
    public orderId!: string;
    public orderNumber!: string;
    public toCompanyId!: string;
    public toCompanyName!: string;
    public productGroup!: ProductGroup;
    public styleNumber!: string;
    public styleName!: string;
    public brandName!: string;
    public expectedTimeOfDelivery!: moment.Moment;
    public scpCompanyName: string = "";
    public productPictures?: ProductPictureDisplayModel[];
    public certificate?: string;
    public evidenceCreatedBy?: string = "";
    public evidenceCreatedAt?: moment.Moment;
    public stepStatus: OrderLineStepStatus = OrderLineStepStatus.None;
    public show: boolean = true;
    public orderLineStatus: number = 0;
    public orderLinePhase: number = 0;
    public fromCompanyId!: string;
    public fromCompanyName!: string;
    public season!: string;
    public repeat!: string;
    public fromCompanyUser!: FromCompanyUserModel;
    public orderLines!: ConfirmOrderLineModel[];
    public createdAt!: string;
    public productQualityReport!: ProductQualityReportModel[];
    public isOrderSeleted : boolean = false;
    public footprintFlag!: string;
    public footprintDeadline!: string;
    public footprintFilledByCompanyID!: string;
    public orderRefId!: string;
    public yarn!: YarnDataModel;
    public wetprocess!: WetProcessDataModel;
    public fabric!: FabricDataModel;
    public garment!: GarmentDataModel;
    public agentCompanyUser!: AgentCompanyUserModel;
    public agentCompanyID!: string;
    public delegateCompanyUser!: DelegateCompanyUserModel;
    public delegateCompanyID!: string;
    //Sprint-22 TTD-3739 
    public isFpOrderSelected!: boolean;
    public actionOnFootprint!: ActionOnFootprintModel;
    // Sprint-24 TTD-3649
    public tranCertificate1!: string;
    public tranCertificate2!: string;
    public levelTC1!: string;
    public levelTC2!: string;
    public license!: string;
    public deliveryTerm!: string;
    public tc1Status!: string;
    public tc2Status!: string;
    public tranCertificate1RefId!: string;
    public tranCertificate2RefId!: string; 
    public customer!: string;
    public tc1Type!: TCType;
    public tc2Type!: TCType;
    public tcReferenceNumber!: string;
    public tcReferenceNumber1!: string;
    // Sprint 29 TTD-3244
    public isDisabled?: boolean = false;
}