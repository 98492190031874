import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";

// Services
import { SupplierService } from "@/services/supplierService";
import { OrderService } from "@/services/orderService";

// Helpers
import { SuppliersHelper } from "@/helpers/suppliersHelper";

// Models
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { CPSCPModel, SupplierOrderCountModel, SupplierPartnerModel, UniqueSupplierWithStepModel } from "@/models/supplierPartnerModel";
import { UserModel } from "@/models/userModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { SupplierPartnersBrandsModel } from "@/models/brandWithSupplierModel";
import { ClientRelationship } from "@/models/sharingSetting";

@Component({
  computed: mapState(['user', 'orders', 'supplierPartnersBrands', 't1AssociatedPartners', 'agentAssociatedPartners'])
})
export default class SupplyChainPartner extends Vue {

  @Prop()
  private supplier!: SupplierOverviewModel;

  @Prop()
  private selectedPartnerType!: number;

  private orders!: OrderLineDisplayModel[];

  private isValid: boolean = false;

  private partnersLoading: boolean = false;

  private partnerCallEnd: boolean = false;

  private scpOrderDetailsCount: SupplierOrderCountModel = new SupplierOrderCountModel;

  private partners: SupplierPartnerModel[] = [];

  private scpPartner: any = {};

  private supplierService: SupplierService;

  private orderService: OrderService;

  private user!: UserModel;

  private supplierPartnersBrands!: SupplierPartnersBrandsModel;

  private t1AssociatedPartners!: CPSCPModel[];

  private agentAssociatedPartners!: CPSCPModel[];

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.orderService = new OrderService();
  }

  private async getSupplierPartner(supplierId: string,e:any): Promise<void> {
    try {
    e.stopPropagation();
    this.isValid = this.isValid ? false : true;
    if(this.partners.length === 0){
      this.partnersLoading = true;
          const associatedPartners: SupplierPartnerModel[] = [];
          let uniqPartners: UniqueSupplierWithStepModel[] = [];
          let allPartners: CPSCPModel[] = [];
          // TTD-3978: show suppliers associated with agent
          if(this.selectedPartnerType === 2){
            uniqPartners = SuppliersHelper.getAgentRelatedSuppliers(this.supplier.id);
            allPartners = this.agentAssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
          } else {
            // TTD-3916: show suppliers based on client and cp
            uniqPartners = SuppliersHelper.getCPRelatedSuppliers(this.supplier.id);
            allPartners = this.t1AssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
          }
          if(allPartners.length > 0){
            allPartners[0].suppliers.forEach(sup => {
              const partners = uniqPartners.filter(ap => ap.supplierId === sup.invitedCompanyID);
              if(partners.length > 0){
                const ap: SupplierPartnerModel = {
                  partnerName: sup.companyName,
                  companySubtypes: partners[0].steps,
                  ID: sup.invitedCompanyID,
                  onboarded: sup.onboarded,
                  isDelegatePartner: sup.isDelegatePartner,
                  isAgent: sup.isAgent,
                  isTier1: sup.isTier1,
                  partnerOf: allPartners[0].companyID
                }
                if(!sup.isTier1 && !sup.isAgent && !sup.isDelegatePartner && this.selectedPartnerType === 1){
                  ap.relatioshipWithClient = ClientRelationship.NP_OF_CP;
                } else if(!sup.isTier1 && !sup.isAgent && sup.isDelegatePartner && this.selectedPartnerType === 1){
                  ap.relatioshipWithClient = ClientRelationship.DP_OF_CP;
                } else if(!sup.isTier1 && !sup.isAgent && !sup.isDelegatePartner && this.selectedPartnerType === 2){
                  ap.relatioshipWithClient = ClientRelationship.NP_OF_AGENT;
                }
                ap.associatedWithClientVia = (ap.relatioshipWithClient === ClientRelationship.NP_OF_CP || ap.relatioshipWithClient === ClientRelationship.DP_OF_CP || ap.relatioshipWithClient === ClientRelationship.NP_OF_AGENT) ? allPartners[0].companyID : '';
                associatedPartners.push(ap);
              }
            })
          }
          this.partners = associatedPartners;
    }
    } finally {
      this.partnersLoading = false;
      this.partnerCallEnd = true;
    }
  }

  private getTypeText(type: number): string {
    return this.$t(`enums.steps.${type}`).toString();
  }

  private openScpDetails(partner: any, e: any): void {
    e.stopPropagation();
    let scpPartner:SupplierOverviewModel;
     scpPartner = {
      id: partner.ID,
      name: partner.partnerName,
      country: partner.country,
      isTier1: partner.isTier1,
      isOnBoarded: partner.onboarded,
      isAgent: partner.isAgent,
      isDelegatePartner: partner.isDelegatePartner,
      relatioshipWithClient: partner.relatioshipWithClient,
      associatedWithClientVia: partner.associatedWithClientVia,
      partnerOf: partner.partnerOf,
      isCompliant: false,
      show: true
    };
    this.openDetails(scpPartner, !partner.isAgent && !partner.isDelegatePartner && !partner.isTier1 ? true : false, partner.isDelegatePartner);
  }

  private async getScpOrderCount(supplierId: string): Promise<void> {
    //TTD-3978
    this.scpOrderDetailsCount = new SupplierOrderCountModel;
    if(this.user.companyType === 1 && this.selectedPartnerType === 1){
      const cpRelatedOrders = this.orders.filter(o => o.toCompanyId === supplierId);
      this.scpOrderDetailsCount.inProgressCount = cpRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 1).length;
      this.scpOrderDetailsCount.publishedCount = cpRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 2).length;
    } else if(this.user.companyType === 1 && this.selectedPartnerType === 2){
      const agentRelatedOrders = this.orders.filter(o => o.agentCompanyID === supplierId);
      this.scpOrderDetailsCount.inProgressCount = agentRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 1).length;
      this.scpOrderDetailsCount.publishedCount = agentRelatedOrders.filter(o => o.orderLines[0].orderLinePhase === 2).length;
    } else {
      this.scpOrderDetailsCount.inProgressCount = 0;
      this.scpOrderDetailsCount.publishedCount = 0;
    }
  }

  @Emit()
    private openDetails(supplier: SupplierOverviewModel, selected: boolean, isDelegatePartner: boolean): void {
    }
}