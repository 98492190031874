import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { v4 as uuidv4 } from 'uuid';
import i18n from "@/i18n";
import { mapState } from "vuex";
import lodash from "lodash";

// Components
import { Drag, Drop, DropList } from "vue-easy-dnd";
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import SearchPartner from '@/components/general/search-partner/searchPartner.vue';
import enLangData from "@/locales/en.json";

// Services
import { ProductGroupService } from "@/services/productGroupService";
import { ClientStepService } from "@/services/clientStepService";
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";

// Models
import { DropdownModel } from "@/models/dropdownModel";
import { OrderLineModel } from "@/models/orderLineModel";
import { OrderLineStepModel } from "@/models/orderLineStepModel";
import { ProductGroupModel } from "@/models/productGroupModel";
import { ClientStepModel } from "@/models/clientStepModel";
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { SupplierPartnerModel } from "@/models/supplierPartnerModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { BulkOrderLineStepModel } from "@/models/confirmBulkOrderLineStepModel";
import { StepState } from "@/models/stepState";
import { StepHistory } from "@/models/stepHistoryModal";

// Helper
import { ProductGroupHelper } from "@/helpers/productGroupHelper";
import { DateTimeHelper } from "@/helpers/dateTimeHelper";
import { StepsHelper } from "@/helpers/stepsHelper";
import { UserModel } from "@/models/userModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepParallelModel } from "@/models/orderLineStepParallelModel";
import { CompanyNameModel } from "@/models/companyModel";
import { MandatoryProcessStepIDsModel, MandatoryStepsProcessModel } from "@/models/mandatoryStepsModel";

@Component({
    components: { Drag, Drop, DropList, SearchSupplier, SearchPartner },
    computed: mapState(['companyType', 'companyNameList'])
})
export default class CreateSupplyChain extends Vue {

    @Prop()
    private orders!: CombinedOrderLineModel;

    @Prop({ default: false })
    private bulkOrder!: boolean;

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private tier1SupplierList!: DropdownModel[];

    @Prop()
    private suppliersLoading!: boolean;

    @Prop()
    private orderConfirming!: boolean;

    @Prop()
    private draftOrderLineSteps!: OrderLineStepModel[];

    //TTD-2620, Agent orderlines changes
    private companyType!: number;

    private orderService: OrderService;

    private productGroupService: ProductGroupService;

    private ClientStepService: ClientStepService;

    private supplierService: SupplierService;

    private orderLineSteps: OrderLineStepModel[] = [];

    private orderLineStepsParallel: OrderLineStepModel[] = [];

    private selectedProductGroup: string;

    private selectedScpId: string = Guid.Empty;

    private partners: SupplierPartnerModel[] = [];

    private selectedproductGroupId: number;

    private selectedTier1Supplier: string = Guid.Empty;

    private selectedStep: number = 0;

    private selectedParallelStep: number = 0;

    private allProductGroups: ProductGroupModel[] = [];

    private deleteDisabled: boolean = true;

    private draggedStep: number = -1;

    private assignNameToOtherNewStep: boolean = false;

    private addingInProgress: boolean = false;

    private allSteps: ClientStepModel[] = [];

    //TTD-2620, Agent orderlines changes
    private allStepsForAgent: OrderLineStepModel[] = [];
    private allStepsForAgentAllOrders: OrderLineStepModel[] = [];
    private orderLineStepsClone: OrderLineStepModel[] = [];
    private stepsForAllOrders: OrderLineStepModel[] = [];

    private stepsForProductGroup: ClientStepModel[] = [];

    private showTier1: boolean = false;

    private showSupplier: boolean = false;

    private showSupplierForParallel: boolean = false;

    private isCreating: boolean = false;

    private isLoading: boolean = false;

    private selectedOrder: OrderLineDisplayModel[] = [];

    private order: OrderLineModel = new OrderLineModel();

    private stepIndex: number = -1;

    private indexOfChain: number = -1;

    private indexOfChainStep: number = -1;

    private customisedCount: number = 0;

    private stepNames: string[] = [];

    private companyStepHistory: StepHistory[] = [];

    private company: UserModel = new UserModel();

    private hasChild: string[] = [];

    private parentStepId: number = 0;

    private showMaxParallelChainModal: boolean = false;

    private parallelChain0DropdownSteps: DropdownModel[] = [];

    private parallelChain1DropdownSteps: DropdownModel[] = [];

    private parallelChain2DropdownSteps: DropdownModel[] = [];

    private parallelChain3DropdownSteps: DropdownModel[] = [];

    private parallelChain4DropdownSteps: DropdownModel[] = [];

    private isNewStepAdded: boolean = false;

    private showRemoveStepModal: boolean = false;

    private stepIdToRemove: number = -1;

    private showHover: boolean = true;

    private paddingLeft: number = 0;

    private isDragging: boolean = false;

    private isParallelSupplyChain: boolean = false;

    private showAgent: boolean = false;

    private agentCompanyName: string = "";

    private isShowDeleteAgent: boolean = false;

    private selectedAgentDetials: OrderCompanyModel = new OrderCompanyModel("", "", "", false, false);

    //TTD-2620, Agent orderlines changes
    private showStepsForAgentLoading: boolean = false;
    private selectOrEditTitle: string = 'Select agent';

    // TTD-2721
    private isShowAddRefModal: boolean = false;
    private toCompanyId: string = '';
    private chainType: string = '';
    private referenceNumber: string = '';
    // TTD-3961
    private companyNameList!: CompanyNameModel[];
    // TTD-5019
    private showMandatoryStepModal: boolean = false;
    private mandatoryStepProcesses: string[] = [];
    private mandatoryStepAllProcess: MandatoryStepsProcessModel[] = [];
    private mandatoryProcesses: MandatoryStepsProcessModel[] = [];
    private processStepMissing: string[] = [];
    private mandatoryStepModalType: string = 'INFO';


    public constructor() {
        super();
        this.selectedProductGroup = "";
        this.selectedproductGroupId = 0;
        this.orderService = new OrderService();
        this.ClientStepService = new ClientStepService();
        this.productGroupService = new ProductGroupService();
        this.supplierService = new SupplierService();
        // this.selectedOrder = this.orders.orderDetails;
        this.stepNames = Object.values(enLangData.enums.steps);
    }

    private async created(): Promise<void> {
        this.status(true);
        try {
            this.isLoading = true;
            this.order = new OrderLineModel();
            this.order.companyName = "";
            this.order.toCompanyId = "";
            this.order.toCompanyName = "";
            this.company = this.$store.getters.user as UserModel;
            this.selectedAgentDetials = new OrderCompanyModel("", "", "", false, false);
            this.selectedOrder = this.orders.orderDetails;
            await this.getOrderDetails();
            await this.getProductGroupId();
            await this.getClientSteps();
            //TTD-2620, Agent orderlines changes
            if (this.companyType === 5) {
                await this.getAgentSteps();
            }
            if (this.selectedproductGroupId != null && this.selectedproductGroupId !== 0) {
                await this.getClientStepsForProductGroup(this.selectedproductGroupId);
            }
            this.setChainCount();
            this.selectedTier1Supplier = this.orders.supplierId;
        }
        finally {
            document.querySelector('.step-list')?.setAttribute('id', 'step-list-custom-scroll');
            const response = await this.supplierService.getMandatoryStepsRuleAsync(this.selectedOrder[0].fromCompanyUser.ID, this.selectedOrder[0].orderLines[0].brandName, this.selectedOrder[0].toCompanyId);
            if(response.success){
                this.mandatoryStepProcesses = response.processes;
                if(this.mandatoryStepProcesses.length > 0){
                    this.mandatoryStepAllProcess = StepsHelper.getMandatoryStepProcess();
                    this.mandatoryStepProcesses.forEach(processID => {
                        const process = this.mandatoryStepAllProcess.find(process => process.processID === processID)
                        if(process !== undefined){
                            this.mandatoryProcesses.push(process);
                        }
                    })
                }
            }
            this.isLoading = false;
        }
    }

    // TTD-5019, Sprint 28
    private getSteps(step:MandatoryProcessStepIDsModel[]): string{
        return step.map(s => s.stepName).join(', ');
    }

    //TTD-2620, Agent orderlines changes
    private async getAgentSteps(): Promise<void> {
        this.showStepsForAgentLoading = true;
        const steps: OrderLineStepDisplayModel[] = this.$store.getters.orderLineSteps;
        const filteredSteps = steps.filter(s => s.orderId === this.orders.orderDetails[0].orderId);
        await Promise.all(this.orders.orderDetails.map(async (order) => {
            let one = await this.orderService.getOrderLineStepsAsync(order.orderId, order.orderLines[0].lineID);
            this.stepsForAllOrders.push(...one);
        }))
        this.allStepsForAgentAllOrders = lodash.cloneDeep(this.stepsForAllOrders);
        this.allStepsForAgent = this.stepsForAllOrders.filter(a => a.orderId == this.orders.orderDetails[0].orderId);
        let count = 0;
        this.allStepsForAgent.forEach(o => {
            o.parallelChainCount = count;
            if (o.parallelSupplyChain0!.length > 0) {
                count++;
                o.parallelSupplyChain0.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
            }
            if (o.parallelSupplyChain1!.length > 0) {
                count++;
                o.parallelSupplyChain1.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
            }
            if (o.parallelSupplyChain2!.length > 0) {
                count++;
                o.parallelSupplyChain2.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
            }
            if (o.parallelSupplyChain3!.length > 0) {
                count++;
                o.parallelSupplyChain3.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
            }
            if (o.parallelSupplyChain4!.length > 0) {
                count++;
                o.parallelSupplyChain4.forEach(s => {
                    s.parentStepId = o.stepId.toString();
                });
            }
        })
        this.orderLineStepsClone = lodash.cloneDeep(this.allStepsForAgent);
        this.showStepsForAgentLoading = false;
    }

    private get isDeleteDisabled(): boolean {
        return this.deleteDisabled;
    }

    private get steps(): DropdownModel[] {
        return this.orderLineSteps.filter(s => s.stepId !== 0).map(s => ({ value: `${s.stepId}`, text: s.displayName }));
    }

    private get showDelete(): boolean {
        return this.orderLineSteps.length > 1 ? true : false;
    }

    // TTD-4752
    private get isStepNameEmpty(): boolean {
        let isEmpty = false;
        isEmpty = this.orderLineSteps.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null);
        if (!isEmpty) {
            this.orderLineSteps.forEach((o) => {
                if (o.parallelSupplyChain0.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain0.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain1.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain1.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain2.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain2.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain3.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain3.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain4.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain4.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
            });
        }
        return isEmpty;
    }

    // TTD-5019
    private showMandatoryStepModalPopup(type: string): void {
        if(!this.showMandatoryStepModal){
            this.mandatoryStepModalType = type;
            this.mandatoryProcesses = [];
            if(type === 'INFO'){
                this.mandatoryStepProcesses.forEach(processID => {
                    const process = this.mandatoryStepAllProcess.find(process => process.processID === processID)
                    if(process !== undefined){
                        this.mandatoryProcesses.push(process);
                    }
                })
            } else if(type === 'MISSING') {
                this.processStepMissing.forEach(processID => {
                    const process = this.mandatoryStepAllProcess.find(process => process.processID === processID)
                    if(process !== undefined){
                        this.mandatoryProcesses.push(process);
                    }
                })
            }
        }
        this.showMandatoryStepModal = !this.showMandatoryStepModal;
    }

    private enabledForAgent(item: OrderLineStepModel): boolean {
        if (this.companyType === 5 && item.toCompanyName !== '') {
            if (item.stepState !== StepState.CREATED_BY_AGENT && item.stepState !== StepState.UPDATED_BY_AGENT) {
                return false;
            }
        }
        return true;
    }

    private enabledForAgentParallel(item: OrderLineStepParallelModel): boolean {
        if (this.companyType === 5 && item.toCompanyName !== '') {
            if (item.stepState !== StepState.CREATED_BY_AGENT && item.stepState !== StepState.UPDATED_BY_AGENT) {
                return false;
            }
        }
        return true;
    }

    private get dropdownSteps(): DropdownModel[] {
        const result: DropdownModel[] = [];
        this.allSteps.forEach(step => {
            const index = this.orderLineSteps.findIndex(sp => sp.stepId === step.stepId);
            if (index === -1) {
                result.push(new DropdownModel(`${step.stepId}`, step.displayName));
            }
        });
        result.sort(function(type1, type2){
            if(type1.text < type2.text) { return -1; }
            if(type1.text > type2.text) { return 1; }
            return 0;
        });
        return result;
    }

    private get supplyChainPartners(): DropdownModel[] {
        return this.suppliers.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
    }

    private get productGroups(): DropdownModel[] {
        return this.allProductGroups.filter(p => p.id !== 0).map(s => ({ value: `${s.id}`, text: s.description }));
    }

    private get totalStepCount(): number {
        return this.orderLineSteps.length;
    }

    private get assignedStepCount(): number {
        return this.orderLineSteps.filter(o => o.toCompanyId !== Guid.Empty).length;
    }

    private get selectedScpName(): string {
        if (this.bulkOrder === true) {
            return 'bulkOrder';
        }
        if ((this.selectedTier1Supplier == null || this.selectedTier1Supplier === Guid.Empty) && this.companyType !== 5) {
            return '';
        }

        if (this.companyType === 5) {
            return this.orders.toCompanyName;
        }
        return this.tier1SupplierList.filter(s => s.value === this.selectedTier1Supplier)[0].text;
    }

    private get isOrderConfirming(): boolean {
        if (this.orderConfirming === false && this.isCreating === true) {
            this.isCreating = false;
            this.close(false);
            return false;
        }
        else {
            return this.orderConfirming;
        }
    }

    private async getClientSteps(): Promise<void> {
        this.allSteps = StepsHelper.getStepsByProductGroup(this.selectedproductGroupId);
    }

    // Get Order details from parent component and bind those details on html
    private async getOrderDetails(): Promise<void> {
        this.selectedOrder.forEach((order, index) => {
            const orderLineData: OrderLineModel = new OrderLineModel();
            order.orderLines.forEach(data => {
                orderLineData.expectedTimeOfDelivery = DateTimeHelper.convertDateStringToDesiredFormat(data.etd, "YYYY-MM-DD", "D MMM YYYY");
                orderLineData.styleName = data.styleName;
                orderLineData.styleNumber = data.styleNumber;
                orderLineData.productGroup = ProductGroupHelper.getProductGroups('en').filter(f => f.id == data.productGroup)[0].description;
                orderLineData.orderLineId = data.lineID;
            });
            this.selectedProductGroup = orderLineData.productGroup;
            const supplier = this.suppliers.filter(s => s.id === order.toCompanyId)[0];
            if (this.bulkOrder) {
                if (index === 0) {
                    this.order.orderNumber = order.orderNumber;
                    this.order.styleName = orderLineData.styleName;
                    this.order.styleNumber = orderLineData.styleNumber;
                    this.order.companyName = supplier !== undefined ? supplier.companyName : '';
                    this.order.expectedTimeOfDelivery = orderLineData.expectedTimeOfDelivery;
                    this.order.colourway = order.orderLines[0].colourway == undefined || order.orderLines[0].colourway.trim() == '' ? '-' : order.orderLines[0].colourway;
                }
                else {
                    this.order.orderNumber += ", " + order.orderNumber;
                    this.order.styleNumber += ", " + orderLineData.styleNumber;
                    this.order.styleName += this.selectedOrder[index - 1].orderLines[0].styleName !== "" ? ", " + orderLineData.styleName : "";
                    this.order.expectedTimeOfDelivery += ", " + orderLineData.expectedTimeOfDelivery;
                    this.order.colourway += order.orderLines[0].colourway == undefined || order.orderLines[0].colourway.trim() == '' ? ', -' : ", " + order.orderLines[0].colourway;
                }
            }
            else {
                this.order.orderId = order.orderId;
                this.order.orderLineId = orderLineData.orderLineId;
                this.order.orderNumber = order.orderNumber;
                this.order.styleName = orderLineData.styleName;
                this.order.styleNumber = orderLineData.styleNumber;
                this.order.companyName = supplier !== undefined ? supplier.companyName : '';
                this.order.colourway = order.orderLines[0].colourway == undefined || order.orderLines[0].colourway.trim() == '' ? '-' : order.orderLines[0].colourway;
                this.order.expectedTimeOfDelivery = orderLineData.expectedTimeOfDelivery;
                this.order.fromCompanyId = order.fromCompanyUser.ID;
                this.order.toCompanyId = order.toCompanyId;
            }
        });
        const orderNumber = this.order.orderNumber?.split(",");
        let colourWay = this.order.colourway?.split(",");
        if (colourWay && orderNumber) {
            const colour = colourWay;
            if ((colourWay.every((val) => val.trim() === '-') && orderNumber.every((val) => val.trim() === orderNumber[0])) || (colourWay.every((val) => val.trim() === '-'))) {
                this.order.colourway = '-';
            } else if ((colourWay.every((val) => val.trim() === colour[0]) && orderNumber.every((val) => val.trim() === orderNumber[0])) || (colourWay.every((val) => val.trim() === colour[0]))) {
                this.order.colourway = colour[0];
            } else if (this.order.colourway?.trim().includes('-')) {
                colourWay = colourWay.filter((val) => val.trim() !== '-');
                this.order.colourway = colourWay.toString();
            }
        }
    }

    private async getClientStepsForProductGroup(productGroup: number): Promise<void> {
        this.stepsForProductGroup = this.allSteps.filter(s => s.showDefault === true);
        this.stepsForProductGroup.sort((step1, step2) => {
            return step1.sequence - step2.sequence;
        });
        this.orderLineSteps = [];
        //TTD-2620, Agent orderlines changes, added condition
        if (this.companyType !== 5) {
            if (this.draftOrderLineSteps !== undefined && this.draftOrderLineSteps.length > 0) {
                this.orderLineSteps = this.draftOrderLineSteps;
            } else {
                this.stepsForProductGroup.forEach((step, index) => {
                    this.order.id = Guid.Empty;
                    step.displayName = step.displayName.charAt(0).toUpperCase() + step.displayName.slice(1);
                    this.orderLineSteps.push(new OrderLineStepModel(this.order, step));
                    if (step.showDefaultParallelChain) {
                        step.parallelChain!.forEach((defaultchild, defaultchildIndex) => {
                            const defaultStep = {
                                displayName: i18n.t(`enums.steps.${defaultchild}`).toString(),
                                stepId: defaultchild,
                                sequence: 1,
                                productGroup: step.productGroup,
                                showDefault: true
                            };
                            const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, defaultStep, step.stepId.toString());
                            if (defaultchildIndex == 0) {
                                this.orderLineSteps[index].parallelSupplyChain0 = [newStep];
                            }
                            if (defaultchildIndex == 1) {
                                this.orderLineSteps[index].parallelSupplyChain1 = [newStep];
                            }
                        });
                    }

                });
            }
        } else {
            //TTD-2620, Agent orderlines changes
            if (this.allStepsForAgent.length > 0) {
                this.allStepsForAgent.sort((step1, step2) => {
                    return step1.sequence - step2.sequence;
                });
                this.orderLineSteps = this.allStepsForAgent.filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE));
                this.customisedCount = this.allStepsForAgent.filter(s => (s.stepState === StepState.CREATED_BY_CLIENT || s.stepState === StepState.UPDATED_BY_CLIENT || s.stepState === StepState.NONE) && s.toCompanyId !== Guid.Empty).length;
            }
            else {
                // this.stepsForProductGroup.forEach(step => {
                //     this.order.id = Guid.Empty;
                //     this.order.toCompanyId = Guid.Empty;
                //     this.order.toCompanyName = "";
                //     step.displayName = step.displayName.charAt(0).toUpperCase() + step.displayName.slice(1);
                //     this.orderLineSteps.push(new OrderLineStepModel(this.order, step));
                // });
                this.stepsForProductGroup.forEach((step, index) => {
                    this.order.id = Guid.Empty;
                    this.order.toCompanyId = Guid.Empty;
                    this.order.toCompanyName = "";
                    step.displayName = step.displayName.charAt(0).toUpperCase() + step.displayName.slice(1);
                    this.orderLineSteps.push(new OrderLineStepModel(this.order, step));
                    if (step.showDefaultParallelChain) {
                        step.parallelChain!.forEach((defaultchild, defaultchildIndex) => {
                            const defaultStep = {
                                displayName: i18n.t(`enums.steps.${defaultchild}`).toString(),
                                stepId: defaultchild,
                                sequence: 1,
                                productGroup: step.productGroup,
                                showDefault: true
                            };
                            const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, defaultStep, step.stepId.toString());
                            if (defaultchildIndex == 0) {
                                this.orderLineSteps[index].parallelSupplyChain0 = [newStep];
                            }
                            if (defaultchildIndex == 1) {
                                this.orderLineSteps[index].parallelSupplyChain1 = [newStep];
                            }
                        });
                    }

                });
            }
        }
    }

    // Display step name based on the country language
    private displayName(stepId: number): string {
        return this.$t(`enums.steps.${stepId}`).toString();
    }

    // Get Product Group Id from product helper component
    private async getProductGroupId(): Promise<void> {
        const productGroup = ProductGroupHelper.getIdFromProductGroupName(this.selectedProductGroup);
        this.selectedproductGroupId = productGroup.id;
    }

    private getStatusForStep(stepId: number): string {
        const supplierAssigned = this.orderLineSteps.filter(o => o.stepId === stepId)[0].toCompanyId !== Guid.Empty;
        return supplierAssigned ? "green" : "red";
    }

    private getStatusForParallelStep(stepId: number, parentStepId: string, indexOfChain: number): string {
        const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : null;
        const supplierAssigned = this.getParallelChain(indexOfChain, parentStepId).filter(c => c.stepId == stepId)[0].toCompanyId !== Guid.Empty;
        return supplierAssigned ? "green" : "red";
    }

    private getParallelChain(index: number, parentStepId: string): OrderLineStepParallelModel[] {
        //TTD-2620, Agent orderlines changes, tweaked condition
        const parentId = parentStepId !== null || parentStepId !== undefined ? Number.parseInt(parentStepId) : null;
        let parallelChain: OrderLineStepParallelModel[] = [];
        if (index == 0) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain0!;
        } else if (index == 1) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain1!;
        } else if (index == 2) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain2!;
        } else if (index == 3) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain3!;
        } else {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain4!;
        }
        return parallelChain;
    }

    private addChain(stepIdText: string): void {
        let count = 0;
        this.orderLineSteps.forEach(step => {
            if (step.parallelSupplyChain0 && step.parallelSupplyChain0.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain1 && step.parallelSupplyChain1.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain2 && step.parallelSupplyChain2.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain3 && step.parallelSupplyChain3.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain4 && step.parallelSupplyChain4.length > 0) {
                count = count + 1;
            }
            if (count >= 5) {
                return;
            }
        });
        if (count >= 5) {
            this.showMaxParallelChainModal = true;
            return;
        }
        const stepId = Number.parseInt(stepIdText);
        const parentChain = this.orderLineSteps.filter(s => s.stepId == stepId)[0];
        const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, { stepId: 0, displayName: "", sequence: 1, productGroup: this.selectedproductGroupId }, stepIdText);
        if (parentChain.parallelSupplyChain0?.length === 0) {
            parentChain.parallelSupplyChain0 = [newStep];
            this.setParallelChainStepDropdown(0, stepIdText);
        } else if (parentChain.parallelSupplyChain1?.length === 0) {
            parentChain.parallelSupplyChain1 = [newStep];
            this.setParallelChainStepDropdown(1, stepIdText);
        } else if (parentChain.parallelSupplyChain2?.length === 0) {
            parentChain.parallelSupplyChain2 = [newStep];
            this.setParallelChainStepDropdown(2, stepIdText);
        } else if (parentChain.parallelSupplyChain3?.length === 0) {
            parentChain.parallelSupplyChain3 = [newStep];
            this.setParallelChainStepDropdown(3, stepIdText);
        } else if (parentChain.parallelSupplyChain4?.length === 0) {
            parentChain.parallelSupplyChain4 = [newStep];
            this.setParallelChainStepDropdown(4, stepIdText);
        } else {
            this.showMaxParallelChainModal = true;
        }
        this.setChainCount();
        this.calculateDistance();
        this.isNewStepAdded = true;
    }

    private changeStyleForParallel(stepId: number, parentStepId: number, indexOfChain: number): void {
        if (this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0].isSelected === true) {
            this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0].isSelected = false;
        } else {
            this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0].isSelected = true;
        }
        const step = this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0];
        const index = this.getParallelChain(indexOfChain, parentStepId.toString()).findIndex(s => s.stepId == stepId);
        this.getParallelChain(indexOfChain, parentStepId.toString()).splice(index, 1);
        this.getParallelChain(indexOfChain, parentStepId.toString()).splice(index, 0, step); 1;
    }

    private changeStyle(stepId: string): void {
        if (this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0].isSelected === true) {
            this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0].isSelected = false;
        } else {
            this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0].isSelected = true;
        }
        const step = this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0];
        const index = this.orderLineSteps.findIndex(s => s.stepId == Number.parseInt(stepId));
        this.orderLineSteps.splice(index, 1);
        this.orderLineSteps.splice(index, 0, step);
    }

    private remove(stepIdText: string): void {
        const stepId = Number.parseInt(stepIdText);
        const parentStep = this.orderLineSteps.filter(o => o.stepId === stepId);
        if (parentStep[0].parallelSupplyChain0?.length == 0 && parentStep[0].parallelSupplyChain1?.length == 0 && parentStep[0].parallelSupplyChain2?.length == 0 && parentStep[0].parallelSupplyChain3?.length == 0 && parentStep[0].parallelSupplyChain4?.length == 0) {
            this.stepIdToRemove = stepId;
            if (this.companyType === 5 && parentStep[0].toCompanyName !== '' && (!parentStep[0].updatedByAgent && !parentStep[0].addedByAgent)) {
                if ((parentStep[0].stepState !== StepState.CREATED_BY_AGENT && parentStep[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                }
            }
            this.removeChain();
        } else {
            let disabledDelete: boolean = false;
            if (this.companyType === 5) {
                if (parentStep[0].parallelSupplyChain0?.length > 0) {
                    parentStep[0].parallelSupplyChain0.forEach(p0 => {
                        if (p0.toCompanyName !== '' && (!p0.updatedByAgent && !p0.addedByAgent)) {
                            if (p0.stepState !== StepState.CREATED_BY_AGENT && p0.stepState !== StepState.UPDATED_BY_AGENT) {
                                disabledDelete = true;
                                return;
                            }
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain1?.length > 0) {
                    parentStep[0].parallelSupplyChain1.forEach(p1 => {
                        if (p1.toCompanyName !== '' && (!p1.updatedByAgent && !p1.addedByAgent)) {
                            if (p1.stepState !== StepState.CREATED_BY_AGENT && p1.stepState !== StepState.UPDATED_BY_AGENT) {
                                disabledDelete = true;
                                return;
                            }
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain2?.length > 0) {
                    parentStep[0].parallelSupplyChain2.forEach(p2 => {
                        if (p2.toCompanyName !== '' && (!p2.updatedByAgent && !p2.addedByAgent)) {
                            if (p2.stepState !== StepState.CREATED_BY_AGENT && p2.stepState !== StepState.UPDATED_BY_AGENT) {
                                disabledDelete = true;
                                return;
                            }
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain3?.length > 0) {
                    parentStep[0].parallelSupplyChain3.forEach(p3 => {
                        if (p3.toCompanyName !== '' && (!p3.updatedByAgent && !p3.addedByAgent)) {
                            if (p3.stepState !== StepState.CREATED_BY_AGENT && p3.stepState !== StepState.UPDATED_BY_AGENT) {
                                disabledDelete = true;
                                return;
                            }
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain4?.length > 0) {
                    parentStep[0].parallelSupplyChain4.forEach(p4 => {
                        if (p4.toCompanyName !== '' && (!p4.updatedByAgent && !p4.addedByAgent)) {
                            if (p4.stepState !== StepState.CREATED_BY_AGENT && p4.stepState !== StepState.UPDATED_BY_AGENT) {
                                disabledDelete = true;
                                return;
                            }
                        }
                    })
                }
            }
            if (!disabledDelete) {
                this.stepIdToRemove = stepId;
                this.showRemoveStepModal = true;
            }
        }
    }

    private removeChain(): void {
        const indexOfStep = this.orderLineSteps.findIndex(s => s.stepId == this.stepIdToRemove);
        if (this.companyType === 5) {
            this.orderLineSteps[indexOfStep].stepState = StepState.DELETED_BY_AGENT;
        }
        this.orderLineSteps.splice(indexOfStep, 1);
        this.setChainCount();
        this.stepIdToRemove = -1;
        this.showRemoveStepModal = false;
    }

    private closeModal(stepIdText: string): void {
        this.stepIdToRemove = -1;
        this.showRemoveStepModal = false;
    }

    private setName(stepIdText: string): void {
        const stepId = Number.parseInt(stepIdText);
        const item = this.allSteps.filter(s => s.stepId === stepId)[0];
        this.orderLineSteps.filter(sp => sp.stepId === stepId)[0].displayName = item.displayName;
        this.assignNameToOtherNewStep = false;
        this.addingInProgress = false;
    }

    private setNameForParallel(stepIdText: string, indexOfChain: number, parentStepId: string): void {
        const stepId = Number.parseInt(stepIdText);
        const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : 0;
        const item = this.allSteps.filter(s => s.stepId === stepId)[0];
        this.getParallelChain(indexOfChain, parentStepId).filter(c => c.stepId === stepId)[0].displayName = item.displayName;
        this.assignNameToOtherNewStep = false;
        this.addingInProgress = false;
        this.isNewStepAdded = false;
    }

    private enableDelete(event: any): void {
        this.isDragging = true;
        if(event.data !== null && event.data !== undefined) {
            const step = event.data as ClientStepModel;
            this.draggedStep = step.stepId;
        }
        this.deleteDisabled = false;
        this.showHover = false;
    }

    private disableDelete(): void {
        this.isDragging = false;
        this.deleteDisabled = true;
        this.draggedStep = -1;
        this.setChainCount();
        this.showHover = true;
        this.calculateDistance();
    }

    private add(stepIdText: string): void {
        const stepId = Number.parseInt(stepIdText);
        const index = this.orderLineSteps.findIndex(s => s.stepId === stepId);
        this.addAtIndex(index + 1);
    }

    private addAtIndex(index: number): void {
        if (this.orderLineSteps.some(a => a.stepId === 0)) {
            this.assignNameToOtherNewStep = true;
            return;
        } else if (this.dropdownSteps.length <= 1) {
            return;
        }
        this.addingInProgress = true;
        const newStep: OrderLineStepModel = new OrderLineStepModel(this.order, { stepId: 0, displayName: "", sequence: index, productGroup: this.selectedproductGroupId });
        //TTD-2620, Agent orderlines changes
        if (this.companyType === 5) {
            newStep.addedByAgent = true;
            newStep.stepState = StepState.CREATED_BY_AGENT;
        }
        //TTD-2620, Agent orderlines changes
        this.orderLineSteps.splice(index, 0, newStep);
    }

    private addInParallel(stepIdText: string, parentStepIdText: string, indexOfChain: number): void {
        if (!this.isNewStepAdded) {
            const stepId = Number.parseInt(stepIdText);
            const ParentStepId = Number.parseInt(parentStepIdText);
            const index = this.getParallelChain(indexOfChain, parentStepIdText).findIndex(s => s.stepId === stepId);
            if (stepIdText !== null) {
                const stepId = Number.parseInt(stepIdText);
                const item = this.allSteps.filter(s => s.stepId === stepId)[0];
                const ParentStepId = Number.parseInt(parentStepIdText);
                const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, { stepId: 0, displayName: "", sequence: index + 1, productGroup: this.selectedproductGroupId }, parentStepIdText);
                //TTD-2620, Agent orderlines changes
                if (this.companyType === 5) {
                    newStep.addedByAgent = true;
                    newStep.stepState = StepState.CREATED_BY_AGENT;
                }
                //TTD-2620, Agent orderlines changes
                this.getParallelChain(indexOfChain, parentStepIdText).splice(index, 0, newStep);
                this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
                this.isNewStepAdded = true;
                this.calculateDistance();
            }
        }
    }

    private addInParallelAtIndex(index: number, stepIdText: string | null, parentStepIdText: string, indexOfChain: number): void {
        if (!this.isNewStepAdded) {
            if (stepIdText !== null) {
                const stepId = Number.parseInt(stepIdText);
                const item = this.allSteps.filter(s => s.stepId === stepId)[0];
                const ParentStepId = Number.parseInt(parentStepIdText);
                const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, { stepId: 0, displayName: "", sequence: index + 1, productGroup: this.selectedproductGroupId }, parentStepIdText);
                if (this.companyType === 5) {
                    newStep.addedByAgent = true;
                    newStep.stepState = StepState.CREATED_BY_AGENT;
                }
                this.getParallelChain(indexOfChain, parentStepIdText).splice(index + 1, 0, newStep);
                this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
                this.isNewStepAdded = true;
                this.calculateDistance();
            }
        }
    }

    private calculateDistance(): void {
        setTimeout(async (app: any) => {
            const leftPoint = document.getElementById('step-list-custom-scroll')!.getBoundingClientRect();
            if (document.getElementById('leftest-leftplus') && document.getElementById('leftest-leftplus')!.getBoundingClientRect().x < leftPoint.x) {
                const padding = app.paddingLeft + 200;
                app.paddingLeft = padding;
                const outerElement = document.getElementById('step-list-custom-scroll');
                outerElement!.style.paddingLeft = padding + 'px';
            } else if (document.getElementById('leftest-add') && document.getElementById('leftest-add')!.getBoundingClientRect().x < leftPoint.x) {
                const padding = app.paddingLeft + 210;
                app.paddingLeft = padding;
                const outerElement = document.getElementById('step-list-custom-scroll');
                outerElement!.style.paddingLeft = padding + 'px';
            }
        }, 250, this);
    }

    private setParallelChainStepDropdown(indexOfChain: number, parentStepIdText: string): void {
        let parentStepIndex = 0;
        switch (indexOfChain) {
            case 0:
                this.parallelChain0DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain0?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain0DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                this.parallelChain0DropdownSteps.sort(function(type1, type2){
                    if(type1.text < type2.text) { return -1; }
                    if(type1.text > type2.text) { return 1; }
                    return 0;
                });
                break;
            case 1:
                this.parallelChain1DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain1?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain1DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                this.parallelChain1DropdownSteps.sort(function(type1, type2){
                    if(type1.text < type2.text) { return -1; }
                    if(type1.text > type2.text) { return 1; }
                    return 0;
                });
                break;
            case 2:
                this.parallelChain2DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain2?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain2DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                this.parallelChain2DropdownSteps.sort(function(type1, type2){
                    if(type1.text < type2.text) { return -1; }
                    if(type1.text > type2.text) { return 1; }
                    return 0;
                });
                break;
            case 3:
                this.parallelChain3DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain3?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain3DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                this.parallelChain3DropdownSteps.sort(function(type1, type2){
                    if(type1.text < type2.text) { return -1; }
                    if(type1.text > type2.text) { return 1; }
                    return 0;
                });
                break;
            case 4:
                this.parallelChain4DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain4?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain4DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                this.parallelChain4DropdownSteps.sort(function(type1, type2){
                    if(type1.text < type2.text) { return -1; }
                    if(type1.text > type2.text) { return 1; }
                    return 0;
                });
                break;
        }
    }

    private setChainCount(): void {
        let count = 0;
        this.isParallelSupplyChain = false;
        this.orderLineSteps.forEach(o => {
            o.parallelChainCount = count;
            if (o.parallelSupplyChain0!.length > 0) {
                count++;
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain1!.length > 0) {
                count++;
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain2!.length > 0) {
                count++;
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain3!.length > 0) {
                count++;
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain4!.length > 0) {
                count++;
                this.isParallelSupplyChain = true;
            }
        });
    }

    private isLastStep(stepId: string): boolean {
        const index = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(stepId));
        if (this.orderLineSteps.length === index + 1) {
            return true;
        }
        return false;
    }

    private removeParallelChainOrStep(stepIdText: string, parentStepIdText: string, indexOfChain: number) {
        const stepId = Number.parseInt(stepIdText);
        const ParentStepId = Number.parseInt(parentStepIdText);
        const indexOfStep = this.getParallelChain(indexOfChain, parentStepIdText).findIndex(s => s.stepId === stepId);
        const allStepsInParallel = this.getParallelChain(indexOfChain, parentStepIdText);
        if (allStepsInParallel.length > 1) {
            if (indexOfChain == 0) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        return;
                    }
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 1) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        return;
                    }
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 2) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        return;
                    }
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 3) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        return;
                    }
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 4) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        return;
                    }
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.splice(indexOfStep, 1);
                }
            }
        } else {
            let dontDelete: boolean = false;
            if (this.companyType === 5) {
                if (indexOfChain === 0 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        dontDelete = true;
                    }
                } else if (indexOfChain === 1 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        dontDelete = true;
                    }
                } else if (indexOfChain === 2 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        dontDelete = true;
                    }
                } else if (indexOfChain === 3 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        dontDelete = true;
                    }
                } else if (indexOfChain === 4 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (!this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].updatedByAgent && !this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].addedByAgent)) {
                    if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT) {
                        dontDelete = true;
                    }
                }
            }
            if (!dontDelete) {
                switch (indexOfChain) {
                    case 0:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(0, parentStepIdText);
                        break;
                    case 1:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(1, parentStepIdText);
                        break;
                    case 2:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(2, parentStepIdText);
                        break;
                    case 3:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(3, parentStepIdText);
                        break;
                    default:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(4, parentStepIdText);
                        break;
                }
                this.setChainCount();
            }
        }
    }

    private setInvalid(): void {
        this.assignNameToOtherNewStep = true;
    }

    private deleteStep(event: any): void {
        if (this.orderLineSteps.length <= 1 || this.deleteDisabled) {
            return;
        }
        const step = event.data as OrderLineStepModel;
        const index = this.orderLineSteps.findIndex(s => s.stepId === step.stepId);
        if (step.toCompanyId !== Guid.Empty && step.toCompanyName !== "") {
            this.customisedCount--;
        }
        this.orderLineSteps.splice(index, 1);
    }

    private swipeLeft(): void {
        const contentToScroll = this.$refs.scrollContent as Vue;
        contentToScroll.$el.scrollLeft -= 200;
    }

    private swipeRight(): void {
        const contentToScroll = this.$refs.scrollContent as Vue;
        contentToScroll.$el.scrollLeft += 100;
    }

    private closeSearchHandler(): void {
        this.showTier1 = false;
        this.showSupplier = false;
        this.changeStyle(this.selectedStep.toString());

    }

    private closeSearchHandlerParallel(): void {
        this.showSupplierForParallel = false;
        this.showSupplier = false;
        if (!this.showAgent) {
            this.changeStyleForParallel(this.selectedParallelStep, this.parentStepId, this.indexOfChain);
        }
        this.showAgent = false;
    }

    private showleftRightIcon(parentStepId: string, index: number): boolean {
        let show = false;
        const parentIndex = this.orderLineSteps.findIndex(s => s.stepId === Number(parentStepId));
        switch (index) {
            case 0: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain0.length > 1 ? true : false;
                break;
            }
            case 1: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain1.length > 1 ? true : false;
                break;
            }
            case 2: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain2.length > 1 ? true : false;
                break;
            }
            case 3: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain3.length > 1 ? true : false;
                break;
            }
            case 4: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain4.length > 1 ? true : false;
                break;
            }
        }
        return show;
    }

    private openSearchSelectHandler(stepId: number, toCompanyId: string): void {
        if (stepId !== -1) {
            const index = this.orderLineSteps.findIndex(s => s.stepId === stepId);
            if (this.companyType === 5) {
                const stepData = this.orderLineSteps.filter(s => s.stepId === stepId);
                if (stepData[0].toCompanyName !== '' && (stepData[0].stepState !== StepState.CREATED_BY_AGENT && stepData[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    if (!stepData[0].updatedByAgent && !stepData[0].addedByAgent) {
                        return;
                    }
                }
            }
            this.toCompanyId = toCompanyId;
            this.stepIndex = index;
            this.selectedStep = stepId;
            this.showSupplier = true;
            this.changeStyle(stepId.toString());
            if (this.orderLineSteps[index].footprintRefNo !== '') {
                this.referenceNumber = this.orderLineSteps[index].footprintRefNo;
            }
            return;
        }
        this.showTier1 = true;
    }

    private openSearchSelectHandlerParallel(stepId: number, parentStepId: number, indexOfChain: number, toCompanyId: string): void {
        if (stepId !== -1) {
            const index = this.getParallelChain(indexOfChain, parentStepId.toString()).findIndex(t => t.stepId === stepId);
            if (this.companyType === 5) {
                const stepData = this.getParallelChain(indexOfChain, parentStepId.toString()).filter(f => f.stepId === stepId);
                if (stepData[0].toCompanyName !== '' && (stepData[0].stepState !== StepState.CREATED_BY_AGENT && stepData[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    if (!stepData[0].updatedByAgent && !stepData[0].addedByAgent) {
                        return;
                    }
                }
            }
            this.indexOfChain = indexOfChain;
            this.indexOfChainStep = index;
            this.selectedParallelStep = stepId;
            this.parentStepId = parentStepId;
            this.showSupplier = true;
            this.showSupplierForParallel = true;
            this.toCompanyId = toCompanyId;
            this.changeStyleForParallel(stepId, parentStepId, indexOfChain);
            if (this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                this.indexOfChainStep
            ].footprintRefNo !== '') {
                this.referenceNumber = this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNo
            }
            return;
        }
        this.showTier1 = true;
    }

    private async invitationSendHandler(): Promise<void> {
        this.suppliersChanged();
    }

    private setTier1SupplierHandler(value: string): void {
        this.selectedTier1Supplier = value;
        this.showTier1 = false;
        this.showSupplier = false;
    }

    private setSupplierHandler(value: string, refNum: string): void {
        if (this.orderLineSteps[this.stepIndex].toCompanyName === "") {
            this.customisedCount++;
        }
        const selectSCP = this.suppliers.filter(s => s.id === value)[0];
        const history: StepHistory = {};
        history.stepId = this.selectedParallelStep;
        history.createdByCompanyId = this.company.companyId;
        history.createdByCompanyName = this.company.companyName;
        history.editedByCompanyId = Guid.Empty;
        history.editedByCompanyName = "";
        this.companyStepHistory.push(history);
        this.orderLineSteps[this.stepIndex].fromCompanyId = this.company.companyId;
            this.orderLineSteps[this.stepIndex].toCompanyName = selectSCP.companyName;
        this.orderLineSteps[this.stepIndex].toCompanyId = selectSCP.id;
        if (this.companyType === 5 && this.orderLineSteps[this.stepIndex].stepState !== StepState.CREATED_BY_AGENT) {
            this.orderLineSteps[this.stepIndex].updatedByAgent = true;
            this.orderLineSteps[this.stepIndex].stepState = StepState.UPDATED_BY_AGENT;
        }
        this.showTier1 = false;
        this.showSupplier = false;
        // if (this.companyType === 5) {
        //     this.orderLineSteps[this.stepIndex].stepState = StepState.CREATED_BY_AGENT;
        // } else {
        //     this.orderLineSteps[this.stepIndex].stepState = StepState.CREATED_BY_CLIENT;
        // }
        if (refNum !== '') {
            this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
            this.orderLineSteps[this.stepIndex].footprintRefNo = refNum;
            this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = this.company.companyId;
        } else {
            this.orderLineSteps[this.stepIndex].footprintRefID = Guid.Empty;
            this.orderLineSteps[this.stepIndex].footprintRefNo = refNum;
            this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = Guid.Empty;
        }
        this.changeStyle(this.selectedStep.toString());
    }

    private setSupplierHandlerParallel(value: string, refNum: string): void {
        if (this.showAgent) {
            const selectScpAgent = this.suppliers.filter(s => s.id === value)[0];
            this.agentCompanyName = selectScpAgent.companyName;
            this.showAgent = false;
            this.selectedAgentDetials = new OrderCompanyModel(selectScpAgent.id, selectScpAgent.companyName, selectScpAgent.country, selectScpAgent.isCompliant, selectScpAgent.onBoarded);
            this.selectedAgentDetials.firstName = selectScpAgent.firstName;
            this.selectedAgentDetials.lastName = selectScpAgent.lastName;
            this.selectedAgentDetials.companyEmail = selectScpAgent.companyEmail;
            this.selectedAgentDetials.companyName = selectScpAgent.companyName;
        } else {
            if (this.orderLineSteps.filter(s => s.stepId == this.parentStepId)[0].toCompanyName === "") {
                this.customisedCount++;
            }
            const selectSCP = this.suppliers.filter(s => s.id === value)[0];
            this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].fromCompanyId = this.company.companyId,
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].toCompanyName = selectSCP.companyName;
            this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].toCompanyId = selectSCP.id;
            if (this.companyType === 5 && this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].stepState !== StepState.CREATED_BY_AGENT) {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].updatedByAgent = true;
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].stepState = StepState.UPDATED_BY_AGENT;
            }
            // if (this.companyType === 5) {
            //     this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].stepState = StepState.CREATED_BY_AGENT;
            // } else {
            //     this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].stepState = StepState.CREATED_BY_CLIENT;
            // }
            if (refNum !== '') {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefID = uuidv4();
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNo = refNum;
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNoUpdatedBy = this.company.companyId;
            } else {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefID = Guid.Empty;
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNo = refNum;
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNoUpdatedBy = Guid.Empty;
            }
            this.showTier1 = false;
            this.showSupplier = false;
            this.showSupplierForParallel = false;
            this.changeStyleForParallel(this.selectedParallelStep, this.parentStepId, this.indexOfChain);
        }
    }

    private create() {
        this.processStepMissing = [];
        if(this.mandatoryStepProcesses.length > 0){
            const orderID = this.orders.orderDetails[0].orderId;
            const steps = this.orderLineSteps.filter(s => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER);
            this.mandatoryProcesses.forEach(process => {
                const stepIDs = process.processSteps.map(ps => ps.stepID);
                process.stepsExistCount = 0;
                process.processSteps.forEach(ps => {
                    process.stepsExistCount = process.stepsExistCount + steps.filter(s => s.stepId === ps.stepID).length;
                    steps.forEach(step => {
                        if(step.parallelSupplyChain0.length > 0){
                            process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain0.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                            if(step.parallelSupplyChain1.length > 0){
                                process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain1.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                                if(step.parallelSupplyChain2.length > 0){
                                    process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain2.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                                    if(step.parallelSupplyChain3.length > 0){
                                        process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain3.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                                        if(step.parallelSupplyChain4.length > 0){
                                            process.stepsExistCount = process.stepsExistCount + step.parallelSupplyChain4.filter(s => s.stepId === ps.stepID && s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER).length;
                                        }
                                    }
                                }
                            }
                        }
                    })
                })
                if(process.stepsExistCount === 0){
                    this.processStepMissing.push(process.processID);
                }
            })
        }
        if(this.processStepMissing.length > 0){
            this.showMandatoryStepModalPopup('MISSING');
            return;
        }
        const confirmationText = this.$t('pages.home.confirm_create_supply_chain').toString();
        this.$confirm(confirmationText, undefined, undefined, { allowOutsideClick: false }).then(() => {
            this.createSupplyChainAsync();
        }).catch(onrejected => { });
    }

    private showAddRefModalForMc(stepId: number) {
        this.isShowAddRefModal = true;
        this.chainType = 'mainChain';
        const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
        this.stepIndex = index;
        this.referenceNumber = '';
    }

    private showAddRefModalForPc(stepId: number, parentStepId: number, indexOfChain: number) {
        this.isShowAddRefModal = true;
        this.chainType = 'parallelChain';
        if (stepId !== -1) {
            const index = this.getParallelChain(
                indexOfChain,
                parentStepId.toString()
            ).findIndex((t) => t.stepId === stepId);
            this.indexOfChain = indexOfChain;
            this.indexOfChainStep = index;
            this.parentStepId = parentStepId;
        }
        this.referenceNumber = ''
    }

    private updateReferenceNumber() {
        const user = this.$store.getters.user as UserModel;
        if (this.chainType === 'mainChain') {
            this.orderLineSteps[this.stepIndex].footprintRefNo = this.referenceNumber;
            if (this.referenceNumber !== '') {
                this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
                this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
            }
        } else {
            this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                this.indexOfChainStep
            ].footprintRefNo = this.referenceNumber;
            if (this.referenceNumber !== '') {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefID = uuidv4();
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNoUpdatedBy = user.companyId;
            }
            const parentId = this.parentStepId !== null ? Number.parseInt(this.parentStepId.toString()) : null;
            const index = this.orderLineSteps.findIndex((o) => o.stepId === parentId);
            const ele = this.orderLineSteps.filter((o) => o.stepId === parentId);
            this.orderLineSteps.splice(index, 1);
            this.orderLineSteps.splice(index, 0, ele[0]);
        }
        this.isShowAddRefModal = false;
    }

    private filterInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const filteredValue = input.value.replace(/[^a-zA-Z0-9.,+/&-]/g, '');
        input.value = filteredValue;
        this.referenceNumber = filteredValue;
    }

    private saveDraft(): void {
        this.status(false);
        this.draft(this.orderLineSteps);
    }

    private fetchStateForParallel(step: OrderLineStepParallelModel): number {
        let state: number = 0;
        if (step.stepState === StepState.UPDATED_BY_AGENT) {
            state = StepState.UPDATED_BY_AGENT;
        } else if (step.stepState === StepState.CREATED_BY_AGENT) {
            state = StepState.CREATED_BY_AGENT;
        } else {
            state = StepState.CREATED_BY_CLIENT;
        }
        return state;
    }

    private fetchState(step: OrderLineStepModel): number {
        let state: number = 0;
        if (step.stepState === StepState.UPDATED_BY_AGENT) {
            state = StepState.UPDATED_BY_AGENT
        } else if (step.stepState === StepState.CREATED_BY_AGENT) {
            state = StepState.CREATED_BY_AGENT;
        } else {
            state = StepState.CREATED_BY_CLIENT;
        }
        return state;
    }

    private getUuid(step: OrderLineStepModel): string {
        let id: string = '';
        if (step.addedByAgent) {
            id = uuidv4();
        } else if (this.companyType !== 5) {
            id = uuidv4();
        } else {
            id = step.id !== Guid.Empty ? step.id : uuidv4();
        }
        return id;
    }

    private getUuidParallel(step: OrderLineStepParallelModel): string {
        let id: string = '';
        if (step.addedByAgent) {
            id = uuidv4();
        } else if (this.companyType !== 5) {
            id = uuidv4();
        } else {
            id = step.id !== Guid.Empty ? step.id : uuidv4();
        }
        return id;
    }

    private async createSupplyChainAsync(): Promise<void> {
        try {
            if (JSON.stringify(this.orderLineSteps) === JSON.stringify(this.orderLineStepsClone)) {
                this.$store.commit("setAgentStepsUpdated", false);
            } else {
                this.$store.commit("setAgentStepsUpdated", true);
            }
            this.isCreating = true;
            let confirmOrderLineStep: OrderLineStepDisplayModel[] = [];
            let finalSequence = 1;
            if (this.bulkOrder) {
                this.selectedOrder.forEach(order => {
                    finalSequence = 1;
                    this.orderLineSteps.forEach((line, index) => {
                        const parallelSupplyChain0: OrderLineStepParallelModel[] = [];
                        const parallelSupplyChain1: OrderLineStepParallelModel[] = [];
                        const parallelSupplyChain2: OrderLineStepParallelModel[] = [];
                        const parallelSupplyChain3: OrderLineStepParallelModel[] = [];
                        const parallelSupplyChain4: OrderLineStepParallelModel[] = [];
                        const state: number = this.companyType === 5 ? this.fetchState(line) : StepState.CREATED_BY_CLIENT;
                        line.id = this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId).length > 0 && this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].id !== Guid.Empty ? this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].id : uuidv4();
                        line.orderId = order.orderId;
                        line.orderLineId = order.orderLines[0].lineID;
                        line.orderNumber = order.orderNumber;
                        line.status = 0;
                        line.sequence = finalSequence++;
                        line.displayName = this.stepNames[line.stepId];
                        //TTD-2620, Agent orderlines changes, changed condition
                        line.stepState = state;
                        line.isLastStep = line.sequence === this.orderLineSteps.length ? 'true' : 'false';
                        line.clientID = order.fromCompanyUser.ID;
                        line.cpSupplierID = order.toCompanyId;
                        line.approvalRequestedTo = '';
                        line.stepHistory = [];
                        if (line.parallelSupplyChain0 !== undefined && line.parallelSupplyChain0.length > 0) {
                            let pscFinalSequence = 1;
                            line.parallelSupplyChain0.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain0.forEach((supplyChain, index) => {
                                const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                                const condition = this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId).length == 0 ? false : (this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain0.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId).length > 0 && this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain0.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId)[0].id !== Guid.Empty);
                                const newParallelSupplyChain: OrderLineStepParallelModel = {
                                    id: condition ? this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain0.filter(p => p.orderId && p.stepId === supplyChain.stepId)[0].id : uuidv4(),
                                    orderId: order.orderId,
                                    orderLineId: order.orderLines[0].lineID,
                                    orderNumber: order.orderNumber,
                                    stepId: supplyChain.stepId,
                                    displayName: this.stepNames[supplyChain.stepId],
                                    productGroup: order.productGroup,
                                    isLastStep: pscFinalSequence === line.parallelSupplyChain0?.length ? 'true' : 'false',
                                    sequence: pscFinalSequence++,
                                    status: 0,
                                    fromCompanyId: supplyChain.fromCompanyId,
                                    toCompanyId: supplyChain.toCompanyId,
                                    toCompanyName: supplyChain.toCompanyName,
                                    evidencePhotos: [],
                                    validSocialCertificates: [],
                                    validSustainabilityCertificates: [],
                                    showDetailsCount: 0,
                                    showDetails: false,
                                    showNewsLetter: false,
                                    showFactoryAddress: false,
                                    showProfile: false,
                                    rejectionReason: "",
                                    compliantAtVerificationDate: false,
                                    currentlyValidCertificates: null,
                                    //TTD-2620, Agent orderlines changes, changed condition
                                    stepState: state,
                                    stepHistory: [],
                                    parentStepId: supplyChain.parentStepId,
                                    footprintRefID: supplyChain.footprintRefID,
                                    footprintRefNo: supplyChain.footprintRefNo,
                                    footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                    clientID: order.fromCompanyUser.ID,
                                    cpSupplierID: order.toCompanyId,
                                    agentID: order.agentCompanyID,
                                    delegatedCompanyID: order.delegatedCompanyID
                                };
                                parallelSupplyChain0.push(newParallelSupplyChain);
                            });
                            parallelSupplyChain0.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain0.reverse();   //Done due to correct sequence
                        }
                        if (line.parallelSupplyChain1 !== undefined && line.parallelSupplyChain1.length > 0) {
                            let pscFinalSequence = 1;
                            line.parallelSupplyChain1.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain1.forEach(supplyChain => {
                                const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                                const condition = this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId).length == 0 ? false : (this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain1.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId).length > 0 && this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain1.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId)[0].id !== Guid.Empty);
                                const newParallelSupplyChain: OrderLineStepParallelModel = {
                                    id: condition ? this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain1.filter(p => p.orderId && p.stepId === supplyChain.stepId)[0].id : uuidv4(),
                                    orderId: order.orderId,
                                    orderLineId: order.orderLines[0].lineID,
                                    orderNumber: order.orderNumber,
                                    stepId: supplyChain.stepId,
                                    displayName: this.stepNames[supplyChain.stepId],
                                    productGroup: order.productGroup,
                                    isLastStep: pscFinalSequence === line.parallelSupplyChain1?.length ? 'true' : 'false',
                                    sequence: pscFinalSequence++,
                                    status: 0,
                                    fromCompanyId: supplyChain.fromCompanyId,
                                    toCompanyId: supplyChain.toCompanyId,
                                    toCompanyName: supplyChain.toCompanyName,
                                    evidencePhotos: [],
                                    validSocialCertificates: [],
                                    validSustainabilityCertificates: [],
                                    showDetailsCount: 0,
                                    showDetails: false,
                                    showNewsLetter: false,
                                    showFactoryAddress: false,
                                    showProfile: false,
                                    rejectionReason: "",
                                    compliantAtVerificationDate: false,
                                    currentlyValidCertificates: null,
                                    //TTD-2620, Agent orderlines changes, changed condition
                                    stepState: state,
                                    stepHistory: [],
                                    parentStepId: supplyChain.parentStepId,
                                    footprintRefID: supplyChain.footprintRefID,
                                    footprintRefNo: supplyChain.footprintRefNo,
                                    footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                    clientID: order.fromCompanyUser.ID,
                                    cpSupplierID: order.toCompanyId,
                                    agentID: order.agentCompanyID,
                                    delegatedCompanyID: order.delegatedCompanyID
                                };
                                parallelSupplyChain1.push(newParallelSupplyChain);
                            });
                            parallelSupplyChain1.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain1.reverse();   //Done due to correct sequence
                        }
                        if (line.parallelSupplyChain2 !== undefined && line.parallelSupplyChain2.length > 0) {
                            let pscFinalSequence = 1;
                            line.parallelSupplyChain2.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain2.forEach(supplyChain => {
                                const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                                const condition = this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId).length == 0 ? false : (this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain2.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId).length > 0 && this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain2.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId)[0].id !== Guid.Empty);
                                const newParallelSupplyChain: OrderLineStepParallelModel = {
                                    id: condition ? this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain2.filter(p => p.orderId && p.stepId === supplyChain.stepId)[0].id : uuidv4(),
                                    orderId: order.orderId,
                                    orderLineId: order.orderLines[0].lineID,
                                    orderNumber: order.orderNumber,
                                    stepId: supplyChain.stepId,
                                    displayName: this.stepNames[supplyChain.stepId],
                                    productGroup: order.productGroup,
                                    isLastStep: pscFinalSequence === line.parallelSupplyChain2?.length ? 'true' : 'false',
                                    sequence: pscFinalSequence++,
                                    status: 0,
                                    fromCompanyId: supplyChain.fromCompanyId,
                                    toCompanyId: supplyChain.toCompanyId,
                                    toCompanyName: supplyChain.toCompanyName,
                                    evidencePhotos: [],
                                    validSocialCertificates: [],
                                    validSustainabilityCertificates: [],
                                    showDetailsCount: 0,
                                    showDetails: false,
                                    showNewsLetter: false,
                                    showFactoryAddress: false,
                                    showProfile: false,
                                    rejectionReason: "",
                                    compliantAtVerificationDate: false,
                                    currentlyValidCertificates: null,
                                    //TTD-2620, Agent orderlines changes, changed condition
                                    stepState: state,
                                    stepHistory: [],
                                    parentStepId: supplyChain.parentStepId,
                                    footprintRefID: supplyChain.footprintRefID,
                                    footprintRefNo: supplyChain.footprintRefNo,
                                    footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                    clientID: order.fromCompanyUser.ID,
                                    cpSupplierID: order.toCompanyId,
                                    agentID: order.agentCompanyID,
                                    delegatedCompanyID: order.delegatedCompanyID
                                };
                                parallelSupplyChain2.push(newParallelSupplyChain);
                            });
                            parallelSupplyChain2.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain2.reverse();   //Done due to correct sequence
                        }
                        if (line.parallelSupplyChain3 !== undefined && line.parallelSupplyChain3.length > 0) {
                            let pscFinalSequence = 1;
                            line.parallelSupplyChain3.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain3.forEach(supplyChain => {
                                const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                                const condition = this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId).length == 0 ? false : (this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain3.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId).length > 0 && this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain3.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId)[0].id !== Guid.Empty);
                                const newParallelSupplyChain: OrderLineStepParallelModel = {
                                    id: condition ? this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain3.filter(p => p.orderId && p.stepId === supplyChain.stepId)[0].id : uuidv4(),
                                    orderId: order.orderId,
                                    orderLineId: order.orderLines[0].lineID,
                                    orderNumber: order.orderNumber,
                                    stepId: supplyChain.stepId,
                                    displayName: this.stepNames[supplyChain.stepId],
                                    productGroup: order.productGroup,
                                    isLastStep: pscFinalSequence === line.parallelSupplyChain3?.length ? 'true' : 'false',
                                    sequence: pscFinalSequence++,
                                    status: 0,
                                    fromCompanyId: supplyChain.fromCompanyId,
                                    toCompanyId: supplyChain.toCompanyId,
                                    toCompanyName: supplyChain.toCompanyName,
                                    evidencePhotos: [],
                                    validSocialCertificates: [],
                                    validSustainabilityCertificates: [],
                                    showDetailsCount: 0,
                                    showDetails: false,
                                    showNewsLetter: false,
                                    showFactoryAddress: false,
                                    showProfile: false,
                                    rejectionReason: "",
                                    compliantAtVerificationDate: false,
                                    currentlyValidCertificates: null,
                                    //TTD-2620, Agent orderlines changes, changed condition
                                    stepState: state,
                                    stepHistory: [],
                                    parentStepId: supplyChain.parentStepId,
                                    footprintRefID: supplyChain.footprintRefID,
                                    footprintRefNo: supplyChain.footprintRefNo,
                                    footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                    clientID: order.fromCompanyUser.ID,
                                    cpSupplierID: order.toCompanyId,
                                    agentID: order.agentCompanyID,
                                    delegatedCompanyID: order.delegatedCompanyID
                                };
                                parallelSupplyChain3.push(newParallelSupplyChain);
                            });
                            parallelSupplyChain3.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain3.reverse();   //Done due to correct sequence
                        }
                        if (line.parallelSupplyChain4 !== undefined && line.parallelSupplyChain4.length > 0) {
                            let pscFinalSequence = 1;
                            line.parallelSupplyChain4.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain4.forEach(supplyChain => {
                                const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                                const condition = this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId).length == 0 ? false : (this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain4.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId).length > 0 && this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain4.filter(p => p.orderId === order.orderId && p.stepId === supplyChain.stepId)[0].id !== Guid.Empty);
                                const newParallelSupplyChain: OrderLineStepParallelModel = {
                                    id: condition ? this.stepsForAllOrders.filter(s => s.orderId === order.orderId && s.stepId === line.stepId)[0].parallelSupplyChain4.filter(p => p.orderId && p.stepId === supplyChain.stepId)[0].id : uuidv4(),
                                    orderId: order.orderId,
                                    orderLineId: order.orderLines[0].lineID,
                                    orderNumber: order.orderNumber,
                                    stepId: supplyChain.stepId,
                                    displayName: this.stepNames[supplyChain.stepId],
                                    productGroup: order.productGroup,
                                    isLastStep: pscFinalSequence === line.parallelSupplyChain4?.length ? 'true' : 'false',
                                    sequence: pscFinalSequence++,
                                    status: 0,
                                    fromCompanyId: supplyChain.fromCompanyId,
                                    toCompanyId: supplyChain.toCompanyId,
                                    toCompanyName: supplyChain.toCompanyName,
                                    evidencePhotos: [],
                                    validSocialCertificates: [],
                                    validSustainabilityCertificates: [],
                                    showDetailsCount: 0,
                                    showDetails: false,
                                    showNewsLetter: false,
                                    showFactoryAddress: false,
                                    showProfile: false,
                                    rejectionReason: "",
                                    compliantAtVerificationDate: false,
                                    currentlyValidCertificates: null,
                                    //TTD-2620, Agent orderlines changes, changed condition
                                    stepState: state,
                                    stepHistory: [],
                                    parentStepId: supplyChain.parentStepId,
                                    footprintRefID: supplyChain.footprintRefID,
                                    footprintRefNo: supplyChain.footprintRefNo,
                                    footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                    clientID: order.fromCompanyUser.ID,
                                    cpSupplierID: order.toCompanyId,
                                    agentID: order.agentCompanyID,
                                    delegatedCompanyID: order.delegatedCompanyID
                                };
                                parallelSupplyChain4.push(newParallelSupplyChain);
                            });
                            parallelSupplyChain4.reverse();   //Done due to correct sequence
                            line.parallelSupplyChain4.reverse();   //Done due to correct sequence
                        }
                        line.parallelSupplyChain0 = parallelSupplyChain0;
                        line.parallelSupplyChain1 = parallelSupplyChain1;
                        line.parallelSupplyChain2 = parallelSupplyChain2;
                        line.parallelSupplyChain3 = parallelSupplyChain3;
                        line.parallelSupplyChain4 = parallelSupplyChain4;
                        confirmOrderLineStep.push(new BulkOrderLineStepModel(line));
                    });
                    // confirmOrderLineStep = this.setLastStep(confirmOrderLineStep);
                    if (this.companyType === 5) {
                        this.allStepsForAgentAllOrders.filter(a => a.orderId === order.orderId).forEach(lineClone => {
                            if (confirmOrderLineStep.find(s => s.id === lineClone.id) === undefined) {
                                lineClone.stepState = StepState.DELETED_BY_AGENT;
                                lineClone.displayName = this.stepNames[lineClone.stepId];
                                lineClone.orderId = order.orderId;
                                if (lineClone.parallelSupplyChain0.length > 0) {
                                    lineClone.parallelSupplyChain0.forEach(p => {
                                        p.stepState = StepState.DELETED_BY_AGENT;
                                        p.displayName = this.stepNames[p.stepId];
                                        p.orderId = order.orderId;
                                    });
                                }
                                if (lineClone.parallelSupplyChain1.length > 0) {
                                    lineClone.parallelSupplyChain1.forEach(p => {
                                        p.stepState = StepState.DELETED_BY_AGENT;
                                        p.displayName = this.stepNames[p.stepId];
                                        p.orderId = order.orderId;
                                    });
                                }
                                if (lineClone.parallelSupplyChain2.length > 0) {
                                    lineClone.parallelSupplyChain2.forEach(p => {
                                        p.stepState = StepState.DELETED_BY_AGENT;
                                        p.displayName = this.stepNames[p.stepId];
                                        p.orderId = order.orderId;
                                    });
                                }
                                if (lineClone.parallelSupplyChain3.length > 0) {
                                    lineClone.parallelSupplyChain3.forEach(p => {
                                        p.stepState = StepState.DELETED_BY_AGENT;
                                        p.displayName = this.stepNames[p.stepId];
                                        p.orderId = order.orderId;
                                    });
                                }
                                if (lineClone.parallelSupplyChain4.length > 0) {
                                    lineClone.parallelSupplyChain4.forEach(p => {
                                        p.stepState = StepState.DELETED_BY_AGENT;
                                        p.displayName = this.stepNames[p.stepId];
                                        p.orderId = order.orderId;
                                    });
                                }
                                confirmOrderLineStep.push(lineClone);
                            }
                        });
                    }
                });
            }
            else {
                this.orderLineSteps.forEach(step => {
                    const parallelSupplyChain0: OrderLineStepParallelModel[] = [];
                    const parallelSupplyChain1: OrderLineStepParallelModel[] = [];
                    const parallelSupplyChain2: OrderLineStepParallelModel[] = [];
                    const parallelSupplyChain3: OrderLineStepParallelModel[] = [];
                    const parallelSupplyChain4: OrderLineStepParallelModel[] = [];
                    const state: number = this.companyType === 5 ? this.fetchState(step) : StepState.CREATED_BY_CLIENT;
                    step.id = this.getUuid(step);
                    step.status = 0;
                    step.sequence = finalSequence++;
                    //TTD-2620, Agent orderlines changes, changed condition
                    step.stepState = state;
                    step.isLastStep = step.sequence === this.orderLineSteps.length ? 'true' : 'false';
                    step.displayName = this.stepNames[step.stepId];
                    step.clientID = this.order.fromCompanyId;
                    step.cpSupplierID = this.order.toCompanyId;
                    step.approvalRequestedTo = '';
                    step.stepHistory = [];
                    if (step.parallelSupplyChain0 !== undefined && step.parallelSupplyChain0.length > 0) {
                        let pscFinalSequence = 1;
                        step.parallelSupplyChain0.reverse();   //Done due to correct sequence
                        step.parallelSupplyChain0.forEach(supplyChain => {
                            const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                            const newParallelSupplyChain: OrderLineStepParallelModel = {
                                id: this.getUuidParallel(supplyChain),
                                orderId: step.orderId,
                                orderLineId: step.orderLineId,
                                orderNumber: step.orderNumber,
                                stepId: supplyChain.stepId,
                                displayName: this.stepNames[supplyChain.stepId],
                                productGroup: step.productGroup,
                                isLastStep: pscFinalSequence === step.parallelSupplyChain0?.length ? 'true' : 'false',
                                sequence: pscFinalSequence++,
                                status: 0,
                                fromCompanyId: supplyChain.fromCompanyId,
                                toCompanyId: supplyChain.toCompanyId,
                                toCompanyName: supplyChain.toCompanyName,
                                evidencePhotos: [],
                                validSocialCertificates: [],
                                validSustainabilityCertificates: [],
                                showDetailsCount: 0,
                                showDetails: false,
                                showNewsLetter: false,
                                showFactoryAddress: false,
                                showProfile: false,
                                rejectionReason: "",
                                compliantAtVerificationDate: false,
                                currentlyValidCertificates: null,
                                //TTD-2620, Agent orderlines changes, changed condition
                                stepState: state,
                                stepHistory: [],
                                parentStepId: supplyChain.parentStepId,
                                footprintRefID: supplyChain.footprintRefID,
                                footprintRefNo: supplyChain.footprintRefNo,
                                footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                clientID: this.order.fromCompanyId,
                                cpSupplierID: this.order.toCompanyId
                            };
                            parallelSupplyChain0.push(newParallelSupplyChain);
                        });
                        parallelSupplyChain0.reverse();   //Done due to correct sequence
                    }
                    if (step.parallelSupplyChain1 !== undefined && step.parallelSupplyChain1.length > 0) {
                        let pscFinalSequence = 1;
                        step.parallelSupplyChain1.reverse();   //Done due to correct sequence
                        step.parallelSupplyChain1.forEach(supplyChain => {
                            const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                            const newParallelSupplyChain: OrderLineStepParallelModel = {
                                id: this.getUuidParallel(supplyChain),
                                orderId: step.orderId,
                                orderLineId: step.orderLineId,
                                orderNumber: step.orderNumber,
                                stepId: supplyChain.stepId,
                                displayName: this.stepNames[supplyChain.stepId],
                                productGroup: step.productGroup,
                                isLastStep: pscFinalSequence === step.parallelSupplyChain1?.length ? 'true' : 'false',
                                sequence: pscFinalSequence++,
                                status: 0,
                                fromCompanyId: supplyChain.fromCompanyId,
                                toCompanyId: supplyChain.toCompanyId,
                                toCompanyName: supplyChain.toCompanyName,
                                evidencePhotos: [],
                                validSocialCertificates: [],
                                validSustainabilityCertificates: [],
                                showDetailsCount: 0,
                                showDetails: false,
                                showNewsLetter: false,
                                showFactoryAddress: false,
                                showProfile: false,
                                rejectionReason: "",
                                compliantAtVerificationDate: false,
                                currentlyValidCertificates: null,
                                //TTD-2620, Agent orderlines changes, changed condition
                                stepState: state,
                                stepHistory: [],
                                parentStepId: supplyChain.parentStepId,
                                footprintRefID: supplyChain.footprintRefID,
                                footprintRefNo: supplyChain.footprintRefNo,
                                footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                clientID: this.order.fromCompanyId,
                                cpSupplierID: this.order.toCompanyId
                            };
                            parallelSupplyChain1.push(newParallelSupplyChain);
                        });
                        parallelSupplyChain1.reverse();   //Done due to correct sequence
                    }
                    if (step.parallelSupplyChain2 !== undefined && step.parallelSupplyChain2.length > 0) {
                        let pscFinalSequence = 1;
                        step.parallelSupplyChain2.reverse();   //Done due to correct sequence
                        step.parallelSupplyChain2.forEach(supplyChain => {
                            const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                            const newParallelSupplyChain: OrderLineStepParallelModel = {
                                id: this.getUuidParallel(supplyChain),
                                orderId: step.orderId,
                                orderLineId: step.orderLineId,
                                orderNumber: step.orderNumber,
                                stepId: supplyChain.stepId,
                                displayName: this.stepNames[supplyChain.stepId],
                                productGroup: step.productGroup,
                                isLastStep: pscFinalSequence === step.parallelSupplyChain2?.length ? 'true' : 'false',
                                sequence: pscFinalSequence++,
                                status: 0,
                                fromCompanyId: supplyChain.fromCompanyId,
                                toCompanyId: supplyChain.toCompanyId,
                                toCompanyName: supplyChain.toCompanyName,
                                evidencePhotos: [],
                                validSocialCertificates: [],
                                validSustainabilityCertificates: [],
                                showDetailsCount: 0,
                                showDetails: false,
                                showNewsLetter: false,
                                showFactoryAddress: false,
                                showProfile: false,
                                rejectionReason: "",
                                compliantAtVerificationDate: false,
                                currentlyValidCertificates: null,
                                //TTD-2620, Agent orderlines changes, changed condition
                                stepState: state,
                                stepHistory: [],
                                parentStepId: supplyChain.parentStepId,
                                footprintRefID: supplyChain.footprintRefID,
                                footprintRefNo: supplyChain.footprintRefNo,
                                footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                clientID: this.order.fromCompanyId,
                                cpSupplierID: this.order.toCompanyId
                            };
                            parallelSupplyChain2.push(newParallelSupplyChain);
                        });
                        parallelSupplyChain2.reverse();   //Done due to correct sequence
                    }
                    if (step.parallelSupplyChain3 !== undefined && step.parallelSupplyChain3.length > 0) {
                        let pscFinalSequence = 1;
                        step.parallelSupplyChain3.reverse();   //Done due to correct sequence
                        step.parallelSupplyChain3.forEach(supplyChain => {
                            const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                            const newParallelSupplyChain: OrderLineStepParallelModel = {
                                id: this.getUuidParallel(supplyChain),
                                orderId: step.orderId,
                                orderLineId: step.orderLineId,
                                orderNumber: step.orderNumber,
                                stepId: supplyChain.stepId,
                                displayName: this.stepNames[supplyChain.stepId],
                                productGroup: step.productGroup,
                                isLastStep: pscFinalSequence === step.parallelSupplyChain3?.length ? 'true' : 'false',
                                sequence: pscFinalSequence++,
                                status: 0,
                                fromCompanyId: supplyChain.fromCompanyId,
                                toCompanyId: supplyChain.toCompanyId,
                                toCompanyName: supplyChain.toCompanyName,
                                evidencePhotos: [],
                                validSocialCertificates: [],
                                validSustainabilityCertificates: [],
                                showDetailsCount: 0,
                                showDetails: false,
                                showNewsLetter: false,
                                showFactoryAddress: false,
                                showProfile: false,
                                rejectionReason: "",
                                compliantAtVerificationDate: false,
                                currentlyValidCertificates: null,
                                //TTD-2620, Agent orderlines changes, changed condition
                                stepState: state,
                                stepHistory: [],
                                parentStepId: supplyChain.parentStepId,
                                footprintRefID: supplyChain.footprintRefID,
                                footprintRefNo: supplyChain.footprintRefNo,
                                footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                clientID: this.order.fromCompanyId,
                                cpSupplierID: this.order.toCompanyId
                            };
                            parallelSupplyChain3.push(newParallelSupplyChain);
                        });
                        parallelSupplyChain3.reverse();   //Done due to correct sequence
                    }
                    if (step.parallelSupplyChain4 !== undefined && step.parallelSupplyChain4.length > 0) {
                        let pscFinalSequence = 1;
                        step.parallelSupplyChain4.reverse();  //Done due to correct sequence
                        step.parallelSupplyChain4.forEach(supplyChain => {
                            const state: number = this.companyType === 5 ? this.fetchStateForParallel(supplyChain) : StepState.CREATED_BY_CLIENT;
                            const newParallelSupplyChain: OrderLineStepParallelModel = {
                                id: this.getUuidParallel(supplyChain),
                                orderId: step.orderId,
                                orderLineId: step.orderLineId,
                                orderNumber: step.orderNumber,
                                stepId: supplyChain.stepId,
                                displayName: this.stepNames[supplyChain.stepId],
                                productGroup: step.productGroup,
                                isLastStep: pscFinalSequence === step.parallelSupplyChain4?.length ? 'true' : 'false',
                                sequence: pscFinalSequence++,
                                status: 0,
                                fromCompanyId: supplyChain.fromCompanyId,
                                toCompanyId: supplyChain.toCompanyId,
                                toCompanyName: supplyChain.toCompanyName,
                                evidencePhotos: [],
                                validSocialCertificates: [],
                                validSustainabilityCertificates: [],
                                showDetailsCount: 0,
                                showDetails: false,
                                showNewsLetter: false,
                                showFactoryAddress: false,
                                showProfile: false,
                                rejectionReason: "",
                                compliantAtVerificationDate: false,
                                currentlyValidCertificates: null,
                                //TTD-2620, Agent orderlines changes, changed condition
                                stepState: state,
                                stepHistory: [],
                                parentStepId: supplyChain.parentStepId,
                                footprintRefID: supplyChain.footprintRefID,
                                footprintRefNo: supplyChain.footprintRefNo,
                                footprintRefNoUpdatedBy: supplyChain.footprintRefNoUpdatedBy,
                                clientID: this.order.fromCompanyId,
                                cpSupplierID: this.order.toCompanyId
                            };
                            parallelSupplyChain4.push(newParallelSupplyChain);
                        });
                        parallelSupplyChain4.reverse();   //Done due to correct sequence
                    }
                    step.parallelSupplyChain0 = parallelSupplyChain0;
                    step.parallelSupplyChain1 = parallelSupplyChain1;
                    step.parallelSupplyChain2 = parallelSupplyChain2;
                    step.parallelSupplyChain3 = parallelSupplyChain3;
                    step.parallelSupplyChain4 = parallelSupplyChain4;
                    confirmOrderLineStep.push(step);
                });
                if (this.companyType === 5) {
                    this.allStepsForAgentAllOrders.forEach(lineClone => {
                        if (confirmOrderLineStep.find(s => s.id === lineClone.id) === undefined) {
                            lineClone.stepState = StepState.DELETED_BY_AGENT;
                            lineClone.displayName = this.stepNames[lineClone.stepId];
                            if (lineClone.parallelSupplyChain0.length > 0) {
                                lineClone.parallelSupplyChain0.forEach(p => {
                                    p.stepState = StepState.DELETED_BY_AGENT;
                                    p.displayName = this.stepNames[p.stepId];
                                });
                            }
                            if (lineClone.parallelSupplyChain1.length > 0) {
                                lineClone.parallelSupplyChain1.forEach(p => {
                                    p.stepState = StepState.DELETED_BY_AGENT;
                                    p.displayName = this.stepNames[p.stepId];
                                });
                            }
                            if (lineClone.parallelSupplyChain2.length > 0) {
                                lineClone.parallelSupplyChain2.forEach(p => {
                                    p.stepState = StepState.DELETED_BY_AGENT;
                                    p.displayName = this.stepNames[p.stepId];
                                });
                            }
                            if (lineClone.parallelSupplyChain3.length > 0) {
                                lineClone.parallelSupplyChain3.forEach(p => {
                                    p.stepState = StepState.DELETED_BY_AGENT;
                                    p.displayName = this.stepNames[p.stepId];
                                });
                            }
                            if (lineClone.parallelSupplyChain4.length > 0) {
                                lineClone.parallelSupplyChain4.forEach(p => {
                                    p.stepState = StepState.DELETED_BY_AGENT;
                                    p.displayName = this.stepNames[p.stepId];
                                });
                            }
                            confirmOrderLineStep.push(lineClone);
                        }
                    });
                }
                // confirmOrderLineStep = this.setLastStep(confirmOrderLineStep);
                const selectedSupplier = this.suppliers.filter(s => s.id === this.selectedTier1Supplier)[0];
                this.orders.supplierId = selectedSupplier !== undefined ? selectedSupplier.id : this.orders.supplierId;
                this.orders.supplierName = selectedSupplier !== undefined ? selectedSupplier.companyName : this.orders.supplierName;
                this.orders.supplierCountry = selectedSupplier !== undefined ? selectedSupplier.country : this.orders.supplierCountry;
            }
            this.confirmed(this.orders, confirmOrderLineStep, this.bulkOrder, this.selectedAgentDetials);
        } finally {
            // this.isCreating = false;
        }
    }

    private checkCount(str: string): boolean {
        if (str.indexOf(', ') !== -1) {
            return false;
        }
        return true;
    }

    private showAgentList(type: string) {
        this.showAgent = true;
        if (type === 'edit') {
            this.selectOrEditTitle = 'Edit agent';
        } else {
            this.selectOrEditTitle = 'Select agent';
        }
    }

    private removeAgent() {
        this.isShowDeleteAgent = false;
        this.agentCompanyName = '';
        this.selectedAgentDetials = new OrderCompanyModel("", "", "", false, false);
    }

    @Emit()
    private suppliersChanged(): void {
    }

    @Emit()
    private close(created: boolean): void {
    }

    @Emit()
    private confirmed(order: CombinedOrderLineModel, confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean, agentDetails: OrderCompanyModel): void {
    }

    @Emit()
    private draft(orderlines: OrderLineStepModel[]): void { }

    @Emit()
    private status(created: boolean): void { }
}
